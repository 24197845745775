import React, { memo } from "react";
import styled from "styled-components";
import BreadCrumb from "./BreadCrumb";
import { Button } from "../Button";

const Navigation = styled.nav`
	width: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 50px;
	position: sticky;
	top: 0;
	z-index: 15;
	background: ${(props) => props.theme.colors.neutral.grey00};
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral.grey100};

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		padding: 16px 30px;
	}
`;

const NavSection = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const InviteButton = styled(Button)`
	background: ${(props) => props.theme.colors.neutral.grey00};
	padding: 8px 24px;
	border: 1px solid #eaecf0;
	box-shadow: ${(props) => props.theme.shadow.glassy100};

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		display: none;
	}
`;

const UserIconContainer = styled.img`
	cursor: pointer;
	width: 38px;
	height: 38px;
	object-fit: cover;
	border-radius: 100%;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	box-shadow: ${(props) => props.theme.shadow.md};
`;

const NoImageIcon = styled.div`
	width: 38px;
	height: 38px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	font-weight: 900;
	cursor: pointer;
	background: ${(props) => props.theme.colors.neutral.grey200};
`;

 function NavBar({ setShowDropdown, setShowModal, authUser }) {
	const handleDropdown = () => {
		setShowDropdown((showDropDown) => !showDropDown);
		setShowModal(false);
	};

	const handleShowModal = () => {
		setShowModal((showModal) => !showModal);
		setShowDropdown(false);
	};

	return (
		<Navigation>
			<BreadCrumb />

			<NavSection>
				{(authUser.role === "super_admin" || authUser.role === "manager") && (
					<InviteButton hoverbg="default" onClick={handleShowModal}>
						Invite member
					</InviteButton>
				)}

				{authUser.image_path ? (
					<UserIconContainer
						src={`${process.env.REACT_APP_API_URL}/${authUser.image_path}`}
						alt={authUser.full_name}
						onClick={handleDropdown}
					/>
				) : (
					<NoImageIcon onClick={handleDropdown}>
						{authUser.full_name.slice(0, 1)}
					</NoImageIcon>
				)}
			</NavSection>
		</Navigation>
	);
}

export default memo(NavBar)
