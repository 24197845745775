/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import DashboardCallLayout from "../../../components/Layout/DashboardCallLayout";
import CallSettings from "./CallSettings";
import EndCallDrawer from "./EndCallDrawer";
import UserIndication from "./UserIndication";
import styled, { keyframes } from "styled-components";
import { Paragraph } from "../../../components/Paragraph";
import aud from "../../../assets/audio/resource_outgoing_call.mp3";
import LoadingFeedback from "./LoadingFeedback";
import Feedback from "../../../components/Feedback";
import { vapi } from "../../../utils/configVapi";
import { axiosInstanceWithCred } from "../../../utils/axiosinstance";
import TranscriptError from "./TranscriptError";

const ripple = keyframes`
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
`;

const DailingContainer = styled.div`
	width: 205.35px;
	height: 205.35px;
	margin-top: 10%;
	margin-inline: auto;
	position: relative;

	&::after {
		content: "";
		opacity: 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: -1px;
		left: -1px;
		right: 0;
		bottom: 0;
		border: 1px solid ${(props) => props.theme.colors.primary.green200};
		border-radius: 100%;
		animation: ${ripple} 3s infinite cubic-bezier(0.65, 0, 0.34, 1);
		animation-delay: 0.5s;
		z-index: 1;
	}
`;

const InnerContainer = styled.div`
	width: 205.35px;
	height: 205.35px;
	position: relative;

	&::after {
		content: "";
		opacity: 0;
		position: absolute;
		top: 1px;
		left: 1px;
		right: 0;
		bottom: 0;
		border: 1px solid ${(props) => props.theme.colors.primary.green200};
		border-radius: 100%;
		animation: ${ripple} 3s infinite cubic-bezier(0.65, 0, 0.34, 1);
		animation-delay: 1s;
		z-index: 1;
	}

	&::before {
		content: "";
		opacity: 0;
		position: absolute;
		top: 1px;
		left: 1px;
		right: 0;
		bottom: 0;
		border: 1px solid ${(props) => props.theme.colors.primary.green200};
		border-radius: 100%;
		animation: ${ripple} 3s infinite cubic-bezier(0.65, 0, 0.34, 1);
		animation-delay: 1.5s;
		z-index: 1;
	}
`;

const AIIconConatiner = styled.div`
	padding: 24.27px;
	border-radius: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 5;
	background: ${(props) => props.theme.colors.secondary.default};
`;

const DailingIcon = styled(Paragraph)`
	width: 60.67px;
	height: 53.54px;
	border-radius: 100%;
	padding: 51.34px 47.71px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${(props) => props.theme.colors.secondary.default};
	box-shadow: 0px 5.19px 6.85px 0px #c4fac4 inset,
		0px -7.26px 7.78px 0px #d7ffd7 inset;
`;

export default function InCall() {
	const location = useLocation();
	const timerIntervalRef = useRef(null);
	const audioRef = useRef(null);
	const { vapi_id, image_path } = location.state;
	// console.log(location);
	const [showCallDrawer, setShowCallDrawer] = useState(false);
	const [showDialing, setShowDialing] = useState(true);
	const [detectAISpeaker, setDetectAISpeaker] = useState(false);
	const [callDuration, setCallDuration] = useState("00:00:00");
	const [loading, setLoading] = useState(false);
	const [showFeedback, setShowFeedback] = useState(false);
	const [feedbackData, setFeedbackData] = useState(null);
	const [callID, setCallID] = useState("");
	const [isTranscriptError, setIsTranscriptError] = useState(false);
	const [isCallShort, setIsCallShort] = useState(false);
	const maxRetries = 3; // Define a maximum number of feedback retries

	const startCallDurationTimer = () => {
		const startTime = Date.now();
		timerIntervalRef.current = setInterval(() => {
			const elapsedTime = Date.now() - startTime;
			const hours = Math.floor(elapsedTime / 3600000);
			const minutes = Math.floor((elapsedTime % 3600000) / 60000);
			const seconds = Math.floor((elapsedTime % 60000) / 1000);
			setCallDuration(
				`${hours.toString().padStart(2, "0")}:${minutes
					.toString()
					.padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
			);
		}, 1000);
	};

	const stopCallDurationTimer = () => {
		if (timerIntervalRef.current) {
			clearInterval(timerIntervalRef.current);
			timerIntervalRef.current = null;
		}
		setCallDuration("00:00:00");
	};

	const handleUserInteraction = () => {
		if (showDialing && audioRef.current) {
			audioRef.current.play().catch((error) => {
				console.error("Error playing audio:", error);
			});
		}
	};

	const handleRestartCall = async () => {
		vapi.stop();
		setIsTranscriptError(false);
		setShowFeedback(false);
		setIsCallShort(false);
		stopCallDurationTimer();
		setShowDialing(true);
	};

	const handleDrawerDisplay = () => {
		setShowCallDrawer((prev) => !prev);
	};

	const convertTimeToSeconds = (timeString) => {
		const [hours, minutes, seconds] = timeString.split(":").map(Number);
		return hours * 3600 + minutes * 60 + seconds;
	};


	const handleDisplayFeedback = async (currentRetryCount = 0) => {
		setLoading(true);

		// Convert callDuration to seconds for comparison
		const callDurationInSeconds = convertTimeToSeconds(callDuration);

		if (callDurationInSeconds <= 30) {
			setLoading(false);
			setIsCallShort(true);
			setIsTranscriptError(true);
			return;
		}

		const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
		await delay(10000); // 10-second delay

		try {
			const response = await axiosInstanceWithCred.post("/report_card", {
				id: callID,
				assistant_id: vapi_id,
			});

			if (response.status === 200) {
				setFeedbackData(response.data);
				setIsTranscriptError(false);
				setShowFeedback(true);
				setLoading(false);
			}
		} catch (error) {
			if (error.response) {
				console.error("Status:", error.response.status);
				console.error("Headers:", JSON.stringify(error.response.headers));
				console.error("Data:", error.response.data);

				if (error.response.status === 403) {
					// If session expired, handle accordingly
					setLoading(false);
					setIsTranscriptError(true);
					return;
				}

				if (currentRetryCount < maxRetries) {
					console.log("Retrying to fetch simulation feedback...");
					await handleDisplayFeedback(currentRetryCount + 1); // Retry with updated count
				} else {
					console.error("Max retries reached. Cannot fetch feedback.");
					setLoading(false);
					setIsTranscriptError(true);
				}
			} else {
				console.error("Error Message:", error.message);
				setLoading(false);
				stopCallDurationTimer();
			}
		}
	};

	const handleEndCall = async () => {
		console.log("Ending call...");
		vapi.stop();
		stopCallDurationTimer();
		setShowCallDrawer(false);
		console.log("Call stopped successfully");

		await handleDisplayFeedback(); // Start the feedback retrieval with retry mechanism
	};

	const initVAPI = useCallback(async () => {
		try {
			const response = await vapi.start(vapi_id);
			// console.log("initVAPI response", response);
			setCallID(response.id);
			setShowDialing(false);
			if (audioRef.current) {
				audioRef.current.pause();
				audioRef.current.currentTime = 0;
			}
		} catch (error) {
			console.error("Error connecting VAPI", error);
		}
	}, [vapi_id]);

	useEffect(() => {
		if (showDialing) {
			initVAPI();
		}

		const handleSpeechStart = () => setDetectAISpeaker(true);
		const handleSpeechEnd = () => setDetectAISpeaker(false);
		const handleCallStart = () => startCallDurationTimer();
		const handleCallEnd = () => {
			console.log("Call has ended.");
		};
		const handleMessage = (message) =>
			console.log("Transcript message", message);
		const handleError = (error) => console.error(error);

		vapi.on("speech-start", handleSpeechStart);
		vapi.on("speech-end", handleSpeechEnd);
		vapi.on("call-start", handleCallStart);
		vapi.on("call-end", handleCallEnd);
		vapi.on("error", handleError);
		vapi.on("message", handleMessage);

		document.addEventListener("click", handleUserInteraction);

		return () => {
			vapi.off("speech-start", handleSpeechStart);
			vapi.off("speech-end", handleSpeechEnd);
			vapi.off("call-start", handleCallStart);
			vapi.off("call-end", handleCallEnd);
			vapi.off("error", handleError);
			vapi.off("message", handleMessage);

			if (audioRef.current) {
				audioRef.current.pause();
				audioRef.current.currentTime = 0;
			}

			document.removeEventListener("click", handleUserInteraction);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showDialing]);

	return (
		<DashboardCallLayout>
			{loading ? (
				<LoadingFeedback />
			) : (
				<>
					{isTranscriptError && (
						<TranscriptError
							handleRestartCall={handleRestartCall}
							isCallShort={isCallShort}
						/>
					)}
					{showFeedback && (
						<Feedback
							feedbackData={feedbackData}
							setShowFeedback={setShowFeedback}
							handleRestartCall={handleRestartCall}
						/>
					)}
					<audio id="outgoingCallSound" ref={audioRef} loop>
						<source src={aud} type="audio/mpeg" />
						Your browser does not support the audio element.
					</audio>
					{showDialing ? (
						<DailingContainer>
							<InnerContainer>
								<AIIconConatiner>
									<DailingIcon color="grey00" fontWeight={700}>
										Dialing
									</DailingIcon>
								</AIIconConatiner>
							</InnerContainer>
						</DailingContainer>
					) : (
						<div>
							<UserIndication
								detectSpeaker={detectAISpeaker}
								image_path={image_path}
							/>
							<CallSettings
								displayDrawer={handleDrawerDisplay}
								handleRestartCall={handleRestartCall}
								callDuration={callDuration}
							/>
							{showCallDrawer && (
								<EndCallDrawer
									setDrawerDisplay={setShowCallDrawer}
									handleEndCall={handleEndCall}
								/>
							)}
						</div>
					)}
				</>
			)}
		</DashboardCallLayout>
	);
}
