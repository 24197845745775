import React from "react";
import { Paragraph } from "../../../components/Paragraph";
import { H4 } from "../../../components/Headers";
import styled, { keyframes } from "styled-components";
import { FeedbackIcon } from "../../../components/Icons/SvgIcons";
import { theme } from "../../../theme/theme";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 30px;
	margin-top: 10%;
`;

const RotatingBorder = styled.div`
	border-radius: 100%;
	border: 8px solid #102d23;
	border-bottom-color: ${(props) => props.theme.colors.secondary.default};
	width: 217px;
	height: 217px;
	top: -6.56px;
	left: -6.56px;
	position: absolute;
	animation: ${rotate} 1s linear infinite;
`;

const IconContainer = styled.div`
	display: inline-block;
	position: relative;
	padding: 33.56px;
	border-radius: 50%;
`;

const InnerContainer = styled.div`
	background: #0a1a0f;
	box-shadow: 0px 3.78px 5px 0px #031913 inset;
	border-radius: 50%;
	padding: 42px 44.5px 44.56px 44.91px;
	border: 1px solid #0a1a0f;
	position: relative;
	z-index: 1;
`;

const TextContainer = styled.div`
	width: 100%;
	max-width: 384px;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export default function LoadingFeedback() {
	return (
		<LoadingContainer>
			<IconContainer>
				<RotatingBorder />
				<InnerContainer>
					<FeedbackIcon color={theme.colors.neutral.grey00} />
				</InnerContainer>
			</IconContainer>
			<TextContainer>
				<H4 color="grey00" fontSize="xs" fontWeight={700}>
					Preparing your feedback
				</H4>
				<Paragraph color="grey400" fontSize="md" fontWeight={400}>
					Almost there! Your feedback will be ready in just a few seconds.
				</Paragraph>
			</TextContainer>
		</LoadingContainer>
	);
}
