import React from 'react';
import styled from "styled-components";
import { Paragraph } from '../../components/Paragraph';
import { Button } from '../../components/Button';
import Img1 from "../../assets/ekho-white-logo.svg";
import Img2 from "../../assets/simulate-image.svg";
import logo from "../../assets/Frame 40565.svg";
import logo2 from "../../assets/Frame 40565 (1).svg";
import { useNavigate } from 'react-router-dom';


const EmptyContainer = styled.div`
	padding: 104px 0px;
	border-radius: 0 0 16px 16px;
	margin-top: 0px;
	background: ${(props) => props.theme.colors.neutral.grey00};
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	border-left: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	border-right: 1px solid ${(props) => props.theme.colors.neutral.grey100};
`;

const ContentContainer = styled.div`
	max-width: 315px;
	margin: auto;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const PersonaContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 16px;
`;

const ImageContainer1 = styled.div`
	background: ${(props) => props.theme.colors.secondary.default};
	border-radius: 100%;
	width: 71.94px;
	height: 71.94px;
	display: flex;
	align-item: center;
	justify-content: center;
	position: relative;
`;

const ImageContainer = styled.div`
	border-radius: 100%;
	width: 71.94px;
	height: 71.94px;
	display: flex;
	align-item: center;
	justify-content: center;
	position: relative;
`;

const Image1 = styled.img`
	width: 32.45px;
	height: 28.63px;
	margin: auto;
`;

const Image = styled.img`
	width: 71.94px;
	height: 71.94px;
`;

const SmallerLogo = styled.img`
	width: 20.03px;
	height: 15.09px;
	position: absolute;
	bottom: -8px;
`;

const SimulateButton = styled(Button)`
	padding: 12px 16px;
	width: 215px;
`;

export default function EmptyTableState() {
	const navigate = useNavigate();
  return (
		<EmptyContainer>
			<ContentContainer>
				<PersonaContainer>
					<ImageContainer1>
						<Image1 src={Img1} alt="" />
						<SmallerLogo src={logo} alt="" />
					</ImageContainer1>
					<ImageContainer>
						<Image src={Img2} alt="" />
						<SmallerLogo src={logo2} alt="" />
					</ImageContainer>
				</PersonaContainer>
				<Paragraph fontSize="lg" color="grey500" fontWeight={700}>
					Start a Simulation
				</Paragraph>
				<Paragraph fontSize="sm" color="grey400" fontWeight={400}>
					Complete a Simulation to add data to your Track page
				</Paragraph>
				<SimulateButton
					hoverbg="green100"
					bg="deafult"
					color="grey00"
					bordercolor="default"
					hoverbordercolor="green100"
					style={{ alignSelf: "center" }}
					onClick={() => navigate("/simulate/persona")}
				>
					Start a simulation
				</SimulateButton>
			</ContentContainer>
		</EmptyContainer>
	);
}
