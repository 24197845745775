import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { ChevronRight } from "../Icons/SvgIcons";
import { vapi } from "../../utils/configVapi";

const BreadCrumbContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	text-transform: capitalize;
`;

const ActiveLink = styled(Link)`
	text-decoration: none !important;
	font-weight: 600;
	color: ${(props) => props.theme.colors.neutral.default};
	font-size: ${(props) => props.theme.paragraphText.md};
`;

const StacticLink = styled.span`
	color: ${(props) => props.theme.colors.neutral.grey300};
	font-weight: 600;
	font-size: ${(props) => props.theme.paragraphText.md};
`;

export default function BreadCrumb() {
	const location = useLocation();
	const theme = useTheme();
	const { pathname } = location;
	const pathnames = pathname.split("/").filter((x) => x);

	const handleEndCall = () => {
		console.log("Ending call...");
		vapi.stop();
		console.log("Call stopped successfully");
	};


	return (
		<BreadCrumbContainer>
			{pathnames.length > 0 && (
				<ActiveLink
					to="/dashboard/tracking"
					style={{
						color:
							location.pathname === "/simulate/in-call"
								? theme.colors.primary.green200
								: "inherit",
					}}
					onClick={handleEndCall}
				>
					{pathnames[0]}
				</ActiveLink>
			)}{" "}
			{pathnames.slice(1).map((value, index) => {
				const to = `/${pathnames.slice(0, index + 2).join("/")}`;
				const isLast = index === pathnames.length - 2;
				return (
					<React.Fragment key={to}>
						<span>
							<ChevronRight
								color={
									location.pathname === "/simulate/in-call"
										? theme.colors.primary.green200
										: theme.colors.neutral.grey200
								}
							/>
						</span>{" "}
						{isLast ? (
							<StacticLink>{value}</StacticLink>
						) : (
							<ActiveLink to={to}>{value}</ActiveLink>
						)}
					</React.Fragment>
				);
			})}
		</BreadCrumbContainer>
	);
}
