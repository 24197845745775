import React, { useEffect } from "react";
import styled, {useTheme} from "styled-components";
import { Button } from "../../../components/Button";
import { CancelIcon, ChevronDownIcon } from "../../../components/Icons/SvgIcons";
import FiltersDropdown from "../../../components/DropdownFIlter";
import { status } from "./personaData";

const FilterButton = styled(Button)`
	min-width: 180.67px;
	border-radius: 100px;
	padding: 8px 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid
		${(props) =>
			props.active
				? props.theme.colors.secondary.default
				: props.theme.colors.neutral.grey100};
	background: ${(props) =>
		props.disabled
			? props.theme.colors.neutral.grey100
			: props.theme.colors.neutral.grey00};

	&:hover {
		background: ${(props) => props.theme.colors.neutral.grey100};
		border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	}
`;

const FixedButton = styled(Button)`
	min-width: 180.67px;
	border-radius: 100px;
	padding: 8px 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${(props) => props.theme.colors.neutral.default};
`;

const PersonaConfigContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const FilterContainer = styled.div`
	display: flex;
	gap: 16px;
`;

const CreatePersonaButton = styled(Button)`
	padding: 12px 35px;
`;

const ChevronIcon = styled(ChevronDownIcon)`
	width: 16px;
	height: 16px;
	color: ${(props) =>
		props.disabled
			? props.theme.colors.neutral.grey400
			: props.theme.colors.neutral.grey700};
`;

const PersonaFiltersDropdown = styled(FiltersDropdown)`
	width: 200px;
	left: 0 !important;
	right: unset;
`;

const Box = styled.div`
	position: relative;
`;

export default function PersonaConfig({
	personaData,
	setPersonaData,
	companyData,
	showStatusDropdown,
	showCompanyDropdown,
	selectStatusFilter,
	selectCompanyFilter,
  handleShowCreatePersona,
	setShowStatusDropdown,
	setShowCompanyDropdown,
	setSelectStatusFilter,
	setSelectCompanyFilter,
}) {
	const theme = useTheme();
		const handleDisplayStatusDropdown = () => {
			setShowStatusDropdown((showStatusDropdown) => !showStatusDropdown);
			setShowCompanyDropdown(false);
		};
		const handleDisplayCompanyDropdown = () => {
			setShowCompanyDropdown((showCompanyDropdown) => !showCompanyDropdown);
			setShowStatusDropdown(false);
		};

		const handleStatusCancel = (e) => {
			setSelectStatusFilter("");
			setShowCompanyDropdown(false);
		};

		const handleCompanyCancel = (e) => {
			setSelectCompanyFilter([]);
			setShowStatusDropdown(false);
		};

			useEffect(() => {
				const fetchData = async () => {
					setPersonaData(personaData);
				};

				fetchData();
			}, [personaData, setPersonaData]);


		const getStatusText = () => {
			if (selectStatusFilter.length === 0) {
				return "No Status Selected";
			}

			if (selectStatusFilter.length === 1) {
				const filter = selectStatusFilter[0];
				if (typeof filter === "boolean") {
					return filter ? "Active Persona" : "Inactive Persona";
				}
				return filter; // Return the text directly if it's not a boolean
			}

			return `${selectStatusFilter.length} status selected`;
		};


	return (
		<PersonaConfigContainer>
			<FilterContainer>
				<Box>
					{selectStatusFilter.length === 0 ? (
						<FilterButton
							fontSize="sm"
							disabled={personaData.length === 0}
							color={personaData.length === 0 ? "grey400" : "grey700"}
							active={showStatusDropdown}
							hovercolor={personaData.length === 0 ? "grey400" : "grey700"}
							onClick={handleDisplayStatusDropdown}
						>
							Status
							<ChevronIcon
								disabled={personaData.length === 0}
								color={
									showStatusDropdown
										? theme.colors.neutral.grey700
										: theme.colors.neutral.grey400
								}
							/>
						</FilterButton>
					) : (
						<FixedButton
							fontSize="sm"
							color="grey00"
							hovercolor="grey00"
							onClick={handleStatusCancel}
						>
							{getStatusText()}
							<CancelIcon size="10" color={theme.colors.neutral.grey00} />
						</FixedButton>
					)}

					{showStatusDropdown && (
						<PersonaFiltersDropdown
							data={status}
							showCheckBox={false}
							showAvatar={false}
							setShowDropdown={setShowStatusDropdown}
							setSelectFilter={setSelectStatusFilter}
						/>
					)}
				</Box>

				<Box>
					{selectCompanyFilter.length === 0 ? (
						<FilterButton
							fontSize="sm"
							disabled={personaData.length === 0}
							color={personaData.length === 0 ? "grey400" : "grey700"}
							active={showCompanyDropdown}
							hovercolor={personaData.length === 0 ? "grey400" : "grey700"}
							onClick={handleDisplayCompanyDropdown}
						>
							Company
							<ChevronIcon
								disabled={personaData.length === 0}
								color={
									showCompanyDropdown
										? theme.colors.neutral.grey700
										: theme.colors.neutral.grey400
								}
							/>
						</FilterButton>
					) : (
						<FixedButton
							fontSize="sm"
							color="grey00"
							hovercolor="grey00"
							onClick={handleCompanyCancel}
						>
							{selectCompanyFilter.length <= 1
								? selectCompanyFilter
								: `${selectCompanyFilter.length} company selected`}
							<CancelIcon size="10" color={theme.colors.neutral.grey00} />
						</FixedButton>
					)}

					{showCompanyDropdown && (
						<PersonaFiltersDropdown
							data={companyData}
							showCheckBox={true}
							showAvatar={true}
							setShowDropdown={setShowCompanyDropdown}
							setSelectFilter={setSelectCompanyFilter}
						/>
					)}
				</Box>
			</FilterContainer>
			<CreatePersonaButton
				hoverbg="green100"
				bg="deafult"
				color="grey00"
				bordercolor="default"
				hoverbordercolor="green100"
				onClick={handleShowCreatePersona}
			>
				Create new persona
			</CreatePersonaButton>
		</PersonaConfigContainer>
	);
}
