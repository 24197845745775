import React from "react";
import styled from "styled-components";
import { Paragraph } from "../Paragraph";

const Conatiner = styled.div`
	position: absolute;
	right: 20px;
	width: auto;
	max-width: 320px;
	padding: 16px;
	border-radius: 8px;
	color: ${(props) => props.color || props.theme.colors.neutral.grey700};
	background: ${(props) =>
		props.background || props.theme.colors.neutral.grey100};
	border: 1px solid
		${(props) => props.color || props.theme.colors.neutral.grey700};
`;

export default function Alert({ children, color, bgColor }) {
	return (
		<Conatiner color={color} background={bgColor}>
			<Paragraph fontSize="sm" fontWeight={500}>
				{children}
			</Paragraph>
		</Conatiner>
	);
}
