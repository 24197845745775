import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import DashboardLayout from "../../../components/Layout/DashboardLayout";
import { H4 } from "../../../components/Headers";
import PersonaCard from "./PersonaCard";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser } from "../../../redux/authSliceLogin";
import { vapi } from "../../../utils/configVapi";
import PersonaConfig from "./PersonaConfig";
import { normalizeDropdownData } from "../../../utils/normalizeDropdownData";
import { fetchPersonas, selectPersonas, setPersonas } from "../../../redux/personaSliceCreation";
import { axiosInstanceWithCred } from "../../../utils/axiosinstance";

const PersonaMainContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 48px;
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;



export default function PersonaSelection() {
	const authUser = useSelector(selectAuthUser);
	const dispatch = useDispatch();
	const firstName = authUser.full_name.split(" ")[0];
	const [personaData, setPersonaData] = useState([]);
	const [companyData, setCompanyData] = useState([]);
	const [showStatusDropdown, setShowStatusDropdown] = useState(false);
	const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
	const [showCreatePersonaModal, setShowCreatePersonaModal] = useState(false);
	const [selectStatusFilter, setSelectStatusFilter] = useState([]);
	const [selectCompanyFilter, setSelectCompanyFilter] = useState([]);

	useEffect(() => {
		const handleFetchPersona = async () => {
			try {
				const response = await dispatch(fetchPersonas());
				if (fetchPersonas.fulfilled.match(response)) {
					// console.log(response);
					const personas = response.payload;
					// console.log(personas);
					dispatch(setPersonas(personas));
				}
			} catch (err) {
				console.error("Persona Error:", err);
			}
		};
		handleFetchPersona();
		
	}, [dispatch]);

	const personas = useSelector(selectPersonas);
	// console.log("personas", personas);

	useEffect(() => {
		const handleFetchComapnyData = async () => {
			try {
				const response = await axiosInstanceWithCred.get("/get-company-names");
				const normalizedData = normalizeDropdownData(response.data);
				setCompanyData(normalizedData);
			} catch (err) {
				console.log(err);
			}
		};
		handleFetchComapnyData();
	}, []);

	useEffect(() => {
		const handleEndCall = () => {
			// console.log("Ending call...");
			vapi.stop();
			// console.log("Call stopped successfully");
		};
		if (vapi) {
			handleEndCall();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const filterData = () => {
			let filteredData = [...personas];

			if (selectStatusFilter.length > 0) {
				filteredData = filteredData.filter((item) =>
					selectStatusFilter.includes(item.status)
				);
			}
			if (selectCompanyFilter.length > 0) {
				filteredData = filteredData.filter((item) =>
					selectCompanyFilter.includes(item.company_name)
				);
			}

			setPersonaData(filteredData);
		};
		filterData();
	}, [selectStatusFilter, selectCompanyFilter, personas]);

	const handleShowCreatePersona = () => {
		setShowCreatePersonaModal(true);
	};

	const memoizedPersonaData = useMemo(() => {
		if (authUser.role === "super_admin") {
			// Correctly returning a sorted copy of the array
			return personaData.sort((a, b) => b.status - a.status);
		} else {
			// Correctly returning a filtered copy of the array
			return personaData.filter((persona) => persona.status === true);
		}
	}, [authUser.role, personaData]);

	// Memoize the company data to avoid unnecessary re-renders
	// const memoizedCompanyData = useMemo(() => companyData, [companyData]);

	return (
		<DashboardLayout>
			<PersonaMainContainer>
				<Container>
					<H4 fontWeight={700} color="grey400" fontSize="sm">
						Hey {firstName}!
					</H4>
					<H4 fontWeight={700} color="grey900" fontSize="md">
						Select a persona to get started
					</H4>
				</Container>
				{authUser.role === "super_admin" && (
					<PersonaConfig
						data={personas}
						personaData={memoizedPersonaData}
						setPersonaData={setPersonaData}
						companyData={companyData}
						showStatusDropdown={showStatusDropdown}
						showCompanyDropdown={showCompanyDropdown}
						selectStatusFilter={selectStatusFilter}
						selectCompanyFilter={selectCompanyFilter}
						handleShowCreatePersona={handleShowCreatePersona}
						setShowStatusDropdown={setShowStatusDropdown}
						setShowCompanyDropdown={setShowCompanyDropdown}
						setSelectStatusFilter={setSelectStatusFilter}
						setSelectCompanyFilter={setSelectCompanyFilter}
					/>
				)}

				<PersonaCard
					companyData={companyData}
					personaData={memoizedPersonaData}
					user={authUser}
					showCreatePersonaModal={showCreatePersonaModal}
					setShowCreatePersonaModal={setShowCreatePersonaModal}
				/>
			</PersonaMainContainer>
		</DashboardLayout>
	);
}
