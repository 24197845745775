import React from 'react';
import styled from "styled-components";
import { CancelIcon } from "../Icons/SvgIcons";



const CancleBox = styled.div`
	position: absolute;
	right: 28px;
	cursor: pointer;
`;

export default function CloseIcon({onClick, color, size}) {
  return (
		<CancleBox onClick={onClick}>
			<CancelIcon color={color} size={size} />
		</CancleBox>
	);
}
