import React, { useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Button } from '../Button';
import { Paragraph } from '../Paragraph';
import { axiosInstanceWithCred } from '../../utils/axiosinstance';
import CloseIcon from '../CancelIcon';
import { H4 } from '../Headers';
import EndpointErrorAlert from '../EndpointErrorAlert';
import InputWithLabel from "../InputWithLabel";
import { CheckCircleBroken, CheckIcon, CopyIcon, InfoIcon } from '../Icons/SvgIcons';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '../../redux/authSliceLogin';


const InnerContainer = styled.div`
	position: relative;
	padding: 28px;
	gap: 10px;
	border-radius: 24px;
	background: linear-gradient(
		180.33deg,
		rgba(96, 208, 129, 0.07) -21.49%,
		rgba(255, 255, 255, 0) 51.27%
	);
`;

const SuccessBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 45px;
	text-align: center;
`;

const TextBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-bottom: 40px;
`;

const Input = styled(InputWithLabel)`
	border: 1px solid
		${(props) =>
			props.active ? props.theme.colors.secondary[props.active] : "inherit"};
	box-shadow: 0px 1px 2px 0px #1018280d;
`;

const InputContainer = styled.div`
	display: flex;
	gap: 24px;
	margin-bottom: 16px;
`;

const SuccessContent = styled.div`
	display: flex;
	padding: 16px;
	gap: 10px;
	border-radius: 8px;
	margin-bottom: 16px;
	border: 1px solid ${(props) => props.theme.colors.warning.default};
`;

const GenerateButton = styled(Button)`
	width: 100%;
	padding: 16px 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 8px;
	color: ${(props) => props.theme.colors.neutral.grey00};
	border: 1px solid ${(props) => props.theme.colors.secondary.default};
	background: ${(props) => props.theme.colors.secondary.default};

	&:hover {
		border: 1px solid ${(props) => props.theme.colors.secondary.green200};
		background: ${(props) => props.theme.colors.secondary.green200};
	}
`;

const ParagraphSuccess = styled(Paragraph)`
	font-size: 16px;
	max-width: 425px;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		max-width: 100%;
	}
`;

const LinkText = styled.div`
	width: 100%;
	padding: 16px;
	border-radius: 8px;
	overflow-x: scroll;
	white-space: nowrap;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	color: ${(props) => props.theme.colors.neutral.default};
	background: ${(props) => props.theme.colors.neutral.grey200};
`;

export default function InviteMember({setShowModal}) {
  	const theme = useTheme();
		const linkRef = useRef(null);
		const authUser = useSelector(selectAuthUser);
		const [companyName, setCompanyName] = useState("");
		const [link, setLink] = useState("");
		const [success, setSuccess] = useState(false);
		const [isCopyLink, setisCopyLink] = useState(false);
		const [error, setError] = useState(null);
  	const handleGenerateLink = async () => {
			try {
				const payload =
					authUser.role === "super_admin" ? { company_name: companyName } : {};
				const response = await axiosInstanceWithCred.post(
					"/generate-links",
					payload
				);
				if (response.status === 200) {
					setLink(response.data.register_link);
					setSuccess(true);
				}
			} catch (err) {
				setError(err);
				console.error("Error generating link", err);
				setSuccess(false);
			}
		};

		const handleCopyLink = () => {
			const link = linkRef.current.innerText;
			navigator.clipboard.writeText(link);
			setisCopyLink(true);
			setTimeout(() => {
				setisCopyLink(false);
			}, 2000);
		};
    	const handleModalClick = (e) => {
				e.stopPropagation(); // Prevent clicks inside the modal from closing it
			};



  return (
		<InnerContainer onClick={handleModalClick}>
			<CloseIcon
				onClick={() => setShowModal(false)}
				color={theme.colors.neutral.grey300}
				size="15"
			/>
			{success ? (
				<SuccessBox>
					<CheckCircleBroken color={theme.colors.secondary.default} />
					<TextBox>
						<H4 color="grey700" fontSize="sm" fontWeight={700}>
							Link Successfully Generated!
						</H4>
						{authUser.role === "super_admin" ? (
							<ParagraphSuccess
								color="grey500"
								// fontSize="lg"
								fontWeight={400}
							>
								An invite link has been created for <b>{companyName}</b>. Share
								it with the manager to create an account.
							</ParagraphSuccess>
						) : (
							<ParagraphSuccess color="grey500" fontSize="lg" fontWeight={400}>
								An invite link has been created. Share it with your team members
								to create an account.
							</ParagraphSuccess>
						)}
					</TextBox>
				</SuccessBox>
			) : (
				<>
					<TextBox>
						<H4 color="grey700" fontSize="sm" fontWeight={700}>
							Generate Invite Link
						</H4>
						<Paragraph color="grey500" fontSize="lg" fontWeight={400}>
							{authUser.role === "super_admin"
								? "Generate a unique invite link to onboard managers."
								: "Generate a unique invite link to onboard team members."}
						</Paragraph>
					</TextBox>
					{authUser.role === "super_admin" ? (
						<>
							{error && error.response.status === 400 && (
								<EndpointErrorAlert>
									{error?.response?.data.error}
								</EndpointErrorAlert>
							)}
							<InputContainer>
								<Input
									label="Company name"
									value={companyName}
									onChange={(e) => setCompanyName(e.target.value)}
									labelFor="company name"
									id="company name"
									type="text"
									placeholder="Enter company name"
									active={companyName.length > 0 ? "default" : ""}
								/>
								<GenerateButton
									onClick={handleGenerateLink}
									style={{ maxWidth: "max-content" }}
									disabled={companyName.length > 1 ? false : true}
								>
									Generate link
								</GenerateButton>
							</InputContainer>
						</>
					) : (
						<>
							{error && error.response.status === 403 && (
								<EndpointErrorAlert>
									{error?.response?.data.error}
								</EndpointErrorAlert>
							)}
							<GenerateButton onClick={handleGenerateLink}>
								Generate link
							</GenerateButton>
						</>
					)}
				</>
			)}
			{(companyName.length > 4 || success) && (
				<SuccessContent>
					<InfoIcon color={theme.colors.warning.default} />
					<Paragraph color="grey700" fontSize="xs" fontWeight={400}>
						Generated link will expire after 2 weeks
					</Paragraph>
				</SuccessContent>
			)}
			{success && (
				<InputContainer>
					<LinkText ref={linkRef}>{link}</LinkText>
					<GenerateButton
						style={{ maxWidth: "max-content" }}
						onClick={handleCopyLink}
					>
						Copy
						{isCopyLink ? (
							<CheckIcon color={theme.colors.neutral.grey00} />
						) : (
							<CopyIcon color={theme.colors.neutral.grey00} />
						)}
					</GenerateButton>
				</InputContainer>
			)}
		</InnerContainer>
	);
}
