import React, { useState } from "react";
import { H1 } from "../../../components/Headers";
import styled from "styled-components";
// import InputDropDown from "../../../components/InputDropDown";
// import { companySize, roles } from "./data";
import { Paragraph } from "../../../components/Paragraph";
import { AuthLayout } from "../../../components/Layout/AuthLayout";
import InputWithLabel from "../../../components/InputWithLabel";
import infoIcon from "../../../assets/info-circle.svg";
import eyeIcon from "../../../assets/eye.svg";
import eyeOffIcon from "../../../assets/eye-off.svg";
import { Button } from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	handleSignupRedux,
	setAuthLoading,
	setAuthError,
	clearError,
} from "../../../redux/authSliceSignup";
import {
	AuthErrorAlert,
	AuthErrorAlertPassword,
} from "../../../components/Layout/AuthErrorAlert";
import EndpointErrorAlert from "../../../components/EndpointErrorAlert";

const Header1 = styled(H1)`
	text-transform: capitalize;
	line-height: 44px;
`;

const FieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: 32px;
`;

const EmailBox = styled.div`
	position: relative;
`;

const AnchorTag = styled.a`
	color: #667085;
`;

const PasswordInstructionText = styled.div``;

const ErrorIcon = styled.img`
	position: absolute;
	right: 10px;
	top: 24px;
`;

const EyeIcon = styled.img`
	position: absolute;
	right: 15px;
	top: 16px;
	cursor: pointer;
	width: 20px;
	height: 20px;
`;

export default function SignupPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token } = useParams();
	const loading = useSelector(setAuthLoading);
	const signupError = useSelector(setAuthError);
	const [fullName, setFullName] = useState("");
	const [companyEmail, setCompanyEmail] = useState("");
	// const [showRoles, setShowRoles] = useState(false);
	// const [showCompany, setShowCompany] = useState(false);
	// const [role, setRole] = useState("");
	// const [company_Size, setCompany_Size] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [errors, setErrors] = useState({
		uppercase: false,
		lowercase: false,
		specialChar: false,
		length: false,
		match: false,
	});

	const isFormComplete =
		fullName && companyEmail && password && confirmPassword;

	const togglePasswordVisibility = () => {
		setShowPassword((prev) => !prev);
	};

	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword((prev) => !prev);
	};

	const validatePassword = (password) => {
		setErrors((prevErrors) => ({
			...prevErrors,
			uppercase: !/[A-Z]/.test(password),
			lowercase: !/[a-z]/.test(password),
			specialChar: !/[!@#$%^&*(),.?":{}|<>]/.test(password),
			length: password.length < 8,
			match: password !== "" && password !== confirmPassword,
		}));
	};

	const validateConfirmPassword = (confirmPassword) => {
		setErrors((prevErrors) => ({
			...prevErrors,
			match: password !== "" && password !== confirmPassword,
		}));
	};

	const handlePasswordChange = (e) => {
		const newPassword = e.target.value;
		setPassword(newPassword);
		validatePassword(newPassword);
	};

	const handleConfirmPasswordChange = (e) => {
		const newConfirmPassword = e.target.value;
		setConfirmPassword(newConfirmPassword);
		validateConfirmPassword(newConfirmPassword);
	};

	const handleSignup = async (e) => {
		e.preventDefault();

		const newErrors = {
			match: password !== confirmPassword,
			length: password.length < 8,
			uppercase: !/[A-Z]/.test(password),
			lowercase: !/[a-z]/.test(password),
			specialChar: !/[!@#$%^&*(),.?":{}|<>]/.test(password),
		};

		const hasError = Object.values(newErrors).some((error) => error);

		setErrors(newErrors);

		if (!hasError) {
			const payload = {
				email: companyEmail,
				password: password,
				full_name: fullName,
				token: token,
			};

			try {
				const response = await dispatch(handleSignupRedux(payload));
				if (handleSignupRedux.fulfilled.match(response)) {
					navigate("/email-sent", {
						state: {
							email: companyEmail,
						},
					});
				}
			} catch (error) {
				console.log("Signup failed:", error);
			}
		}

		// Clear the error after a delay
		setTimeout(() => {
			dispatch(clearError());
		}, 3000);
	};

	console.log("signupError", signupError);


	return (
		<AuthLayout>
			<Header1 fontWeight={700} fontSize="md" color="grey900">
				Sign up
			</Header1>

			<FieldWrapper>
				{signupError && (
					<>
						{signupError.message === "Network Error" && (
							<EndpointErrorAlert>
								Network Error!! refresh your browser or contact support.
							</EndpointErrorAlert>
						)}

						{signupError?.response && (
							<EndpointErrorAlert>
								{signupError?.response?.data?.error}
							</EndpointErrorAlert>
						)}
					</>
				)}

				<InputWithLabel
					label="Full name"
					value={fullName}
					onChange={(e) => setFullName(e.target.value)}
					labelFor="name"
					id="name"
					type="text"
					placeholder="Full name"
				/>
				<EmailBox>
					<InputWithLabel
						label="Company email"
						value={companyEmail}
						onChange={(e) => setCompanyEmail(e.target.value)}
						labelFor="email"
						id="email"
						type="email"
						placeholder="Company email"
						labelColor={
							signupError?.response?.data?.code === "INVALID_EMAIL_DOMAIN"
								? "default"
								: ""
						}
						borderColor={
							signupError?.response?.data?.code === "INVALID_EMAIL_DOMAIN"
								? "default"
								: ""
						}
					/>

					{signupError?.response?.data?.code === "INVALID_EMAIL_DOMAIN" && (
						<>
							<AuthErrorAlert message={signupError?.response?.data?.error} />
							<ErrorIcon src={infoIcon} alt="error icon" />
						</>
					)}
				</EmailBox>
				{/* <InputDropDown
					show={showRoles}
					dropdownData={roles}
					getDropDownValue={setRole}
					onToggle={() => setShowRoles(!showRoles)}
				>
					Role
				</InputDropDown>
				<InputDropDown
					show={showCompany}
					dropdownData={companySize}
					getDropDownValue={setCompany_Size}
					onToggle={() => setShowCompany(!showCompany)}
				>
					Company size
				</InputDropDown> */}
				<EmailBox>
					<InputWithLabel
						label="Create password"
						value={password}
						onChange={handlePasswordChange}
						labelFor="password"
						id="password"
						type={showPassword ? "text" : "password"}
						placeholder="Create password"
						labelColor={
							Object.values(errors).some((error) => error) ? "default" : ""
						}
						borderColor={
							Object.values(errors).some((error) => error) ? "default" : ""
						}
					/>
					{password.length >= 1 && (
						<EyeIcon
							src={showPassword ? eyeOffIcon : eyeIcon}
							alt="eye icon"
							onClick={togglePasswordVisibility}
							style={
								Object.values(errors).some((error) => error)
									? { right: "35px", top: "22px" }
									: { top: "22px" }
							}
						/>
					)}

					{password.length === 0 && (
						<PasswordInstructionText>
							<Paragraph
								fontWeight={400}
								fontSize="xs"
								style={{ color: "#9CA3AF", margin: "0.5rem 0px 0px" }}
							>
								Must be at least 8 characters.
							</Paragraph>
						</PasswordInstructionText>
					)}
					{Object.values(errors).some((error) => error) && (
						<>
							<ErrorIcon src={infoIcon} alt="error icon" />
							<AuthErrorAlertPassword errors={errors} />
						</>
					)}
				</EmailBox>
				<EmailBox>
					<InputWithLabel
						label="Re-enter password"
						value={confirmPassword}
						onChange={handleConfirmPasswordChange}
						labelFor="password-rest"
						id="password-rest"
						type={showConfirmPassword ? "text" : "password"}
						placeholder="Re-enter password"
						labelColor={
							Object.values(errors).some((error) => error) && confirmPassword
								? "default"
								: ""
						}
						borderColor={
							Object.values(errors).some((error) => error) && confirmPassword
								? "default"
								: ""
						}
					/>
					{confirmPassword.length >= 1 && (
						<EyeIcon
							src={showConfirmPassword ? eyeOffIcon : eyeIcon}
							alt="eye icon"
							style={
								Object.values(errors).some((error) => error) && confirmPassword
									? { right: "35px", top: "22px" }
									: { top: "22px" }
							}
							onClick={toggleConfirmPasswordVisibility}
						/>
					)}

					{Object.values(errors).some((error) => error) && confirmPassword && (
						<>
							<ErrorIcon src={infoIcon} alt="error icon" />
						</>
					)}
				</EmailBox>
				<Button
					bg={isFormComplete ? "default" : ""}
					bordercolor={isFormComplete ? "default" : ""}
					hoverbg={isFormComplete ? "green100" : ""}
					color="grey50"
					disabled={!isFormComplete}
					style={{ marginTop: "1rem" }}
					onClick={handleSignup}
				>
					{loading ? "Processing..." : "Sign up"}
				</Button>
				<Paragraph
					fontSize="xs"
					fontWeight={400}
					style={{ textAlign: "center", marginTop: "1rem" }}
				>
					By signing up, you agree to our{" "}
					<AnchorTag
						href="https://app.termly.io/policy-viewer/policy.html?policyUUID=07345953-622e-45a5-8533-5726177e58d5"
						target="_blank"
					>
						terms of service
					</AnchorTag>{" "}
					and our{" "}
					<AnchorTag
						href="https://app.termly.io/policy-viewer/policy.html?policyUUID=7a915eb8-b0c7-406c-af88-938b4d819064"
						target="_blank"
					>
						privacy policy
					</AnchorTag>
					.
				</Paragraph>
			</FieldWrapper>
		</AuthLayout>
	);
}