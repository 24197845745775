import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { H4 } from '../../../components/Headers';
import { Paragraph } from '../../../components/Paragraph';

const EndCallContainer = styled.div`
	background: #1c4f3d;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  justify-content: center;
  padding: 110px 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 25;
  transition: 1s easi-in-out;
`;
const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	text-align: center;
`;

const ButtonContainer = styled.div`
	width: 100%;
	max-width: 383px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		max-width: 100%;
	}
`;

const ResumeButton = styled(Button)`
	width: 100%;
	padding: 8px 24px;
	border-radius: 100px;
	color: ${(props) => props.theme.colors.primary.green200};
	background: ${(props) => props.theme.colors.neutral.grey200};
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	box-shadow: ${(props) => props.theme.shadow.glassy100};

	&:hover {
		color: ${(props) => props.theme.colors.primary.green200};
		background: ${(props) => props.theme.colors.neutral.grey00};
	}
`;

const EndButton = styled(Button)`
	width: 100%;
	padding: 8px 24px;
	border-radius: 100px;
	color: ${(props) => props.theme.colors.neutral.grey00};
	background: ${(props) => props.theme.colors.error.default};
	border: 1px solid ${(props) => props.theme.colors.error.default};
	box-shadow: ${(props) => props.theme.shadow.glassy100};

	&:hover {
		color: ${(props) => props.theme.colors.neutral.grey00};
		border: 1px solid ${(props) => props.theme.colors.error.red200};
		background: ${(props) => props.theme.colors.error.red200};
	}
`;

export default function EndCallDrawer({ setDrawerDisplay, handleEndCall }) {
	const handleCloseDrawer = () => {
		setDrawerDisplay(false);
	};

	return (
		<EndCallContainer>
			<TextContainer>
				<H4 color="grey00" fontSize="xs" fontWeight={700}>
					Quit Simulation
				</H4>
				<Paragraph color="grey400" fontSize="md" fontWeight={400}>
					Are you sure you want to end this simulation?
				</Paragraph>
			</TextContainer>
			<ButtonContainer>
				<ResumeButton onClick={handleCloseDrawer}>Resume</ResumeButton>
				<EndButton onClick={handleEndCall}>End Simulation</EndButton>
			</ButtonContainer>
		</EndCallContainer>
	);
}
