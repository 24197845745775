import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { Paragraph } from "../../../components/Paragraph";
import { Button } from "../../../components/Button";
import { MoreIcon } from "../../../components/Icons/SvgIcons";
import CreatePersona from "./CreatePersona";
import ActivatePersonaModal from "./ActivatePersonaModal";
import EditPersona from "./EditPersona";
import { handleEditPersona, updatePersona } from "../../../redux/personaSliceCreation";
import { useDispatch } from "react-redux";



const CardMainContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	gap: 24px;
	justify-items: center;

	@media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	}

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		grid-template-columns: 1fr;
	}
`;


const CardContainer = styled.div`
	width: 100%;
	max-width: 450px;
	background: ${(props) =>
		props.active
			? `${props.theme.colors.primary.green50}4d`
			: props.theme.colors.neutral.grey200};
	border-radius: 24px;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	box-sizing: border-box;
	display: grid; /* Use grid layout for equal heights */
	grid-template-rows: auto 1fr; /* First row auto height, second row takes remaining space */
`;


const CardHead = styled.div`
	${'' /* flex-grow: 1; */}
	display: flex;
	padding: 16px;
	gap: 16px;
	border-radius: 24px 24px 0 0;
	background: ${(props) =>
		props.active
			? props.theme.colors.primary.green200
			: props.theme.colors.neutral.grey400};

	align-items: stretch;
`;

const Avatar = styled.img`
	width: 48px;
	height: 48px;
	border-radius: 100%;
	object-fit: cover;
`;

const PositionText = styled(Paragraph)`
	color: ${(props) =>
		props.active
			? props.theme.colors.primary.green50
			: props.theme.colors.neutral.grey300};
`;

const CardDetails = styled.div`
	flex-grow: 1; /* Allow this to grow and fill available space */
	min-height: 130px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 16px;
	padding: 16px 24px;
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	position: relative;
`;

const SimulateButton = styled(Button)`
	width: 100%;
	padding: 8px 24px;
	border-radius: 100px;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	color: ${(props) => props.theme.colors.neutral.grey700};
	background: ${(props) => props.theme.colors.neutral.grey00};

	&:hover {
		border: 1px solid ${(props) => props.theme.colors.secondary.default};
		color: ${(props) => props.theme.colors.neutral.grey00};
		background: ${(props) => props.theme.colors.secondary.default};
	}
`;

const StatusButton = styled(Button)`
	padding: 18px 15px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	color: ${(props) => props.theme.colors.neutral.grey700};
	background: ${(props) => props.theme.colors.neutral.grey00};

	&:hover {
		border: 1px solid ${(props) => props.theme.colors.secondary.default};
		color: ${(props) => props.theme.colors.neutral.grey00};
		background: ${(props) => props.theme.colors.secondary.default};
	}
`;

const SelectPersonaStatus = styled.div`
	width: 208px;
	top: 50px;
	right: 0;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 8px;
	position: absolute;
	border-radius: 16px;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey30};
	background: ${(props) => props.theme.colors.neutral.grey00};
	box-shadow: 0px 0px 30px 0px #8e8e8e40;
`;

const EditButton = styled(Button)`
	padding: 8px 16px;
	border-radius: 8px;
	font-size: 14px;
	text-align: left;
	border: none;
	color: ${(props) => props.theme.colors.neutral.grey800};
	background: ${(props) => props.theme.colors.neutral.grey100}80;

	&:hover {
		color: ${(props) => props.theme.colors.neutral.grey800};
		background: ${(props) => props.theme.colors.neutral.grey200}80;
	}
`;

const DeactivateButton = styled(Button)`
	padding: 8px 16px;
	border-radius: 8px;
	font-size: 14px;
	text-align: left;
	border: none;
	color: ${(props) =>
		props.active
			? props.theme.colors.error.default
			: props.theme.colors.secondary.default};
	background: ${(props) =>
		props.active
			? props.theme.colors.error.red50
			: props.theme.colors.success.green50};

	&:hover {
		color: ${(props) =>
			props.active
				? props.theme.colors.error.default
				: props.theme.colors.secondary.default};
		background: ${(props) =>
			props.active
				? props.theme.colors.error.red100
				: props.theme.colors.success.green100};
	}
`;

export default function PersonaCard({
	companyData,
	user,
	personaData,
	showCreatePersonaModal,
	setShowCreatePersonaModal,
}) {
	const navigate = useNavigate();
	const theme = useTheme();
	const dispatch = useDispatch();
	const [showConfigModal, setShowConfigModal] = useState(null);
	const [ID, setID] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [isActivated, setIsActivated] = useState(null);
	const [showEditPersonaModal, setShowEditPersonaModal] = useState(false);
	const [data, setData] = useState([]);

	// Handlers
	const handleSimultaion = useCallback(
		(id, name, image_path, designation, description) => {
			navigate("/simulate/in-call", {
				state: {
					vapi_id: id,
					name,
					image_path,
					designation,
					description,
				},
			});
		},
		[navigate]
	);

	const handleDisplayConfigModal = useCallback((id) => {
		setShowConfigModal((prevId) => (prevId === id ? null : id));
	}, []);

	const handleUpdateStatus = useCallback(async (id, status) => {
		try {
			const formData = new FormData();
			formData.append("assistant_id", id);
			formData.append("status", status);
			const response = await dispatch(handleEditPersona(formData));
			if (handleEditPersona.fulfilled.match(response)) {
				// console.log("response", response);
				const persona = response.payload.persona;
				// console.log("persona", persona);
				dispatch(updatePersona(persona));
			}
		} catch (err) {
			console.error("Status Err:", err);
		}
	}, [dispatch]);

	const handleDeactivate = useCallback(
		async (id) => {
			await handleUpdateStatus(id, false);
			setShowModal(false);
			setShowConfigModal(false);
		},
		[handleUpdateStatus]
	);

	const handleActivate = useCallback(
		async (id) => {
			await handleUpdateStatus(id, true);
			setShowModal(false);
			setShowConfigModal(false);
		},
		[handleUpdateStatus]
	);

	const handleCancelConfig = useCallback(() => {
		setShowModal(false);
		setShowConfigModal(false);
	}, []);

	const handleShowEditPersona = useCallback(
		(
			id,
			name,
			image_path,
			designation,
			description,
			status,
			prompt,
			company
		) => {
			setShowEditPersonaModal(true);
			setData({
				id,
				name,
				image_path,
				designation,
				description,
				status,
				prompt,
				company,
			});
		},
		[]
	);

	const handleChangeStatus = useCallback((persona) => {
		setIsActivated(persona.status);
		setID(persona["assistant id"]);
		setShowModal(true);
	}, []);

	// const truncateText = (text, maxLength) => {
	// 	if (text.length > maxLength) {
	// 		return text.slice(0, maxLength) + "...";
	// 	}
	// 	return text;
	// };

	return (
		<CardMainContainer>
			{showCreatePersonaModal && (
				<CreatePersona
					companyData={companyData}
					setShowModal={setShowCreatePersonaModal}
					showConfigModal={setShowConfigModal}
				/>
			)}
			{showEditPersonaModal && (
				<EditPersona
					companyData={companyData}
					setShowModal={setShowEditPersonaModal}
					personaValue={data}
					showConfigModal={setShowConfigModal}
				/>
			)}
			{personaData.map((persona) => (
				<CardContainer active={persona.status} key={persona["assistant id"]}>
					<CardHead active={persona.status}>
						<Avatar
							src={`${process.env.REACT_APP_API_URL}/${persona.image_path}`}
							alt={persona.full_name}
						/>
						<div>
							<Paragraph color="grey100" fontSize="lg" fontWeight={700}>
								{persona.full_name}
							</Paragraph>
							<PositionText
								fontSize="xs"
								fontWeight={400}
								active={persona.status}
							>
								{persona.designation}
							</PositionText>
						</div>
					</CardHead>
					<CardDetails>
						<Paragraph
							color="grey500"
							fontSize="md"
							fontWeight={400}
							title={persona.description}
						>
							{persona.description}
						</Paragraph>
						<ButtonContainer>
							<SimulateButton
								onClick={() =>
									handleSimultaion(
										persona["assistant id"],
										persona.full_name,
										persona.image_path,
										persona.designation,
										persona.description
									)
								}
							>
								Start Simulation
							</SimulateButton>
							{user.role === "super_admin" && (
								<StatusButton
									onClick={() =>
										handleDisplayConfigModal(persona["assistant id"])
									}
								>
									<MoreIcon color={theme.colors.neutral.grey800} />
								</StatusButton>
							)}
							{showConfigModal === persona["assistant id"] && (
								<SelectPersonaStatus>
									<EditButton
										onClick={() =>
											handleShowEditPersona(
												persona["assistant id"],
												persona.full_name,
												persona.image_path,
												persona.designation,
												persona.description,
												persona.status,
												persona.persona_prompt,
												persona.company_name
											)
										}
									>
										Edit Persona
									</EditButton>
									<DeactivateButton
										onClick={() => handleChangeStatus(persona)}
										active={persona.status}
									>
										{persona.status ? "Deactivate Persona" : "Activate Persona"}
									</DeactivateButton>
								</SelectPersonaStatus>
							)}
							{showModal && (
								<ActivatePersonaModal
									setShowModal={setShowModal}
									isActivated={isActivated}
									handleCancelConfig={handleCancelConfig}
									id={ID}
									handleDeactivate={handleDeactivate}
									handleActivate={handleActivate}
								/>
							)}
						</ButtonContainer>
					</CardDetails>
				</CardContainer>
			))}
		</CardMainContainer>
	);
}
