import React from 'react';


export const EkhoLogo = () => {
	return (
		<svg
			width="32.45"
	    height="28.63"
			viewBox="0 0 48 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.8933 41.6823C12.2561 41.7191 7.62529 32.4181 7.55009 20.9079C7.47489 9.39766 11.9838 0.0369378 17.621 0.000108996C21.7926 -0.0271447 25.4131 5.05912 27.0442 12.3701C28.1903 10.3799 29.7071 9.16632 31.3773 9.15541C35.0053 9.1317 37.9835 14.7916 38.0293 21.7971C38.075 28.8026 35.1711 34.501 31.5431 34.5247C29.6925 34.5368 28.011 33.07 26.8015 30.7002C25.1168 37.2172 21.7722 41.6569 17.8933 41.6823Z"
				fill="#60D081"
			/>
			<path
				d="M2.55385 25.6621C1.12565 25.6527 -0.0176561 23.4453 0.00020649 20.7317C0.0180691 18.0181 1.19034 15.826 2.61854 15.8354C4.04674 15.8448 5.19005 18.0522 5.17219 20.7658C5.15432 23.4794 3.98206 25.6715 2.55385 25.6621Z"
				fill="#60D081"
			/>
			<path
				d="M43.7569 29.4255C41.8597 29.413 40.3409 26.4807 40.3646 22.876C40.3884 19.2713 41.9456 16.3592 43.8428 16.3717C45.74 16.3842 47.2588 19.3165 47.2351 22.9212C47.2113 26.5259 45.6541 29.438 43.7569 29.4255Z"
				fill="#60D081"
			/>
		</svg>
	);
	
}

export const SimulateIcon = ({color}) => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.0497 6.63135C15.0264 6.82191 15.924 7.2996 16.6277 8.00328C17.3314 8.70696 17.8091 9.60461 17.9997 10.5813M14.0497 2.63135C16.0789 2.85678 17.9713 3.76552 19.4159 5.20836C20.8606 6.65119 21.7717 8.54236 21.9997 10.5713M10.2266 14.4944C9.02506 13.2928 8.07627 11.9342 7.38028 10.4846C7.32041 10.3599 7.29048 10.2975 7.26748 10.2187C7.18576 9.9383 7.24446 9.59404 7.41447 9.35661C7.46231 9.2898 7.51947 9.23264 7.63378 9.11833C7.98338 8.76872 8.15819 8.59392 8.27247 8.41814C8.70347 7.75525 8.70347 6.90067 8.27247 6.23778C8.15819 6.062 7.98338 5.8872 7.63378 5.53759L7.43891 5.34272C6.90747 4.81128 6.64174 4.54556 6.35636 4.40121C5.7888 4.11414 5.11854 4.11414 4.55098 4.40121C4.2656 4.54556 3.99987 4.81128 3.46843 5.34272L3.3108 5.50036C2.78117 6.02998 2.51636 6.29479 2.31411 6.65482C2.08969 7.05433 1.92833 7.67482 1.9297 8.13304C1.93092 8.54599 2.01103 8.82821 2.17124 9.39266C3.03221 12.4261 4.65668 15.2884 7.04466 17.6764C9.43264 20.0644 12.295 21.6889 15.3284 22.5498C15.8928 22.71 16.1751 22.7901 16.588 22.7914C17.0462 22.7927 17.6667 22.6314 18.0662 22.4069C18.4263 22.2047 18.6911 21.9399 19.2207 21.4103L19.3783 21.2526C19.9098 20.7212 20.1755 20.4555 20.3198 20.1701C20.6069 19.6025 20.6069 18.9323 20.3198 18.3647C20.1755 18.0793 19.9098 17.8136 19.3783 17.2821L19.1835 17.0873C18.8339 16.7377 18.6591 16.5629 18.4833 16.4486C17.8204 16.0176 16.9658 16.0176 16.3029 16.4486C16.1271 16.5629 15.9523 16.7377 15.6027 17.0873C15.4884 17.2016 15.4313 17.2587 15.3644 17.3066C15.127 17.4766 14.7828 17.5353 14.5024 17.4536C14.4235 17.4306 14.3612 17.4006 14.2365 17.3408C12.7869 16.6448 11.4282 15.696 10.2266 14.4944Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const TrackIcon = ({ color }) => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M17.2 14.6315C17.477 14.6315 17.6155 14.6315 17.7278 14.693C17.8204 14.7438 17.9065 14.839 17.9478 14.9362C17.9978 15.0541 17.9852 15.1795 17.96 15.4302C17.8296 16.7303 17.3822 17.983 16.6518 19.0761C15.7727 20.3917 14.5233 21.4171 13.0615 22.0226C11.5997 22.6281 9.99113 22.7865 8.43928 22.4778C6.88743 22.1691 5.46197 21.4072 4.34315 20.2884C3.22433 19.1696 2.4624 17.7441 2.15372 16.1922C1.84504 14.6404 2.00347 13.0319 2.60897 11.5701C3.21447 10.1082 4.23985 8.85881 5.55544 7.97976C6.64856 7.24936 7.90125 6.80192 9.20131 6.67148C9.45207 6.64632 9.57745 6.63374 9.69528 6.68373C9.79249 6.72497 9.88776 6.81117 9.9385 6.90377C10 7.01602 10 7.15452 10 7.43152V13.8315C10 14.1115 10 14.2516 10.0545 14.3585C10.1024 14.4526 10.1789 14.5291 10.273 14.577C10.38 14.6315 10.52 14.6315 10.8 14.6315H17.2Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14 3.43152C14 3.15451 14 3.01601 14.0615 2.90376C14.1122 2.81116 14.2075 2.72497 14.3047 2.68373C14.4225 2.63373 14.5479 2.64631 14.7987 2.67146C16.6271 2.85486 18.346 3.66382 19.6569 4.97466C20.9677 6.28551 21.7767 8.00442 21.9601 9.83282C21.9852 10.0836 21.9978 10.209 21.9478 10.3268C21.9066 10.424 21.8204 10.5193 21.7278 10.57C21.6155 10.6315 21.477 10.6315 21.2 10.6315L14.8 10.6315C14.52 10.6315 14.38 10.6315 14.273 10.577C14.1789 10.5291 14.1024 10.4526 14.0545 10.3585C14 10.2516 14 10.1115 14 9.83152V3.43152Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);


export const SettingIcon = ({color}) => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.39504 20.0025L9.97949 21.3169C10.1532 21.7082 10.4368 22.0406 10.7957 22.2739C11.1547 22.5073 11.5736 22.6314 12.0017 22.6313C12.4298 22.6314 12.8488 22.5073 13.2077 22.2739C13.5667 22.0406 13.8502 21.7082 14.0239 21.3169L14.6084 20.0025C14.8164 19.536 15.1664 19.1472 15.6084 18.8913C16.0532 18.6348 16.5677 18.5255 17.0784 18.5791L18.5084 18.7313C18.934 18.7764 19.3636 18.6969 19.7451 18.5027C20.1265 18.3084 20.4434 18.0077 20.6573 17.6369C20.8714 17.2664 20.9735 16.8416 20.951 16.4142C20.9285 15.9868 20.7825 15.5751 20.5306 15.2291L19.6839 14.0658C19.3825 13.6485 19.2214 13.1462 19.2239 12.6313C19.2238 12.1179 19.3864 11.6177 19.6884 11.2025L20.535 10.0391C20.7869 9.6931 20.933 9.28142 20.9554 8.85402C20.9779 8.42662 20.8759 8.00189 20.6617 7.63135C20.4478 7.26058 20.1309 6.95984 19.7495 6.76557C19.3681 6.57131 18.9385 6.49188 18.5128 6.5369L17.0828 6.68913C16.5722 6.74276 16.0576 6.63347 15.6128 6.3769C15.1699 6.1196 14.8199 5.72871 14.6128 5.26024L14.0239 3.94579C13.8502 3.55452 13.5667 3.22206 13.2077 2.98875C12.8488 2.75543 12.4298 2.63128 12.0017 2.63135C11.5736 2.63128 11.1547 2.75543 10.7957 2.98875C10.4368 3.22206 10.1532 3.55452 9.97949 3.94579L9.39504 5.26024C9.18797 5.72871 8.83792 6.1196 8.39504 6.3769C7.95026 6.63347 7.43571 6.74276 6.92504 6.68913L5.4906 6.5369C5.06493 6.49188 4.63534 6.57131 4.25391 6.76557C3.87249 6.95984 3.55561 7.26058 3.34171 7.63135C3.12753 8.00189 3.02549 8.42662 3.04798 8.85402C3.07046 9.28142 3.2165 9.6931 3.46838 10.0391L4.31504 11.2025C4.61698 11.6177 4.77958 12.1179 4.77949 12.6313C4.77958 13.1448 4.61698 13.645 4.31504 14.0602L3.46838 15.2236C3.2165 15.5696 3.07046 15.9813 3.04798 16.4087C3.02549 16.8361 3.12753 17.2608 3.34171 17.6313C3.55582 18.0019 3.87274 18.3025 4.25411 18.4967C4.63548 18.691 5.06496 18.7705 5.4906 18.7258L6.9206 18.5736C7.43127 18.5199 7.94581 18.6292 8.3906 18.8858C8.83513 19.1424 9.18681 19.5333 9.39504 20.0025Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.9999 15.6313C13.6568 15.6313 14.9999 14.2882 14.9999 12.6313C14.9999 10.9745 13.6568 9.63135 11.9999 9.63135C10.3431 9.63135 8.99992 10.9745 8.99992 12.6313C8.99992 14.2882 10.3431 15.6313 11.9999 15.6313Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);