import React from "react";
import AuthAccessLayout from "../../../components/Layout/AuthAccessLayout";
import successIcon from "../../../assets/check-verified-rough.svg";
import { Paragraph } from "../../../components/Paragraph";
import { Button } from "../../../components/Button";
import { useNavigate } from "react-router-dom";

export default function VerifiedAccount() {
	const navigate = useNavigate();
	const storedEmail = localStorage.getItem("email");

	const handleDashboard = () => {
		navigate("/login");
	};

	return (
		<AuthAccessLayout>
			<img
				src={successIcon}
				alt="success icon"
				style={{ display: "block", margin: "auto" }}
			/>
			<Paragraph fontSize="xl" color="grey700" fontWeight={700}>
				Account verified
			</Paragraph>
			<div
				style={{
					textAlign: "center",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Paragraph
					fontSize="md"
					color="default"
					style={{
						marginTop: "1rem",
						maxWidth: "324px",
						marginInline: "auto",
					}}
				>
					You’ve successfully verified your Ekho account for{" "}
					<b>{storedEmail}</b>.
				</Paragraph>
			</div>

			<Button
				bg="default"
				color="grey00"
				bordercolor="default"
				hoverbg="green100"
				hoverbordercolor="green100"
				style={{ width: "100%", marginTop: "2rem" }}
				onClick={handleDashboard}
			>
				Access your account
			</Button>
		</AuthAccessLayout>
	);
}
