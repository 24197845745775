import React from "react";
import Modal from "../../../components/Modal";
import styled, { useTheme } from "styled-components";
import { Button } from "../../../components/Button";
import { Paragraph } from "../../../components/Paragraph";
import { H4 } from "../../../components/Headers";
import { CheckCircleBroken } from "../../../components/Icons/SvgIcons";
import { useNavigate } from "react-router-dom";

const InnerContainer = styled.div`
	position: relative;
	padding: 40px;
	border-radius: 24px;
	background: linear-gradient(
		180.33deg,
		rgba(96, 208, 129, 0.25) -21.49%,
		rgba(255, 255, 255, 0) 51.27%
	);
	text-align: center;
`;

const TextContainer = styled.div`
	max-width: 425px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-inline: auto;
	margin-block: 40px;
`;

const ButtonConatiner = styled.div`
	display: flex;
	gap: 24px;
	margin-top: 32px;
`;

const DoneButton = styled(Button)`
	width: 100%;
	padding: 16px 32px;
	border-radius: 32px;
	font-size: 16px;
	color: ${(props) => props.theme.colors.neutral.grey00};
	border: 1px solid ${(props) => props.theme.colors.secondary.default};
	background: ${(props) => props.theme.colors.secondary.default};

	&:hover {
		color: ${(props) => props.theme.colors.neutral.grey00};
		border: 1px solid ${(props) => props.theme.colors.secondary.green200};
		background: ${(props) => props.theme.colors.secondary.green200};
	}
`;
export default function PersonaUpdateModal({ setShowModal, showConfigModal }) {
	const theme = useTheme();
	const navigate = useNavigate();
	const handleModalClick = (e) => {
		e.stopPropagation(); // Prevent clicks inside the modal from closing it
	};
	return (
		<Modal setShowModal={setShowModal} style={{ zIndex: 50 }}>
			<InnerContainer onClick={handleModalClick}>
				<CheckCircleBroken color={theme.colors.secondary.default} />
				<TextContainer>
					<H4 fontSize="sm" color="grey700" fontWeight={700}>
						Persona created successfully
					</H4>
					<Paragraph fontSize="md" color="grey500" fontWeight={400}>
						You can activate the created persona immediately or keep it
						deactivated till after testing
					</Paragraph>
				</TextContainer>

				<ButtonConatiner>
					<DoneButton onClick={() => {
						setShowModal(false);
						showConfigModal(false);
						navigate("/simulate/persona")
						}}>
						Done
					</DoneButton>
				</ButtonConatiner>
			</InnerContainer>
		</Modal>
	);
}
