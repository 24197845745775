import React from 'react';
import styled from "styled-components";
import { H1 } from '../../Headers';
import { Paragraph } from '../../Paragraph';
import logo from "../../../assets/ekho-logo.svg";
import { useLocation, useNavigate } from 'react-router-dom';

const AuthContainer = styled.div`
	padding: 15px;
	max-width: 1440px;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1rem;
	${"" /* border: 1px solid red; */}
`;

const AuthNav = styled.div`
	display: flex;
	justify-content: center;
`;

const LogoContainer = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	cursor: pointer;
`;

const FormWrapper = styled.div`
	width: 100%;
	max-width: 703px;
	margin-top: 80px;

	@media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
		margin-inline: auto;
	}
`;

const Container = styled.div`
	max-width: 527px;
	margin-inline: auto;
`;

const AuthWrapper = styled.div`
	background: ${(props) => props.theme.colors.neutral.grey00};
	border-radius: 36px;
	padding: 12px;
	margin-top: 48px;
`;

const Form = styled.form`
	background: ${(props) => props.theme.gradient.lightGreen};
	padding: 40px;
	border-radius: 24px;
  text-align: center;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		padding: 20px;
	}
`;



export default function AuthAccessLayout({ children }) {
	const navigate = useNavigate();
	const location = useLocation();
	return (
		<AuthContainer>
			<FormWrapper>
				<Container>
					<AuthNav>
						<LogoContainer onClick={() => navigate("/")}>
							<img src={logo} alt="ekho logo" />{" "}
							<H1 fontSize="sm" fontWeight={700} color="grey900">
								Ekho
							</H1>
						</LogoContainer>
					</AuthNav>
					<AuthWrapper>
						<Form>{children}</Form>
					</AuthWrapper>
				</Container>
			</FormWrapper>

			{location.pathname === "/signup/email-sent" && (
				<Paragraph fontSize="sm" style={{ maxWidth: "315px", marginTop: "2rem", textAlign: "center" }}>
					Didn’t get email? Check your spam folder. Wrong email?{" "}
					<span
						style={{
							color: "#2D69F6",
							margin: "-0.5rem 0px 0px",
							cursor: "pointer",
						}}
						onClick={() => navigate("/forget-password")}
					>
						Please re-enter your address
					</span>
				</Paragraph>
			)}
		</AuthContainer>
	);
}

