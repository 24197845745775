import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstanceWithCred } from "../utils/axiosinstance";

const initialState = {
	user: null,
	loading: false,
	error: null,
};

export const handleSignupRedux = createAsyncThunk(
	"authSignup/handleSignup",
	async (payload, { rejectWithValue }) => {
		try {
			const response = await axiosInstanceWithCred.post("/register", payload);
			return response.data;
		} catch (error) {
			console.error("Signup error:", error);
			const errorInfo = {
				message: error.message,
				code: error.code,
				response: error.response,
			};
			console.log("errorInfo", errorInfo);
			return rejectWithValue(errorInfo);
		}
	}
);

const authSliceSignup = createSlice({
	name: "authSignup",
	initialState,
	reducers: {
		clearError(state) {
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleSignupRedux.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(handleSignupRedux.fulfilled, (state, action) => {
				state.user = action.payload;
				state.loading = false;
				state.error = null;
			})
			.addCase(handleSignupRedux.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});


export const { clearError } = authSliceSignup.actions;

export const setAuthUser = (state) => state.authSignup.user;
export const setAuthLoading = (state) => state.authSignup.loading;
export const setAuthError = (state) => state.authSignup.error;

export default authSliceSignup.reducer;
