import React from "react";
import styled, { useTheme } from "styled-components";
import { Paragraph } from "../../../components/Paragraph";
import { Button } from "../../../components/Button";
import {
	CancelCallIcon,
	ReloadIcon,
} from "../../../components/Icons/SvgIcons";

const CallSettingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const SettingContainer = styled.div`
	width: 100%;
	max-width: 575px;
	background: #002524;
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
	border-radius: 48px;
	border: 1px solid #013230;
	margin: 92px 0 16px;
	flex-wrap: wrap;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		max-width: 100%;
		justify-content: center;
	}
`;

const CallTimer = styled.div`
	padding: 10px 24px;
	border-radius: 32px;
	color: ${(props) => props.theme.colors.primary.green200};
	background: ${(props) => props.theme.colors.primary.default};
	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		order: 2;
	}
`;

const RequestCallButton = styled(Button)`
	width: 100%;
	max-width: 320px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 8px 24px;
	background: ${(props) => props.theme.colors.neutral.grey00}29;
	color: ${(props) => props.theme.colors.neutral.grey400};
	box-shadow: ${(props) => props.theme.shadow.glassy100};

	&:hover {
		background: ${(props) => props.theme.colors.neutral.grey00}29;
	}

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		order: 1;
		max-width: 100%;
	}
`;

const EndCallButton = styled(Button)`
	display: flex;
	padding: 10px 24px;
	background: ${(props) => props.theme.colors.error.default};
	border: none;

	&:hover {
		background: ${(props) => props.theme.colors.error.default};
	}

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		order: 3;
	}
`;

export default function CallSettings({ displayDrawer, handleRestartCall, callDuration }) {
	const theme = useTheme();
	return (
		<CallSettingContainer>
			<SettingContainer>
				<CallTimer fontSize="md" fontWeight={700}>
					{callDuration}
				</CallTimer>
				<RequestCallButton fontSize="md" onClick={handleRestartCall}>
					Restart call <ReloadIcon color={theme.colors.neutral.grey400} />
				</RequestCallButton>
				<EndCallButton fontSize="md" onClick={displayDrawer}>
					<CancelCallIcon color={theme.colors.neutral.grey00} />
				</EndCallButton>
			</SettingContainer>
			<Paragraph
				fontSize="xs"
				fontWeight={400}
				style={{ color: "#00524C", textAlign: "center" }}
			>
				**Ekho Simulator can sometimes act unexpectedly, restart the call if that happens
			</Paragraph>
		</CallSettingContainer>
	);
}
