import React from 'react';
import styled from "styled-components"
import { H4 } from '../../components/Headers';
import { Button } from '../../components/Button';
import { Paragraph } from '../../components/Paragraph';
import phoneIcon from "../../assets/phone-icon.svg";
import percentIcon from "../../assets/percent-02.svg"
import backgroundImage1 from "../../assets/phone-call-01.svg";
import backgroundImage2 from "../../assets/percent-02.svg";
import { useNavigate } from 'react-router-dom';


const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 50px;
`;
const Box = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 2%;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		width: 100%;
		flex-direction: column;
		align-items: start;
		gap: 20px;
	}
`;

const SimulateButton = styled(Button)`
	${'' /* background: ${(props) => props.theme.colors.neutral.grey00}; */}
	padding: 12px 43.5px;
	box-shadow: ${(props) => props.theme.shadow.glassy100};

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		display: none;
	}
`;

const Container = styled.span`
  display: flex;
	flex-direction: column;
	gap: 8px;
`;

const ResultTitle = styled.div`
  width: auto;
	display: flex;
	align-items: center;
	gap: 10px;
	align-self: start;
	box-shadow: 0px 10px 4px 0px #f3f5f91a;
	box-shadow: 0px 4px 4px 0px #d0d8e61a;
	padding: 4px 12px 4px 4px;
	border-radius: 100px;
	background: ${(props) => props.theme.colors.neutral.grey00};
	border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
`;


const TitleIcon = styled.div`
  display: flex;	
`;


const Image = styled.img`
	width: 12px;
	height: 12px;
	padding: 4px;
	border-radius: 32px;
	box-shadow: 0px 4px 4px 0px #ffffff, 0px -2px 2px 0px #f3f4f6 inset;
	background: ${(props) => props.theme.colors.neutral.grey300};
`;



const SimulationResultBox = styled.div`
	width: 48%;
	display: flex;
	flex-direction: column;
	gap: 39px;
	padding: 24px 35px 35px;
	border-radius: 16px 0 0 16px;
	position: relative;
	overflow: hidden;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	background: ${(props) => props.theme.colors.neutral.grey00};

	&::before {
		content: "";
		display: block;
		width: 40vw; /* Adjust width based on viewport width */
		max-width: 209px; /* Ensure it does not exceed the original size */
		height: 100%; /* Maintain aspect ratio */
		object-fit: contain;
		background: url(${backgroundImage1}) no-repeat center center;
		position: absolute;
		bottom: -10%; /* Adjust bottom position responsively */
		right: 10%; /* Adjust right position responsively */
		z-index: 1; /* Ensure the pseudo-element is behind the content */
		border-radius: inherit;
	}

	@media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
		width: 100%;
		&::before {
			width: 50vw; /* Adjust width for mobile view */
			bottom: -5%; /* Adjust bottom position for mobile view */
			right: 5%; /* Adjust right position for mobile view */
		}
	}
	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		padding: 15px;
		&::before {
			display: none;
		}
	}
`;

const ScoreResultBox = styled.div`
	width: 48%;
	display: flex;
	flex-direction: column;
	gap: 39px;
	padding: 24px 35px 35px;
	border-radius: 0 16px 16px 0;
	position: relative;
	overflow: hidden;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	background: ${(props) => props.theme.colors.neutral.grey00};

	&::before {
		content: "";
		display: block;
		width: 40vw; /* Adjust width based on viewport width */
		max-width: 209px; /* Ensure it does not exceed the original size */
		height: 100%; /* Maintain aspect ratio */
		object-fit: contain;
		background: url(${backgroundImage2}) no-repeat center center;
		position: absolute;
		bottom: -10%; /* Adjust bottom position responsively */
		right: 10%; /* Adjust right position responsively */
		z-index: 1; /* Ensure the pseudo-element is behind the content */
		border-radius: inherit;
	}

	@media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
		width: 100%;
		&::before {
			width: 50vw; /* Adjust width for mobile view */
			bottom: -5%; /* Adjust bottom position for mobile view */
			right: 5%; /* Adjust right position for mobile view */
		}
	}

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		padding: 15px;
		&::before {
			display: none;
		}
	}
`;




export default function TrackHeader({ data, user }) {
	const navigate = useNavigate();
	return (
		<HeaderContainer>
			<Box>
				<Container>
					<H4 fontWeight={700} color="grey400" fontSize="sm">
						Welcome,
					</H4>
					<H4 fontWeight={700} color="grey900" fontSize="md">
						{user.full_name}
					</H4>
				</Container>
				<Container>
					<SimulateButton
						hoverbg="green100"
						bg="deafult"
						color="grey00"
						bordercolor="default"
						hoverbordercolor="green100"
						onClick={() => navigate("/simulate/persona")}
					>
						Start a simulation
					</SimulateButton>
				</Container>
			</Box>
			<Box>
				<SimulationResultBox>
					<ResultTitle>
						<TitleIcon>
							<Image src={phoneIcon} alt="icon" />
						</TitleIcon>

						<Paragraph fontSize="sm" color="grey500" fontWeight={500}>
							Total Simulations
						</Paragraph>
					</ResultTitle>
					{data.length !== 0 ? (
						<Paragraph
							color="grey700"
							fontWeight={700}
							style={{ fontSize: "96px", lineHeight: "90px", zIndex: 2 }}
						>
							{data.length}
						</Paragraph>
					) : (
						<div style={{zIndex: 3}}>
							<Paragraph
								fontSize="lg"
								color="grey500"
								fontWeight={700}
								style={{ paddingBottom: "8px", fontWeight: "700" }}
							>
								Nothing to show yet
							</Paragraph>
							<Paragraph fontSize="sm" color="grey400" fontWeight={400}>
								Complete a Simulation to add data to your Track page
							</Paragraph>
						</div>
					)}
				</SimulationResultBox>
				<ScoreResultBox>
					<ResultTitle>
						<TitleIcon>
							<Image src={percentIcon} alt="icon" />
						</TitleIcon>
						<Paragraph fontSize="sm" color="grey500" fontWeight={500}>
							Average Score
						</Paragraph>
					</ResultTitle>

					{data.length !== 0 ? (
						<Paragraph
							color="grey700"
							fontWeight={700}
							style={{ fontSize: "96px", lineHeight: "90px", zIndex: 2 }}
						>
							{(
								data.reduce(
									(sum, item) => sum + Number(item.score.replace("%", "")),
									0
								) / data.length
							).toFixed(0)}
							%
						</Paragraph>
					) : (
						<div style={{zIndex: 3}}>
							<Paragraph
								fontSize="lg"
								color="grey500"
								style={{ paddingBottom: "8px", fontWeight: "700" }}
							>
								Nothing to show yet
							</Paragraph>
							<Paragraph fontSize="sm" color="grey400" fontWeight={400}>
								Complete a Simulation to add data to your Track page
							</Paragraph>
						</div>
					)}
				</ScoreResultBox>
			</Box>
		</HeaderContainer>
	);
}
