import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import ProfileData from "./ProfileData";
import ProfilePassword from "./ProfilePassword";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser, setAuthUser } from "../../redux/authSliceLogin";
import { axiosInstanceWithCred } from "../../utils/axiosinstance";

const Conatiner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 52px;
`;

const ProfileDataContent = styled.div`
	padding: 52px;
	border-radius: 32px;
	position: relative;
	background: ${(props) => props.theme.colors.neutral.grey00};
`;

export default function Setting() {
	const dispatch = useDispatch();
	const authUser = useSelector(selectAuthUser);
	const [fullname, setFullname] = useState(authUser.full_name);
	const [oldPassword, setOldPassword] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [errorPassword, setErrorPassword] = useState(null);
	const [successPassword, setSuccessPassword] = useState(null);
	const [loading, setLoading] = useState(false);
	const [imageLoading, setImageLoading] = useState(false);
	const [profileLoading, setProfileLoading] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [file, setFile] = useState(null); // Move file to state

	const MAX_FILE_SIZE_MB = 3;
	const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

	useEffect(() => {
		if (error || errorPassword || success || successPassword) {
			const timer = setTimeout(() => {
				setError(null);
				setSuccess(null);
				setErrorPassword(null);
				setSuccessPassword(null);
				setFile(null);
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [error, errorPassword, success, successPassword]);

	const handleUpdateProfile = async (event) => {
		event.preventDefault();

		// Get the selected file from the input event
		const selectedFile = event.target.files ? event.target.files[0] : null;

		// Check if the modal was closed without selecting a file
		if (!selectedFile && fullname === authUser.full_name) {
			return; // Exit the function if no file is selected and the name hasn't changed
		}

		// Create FormData object
		const formData = new FormData();

		// Check if file size exceeds the limit before appending to FormData
		if (selectedFile && selectedFile.size > MAX_FILE_SIZE_BYTES) {
			setError(null);
			setTimeout(() => {
				setError("Error: Please upload an image below 3MB in size");
			}, 100); // Use a small delay (e.g., 100ms)

			setFile(null); // Reset file state
			return;
		}

		setFile(selectedFile); // Set file to state if valid

		// Append the full name if provided and different from current authUser full name
		if (fullname !== authUser.full_name) {
			setProfileLoading(true);
			formData.append("full_name", fullname);
		}

		// Append the file if it exists
		if (selectedFile) {
			setImageLoading(true);
			formData.append("image_path", selectedFile);
		}

		try {
			const response = await axiosInstanceWithCred.put("/profile", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			console.log(response.data);
			setProfileLoading(false);
			setImageLoading(false);
			setSuccess("Profile update successful");
			dispatch(setAuthUser(response.data.user));
		} catch (err) {
			setError("Profile update failed. Please try again.");
			setProfileLoading(false);
			setImageLoading(false);
			console.log("Profile update error:", err);
		}
	};

	const handleDeleteImage = async () => {
		try {
			// Initiate the delete request
			const response = await axiosInstanceWithCred.delete("/profile/image");

			// Log response for debugging
			console.log(response.data);

			// Update the state with the new user data
			if (response.data.user) {
				// Only update the image_path in the state
				setAuthUser((prevState) => ({
					...prevState,
					image_path: null, // Clear the image path
				}));
			}

			// Provide success feedback to the user
			setSuccess(response.data.message || "Image deleted successfully");
		} catch (err) {
			// Provide error feedback to the user
			setError(err.message || "Failed to delete image");
			console.log("Image delete error:", err);
		}
	};

	const updatePassword = async () => {
		setLoading(true);
		try {
			const response = await axiosInstanceWithCred.post("/change_password", {
				old_password: oldPassword,
				new_password: password,
				confirm_password: confirmPassword,
			});
			console.log(response);
			setLoading(false);
			setSuccessPassword("Password update successful");
		} catch (err) {
			setErrorPassword(err);
			setLoading(false);
			console.log("profile update error:", err);
		}
	};

	return (
		<DashboardLayout>
			<Conatiner>
				<ProfileDataContent>
					<ProfileData
						authUser={authUser}
						fullname={fullname}
						error={error}
						imageLoading={imageLoading}
						profileLoading={profileLoading}
						success={success}
						setFullname={setFullname}
						handleUpdateProfile={handleUpdateProfile}
						handleDeleteImage={handleDeleteImage}
					/>
				</ProfileDataContent>
				<ProfileDataContent>
					<ProfilePassword
						updatePassword={updatePassword}
						password={password}
						error={errorPassword}
						loading={loading}
						success={successPassword}
						oldPassword={oldPassword}
						setOldPassword={setOldPassword}
						setPassword={setPassword}
						confirmPassword={confirmPassword}
						setConfirmPassword={setConfirmPassword}
					/>
				</ProfileDataContent>
			</Conatiner>
		</DashboardLayout>
	);
}
