import React, { useState } from "react";
import { H1 } from "../../../components/Headers";
import { Button } from "../../../components/Button";
import InputWithLabel from "../../../components/InputWithLabel";
import styled from "styled-components";
import { AuthLayout } from "../../../components/Layout/AuthLayout";
import { useNavigate } from "react-router-dom";
import { Paragraph } from "../../../components/Paragraph";
import infoIcon from "../../../assets/info-circle.svg";
import eyeIcon from "../../../assets/eye.svg";
import eyeOffIcon from "../../../assets/eye-off.svg";
import {
	clearError,
	handleLoginRedux,
	selectAuthError,
	selectAuthLoading,
	setAuthUser,
} from "../../../redux/authSliceLogin";
import { useDispatch, useSelector } from "react-redux";
import EndpointErrorAlert from "../../../components/EndpointErrorAlert";

const Header1 = styled(H1)`
	text-transform: capitalize;
	line-height: 44px;
`;

const FieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: 32px;
`;

const EmailBox = styled.div`
	position: relative;
`;

const AnchorTag = styled.a`
	color: #667085;
	text-decoration: underline;
`;

const ErrorText = styled.div`
	margin-bottom: 1rem;
`;

const ErrorIcon = styled.img`
	position: absolute;
	right: 10px;
	top: 24px;
`;

const EyeIcon = styled.img`
	position: absolute;
	right: 15px;
	top: 16px;
	cursor: pointer;
	width: 20px;
	height: 20px;
`;

export default function LoginPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const loading = useSelector(selectAuthLoading);
	const loginError = useSelector(selectAuthError);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const isFormComplete = email && password;

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const handleLogin = async (e) => {
		e.preventDefault();
				const payload = {
					email: email,
					password: password,
				};

		try {
			const resultAction = await dispatch(handleLoginRedux(payload));

			if (handleLoginRedux.fulfilled.match(resultAction)) {
				const user = resultAction.payload.user;

				if (user) {
					dispatch(setAuthUser(user)); // Set user data and authentication state
					navigate("/dashboard/tracking");
				} else {
					console.log("Login succeeded but user data is not available");
				}
			} else {
				console.log("Login action was not fulfilled");
			}
		} catch (err) {
			console.error("Login failed:", err); // Use console.error for errors
		}

		// Clear error after a delay
		setTimeout(() => {
			dispatch(clearError());
		}, 3000);
	};

	return (
		<AuthLayout>
			<Header1 fontSize="md" fontWeight={700} color="grey900">
				Log in
			</Header1>

			<FieldWrapper>
				{/* Render error alerts based on the error type */}
				{loginError && (
					<>
						{loginError.message === "Network Error" && (
							<EndpointErrorAlert>
								Network Error!! refresh your browser or contact support.
							</EndpointErrorAlert>
						)}

						{loginError.response && (
							<EndpointErrorAlert>
								{loginError.response?.data?.error || "Unauthorized access."}
							</EndpointErrorAlert>
						)}
					</>
				)}

				<EmailBox>
					<InputWithLabel
						label="Company email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						labelFor="email"
						id="email"
						type="email"
						placeholder="Company email"
						labelColor={loginError?.response ? "default" : ""}
						borderColor={loginError?.response ? "default" : ""}
					/>
					{loginError?.response && (
						<ErrorIcon src={infoIcon} alt="error icon" />
					)}
				</EmailBox>

				<EmailBox>
					<InputWithLabel
						label="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						labelFor="password"
						id="password"
						type={showPassword ? "text" : "password"}
						placeholder="Password"
						labelColor={loginError?.response ? "default" : ""}
						borderColor={loginError?.response ? "default" : ""}
					/>
					{password.length >= 1 && (
						<EyeIcon
							src={showPassword ? eyeOffIcon : eyeIcon}
							alt="eye icon"
							onClick={togglePasswordVisibility}
							style={
								loginError?.response
									? { right: "35px", top: "22px" }
									: { top: "22px" }
							}
						/>
					)}
					{loginError?.response && (
						<ErrorIcon src={infoIcon} alt="error icon" />
					)}
				</EmailBox>

				<ErrorText>
					<Paragraph
						fontWeight={400}
						fontSize="xs"
						color="blue200"
						style={{
							color: "#2D69F6",
							margin: "-0.5rem 0px 0px",
							cursor: "pointer",
						}}
						onClick={() => navigate("/forget-password")}
					>
						Forgot Password?
					</Paragraph>
				</ErrorText>

				<Button
					bg={isFormComplete ? "default" : ""}
					bordercolor={isFormComplete ? "default" : ""}
					hoverbg={isFormComplete ? "green100" : ""}
					color="grey50"
					disabled={!isFormComplete}
					onClick={handleLogin}
				>
					{loading ? "Processing..." : "Login"}
				</Button>

				<Paragraph
					fontSize="xs"
					fontWeight={400}
					style={{ textAlign: "center", marginTop: "1rem" }}
				>
					By signing up, you agree to our{" "}
					<AnchorTag
						href="https://app.termly.io/policy-viewer/policy.html?policyUUID=07345953-622e-45a5-8533-5726177e58d5"
						target="_blank"
					>
						terms of service
					</AnchorTag>{" "}
					and our{" "}
					<AnchorTag
						href="https://app.termly.io/policy-viewer/policy.html?policyUUID=7a915eb8-b0c7-406c-af88-938b4d819064"
						target="_blank"
					>
						privacy policy
					</AnchorTag>
					.
				</Paragraph>
			</FieldWrapper>
		</AuthLayout>
	);
}
