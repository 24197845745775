import { createGlobalStyle } from "styled-components";

export const theme = {
	colors: {
		neutral: {
			default: "#6B7280",
			grey00: "#FFFFFF",
			grey30: "#EAECF0",
			grey50: "#F9FAFB",
			grey100: "#F3F4F6",
			grey200: "#E5E7EB",
			grey300: "#D1D5DB",
			grey400: "#9CA3AF",
			grey600: "#4B5563",
			grey700: "#374151",
			grey800: "#1F2937",
			grey900: "#111827",
		},
		primary: {
			green50: "#D4F4DD",
			green100: "#75BF97",
			green200: "#428165",
			default: "#102D23",
		},
		secondary: {
			green50: "#C4FAC4",
			green100: "#A3F0AA",
			green200: "#87E299",
			default: "#60D081",
		},
		error: {
			red50: "#FFF3F3",
			red100: "#F8D6D5",
			red200: "#F3ADAB",
			default: "#EC615B",
		},
		success: {
			green50: "#E7FEEB",
			green100: "#CFE8D3",
			green200: "#A1D1A6",
			default: "#53A551",
		},
		info: {
			blue50: "#C5D9FC",
			blue100: "#8BB4F9",
			default: "#2D69F6",
		},
		warning: {
			yellow50: "#F0E5C4",
			yellow100: "#F8DE8E",
			default: "#F1BF42",
		},
	},
	gradient: {
		lightGreen: "linear-gradient(180.33deg, rgba(96, 208, 129, 0.07) -21.49%, rgba(255, 255, 255, 0) 51.27%)",
	},
	shadow: {
		xs: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
		md:	 `0px 25px 25px 0px rgba(0, 0, 0, 0.08),
				 0px 56px 34px 0px rgba(0, 0, 0, 0.05),
				 0px 100px 40px 0px rgba(0, 0, 0, 0.03),
				 0px 156px 44px 0px rgba(0, 0, 0, 0.00)`,
		glassy100: "0px 0px 4px 0px #B6CFFF4F inset",
		dropdownShadow: "0px 4px 32px 0px #0000001A",
		navActive: "0px 4px 4px 0px #C4FAC41A inset",
	},
	breakpoints: {
		mobile: "576px",
		// mobile: "768px",
		tablet: "992px",
		laptop: "1200px",
		desktop: "1440px",
	},
	fonts: {
		default: "'Nunito', sans-serif",
		fallback: "'Open Sans', sans-serif",
	},
	headingText: {
		xxl: "4.5rem", // or "72px"
		xl: "3.750rem", // or "60px"
		lg: "3rem", // or "48px"
		md: "2.25rem",
		sm: "1.87rem",
		xs: "1.5rem",
	},
	paragraphText: {
		xl: "1.25rem",
		lg: "1.125rem",
		md: "1rem",
		sm: "0.875rem",
		xs: "0.75rem",
	},
};

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.colors.neutral.grey50};
    font-family: ${(props) => props.theme.fonts.default};
		color: ${(props) => props.theme.colors.neutral.default};
		font-size: ${(props) => props.theme.paragraphText.md}
  }
	
	button {
		font-size: 1rem;
		border-radius: 32px;
		padding: 1rem 2rem;
		cursor: pointer;
		font-family: ${(props) => props.theme.fonts.default};
		background: ${(props) => props.theme.colors.secondary.default};
		color: ${(props) => props.theme.colors.neutral.grey00};
		border: 1px solid ${(props) => props.theme.colors.secondary.default};
		transition: 0.3s ease-in-out;
	}

	h1, h2, h3, h4, h5, h6, p {
		margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
	}
`;
