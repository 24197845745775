export const status = [
	{
		full_name: "Active Persona",
		value: true

	},
	{
		full_name: "Inactive Persona",
		value: false
	},
];