import styled from "styled-components";


export const Paragraph = styled.p`
	color: ${(props) =>
		props.color ? props.theme.colors.neutral[props.color] : "#667085"};
	font-size: ${(props) =>
		props.fontSize ? props.theme.paragraphText[props.fontSize] : "inherit"};
	font-weight: ${(props) => props.fontWeight || 300};
  margin: 0;
	padding: 0;
`;