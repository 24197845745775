import React from "react";
import { H4 } from "../../components/Headers";
import styled, { useTheme } from "styled-components";
import Input from "../../components/Input";
import { Button } from "../../components/Button";
import Alert from "../../components/Alert";

const SettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

const InputContainer = styled.div`
	display: flex;
	gap: 64px;
`;

const ButtonBox = styled.div`
	max-width: 173px;
	margin-left: auto;
`;

export default function ProfilePassword({
	updatePassword,
	password,
	oldPassword,
	error,
	loading,
	success,
	setOldPassword,
	setPassword,
	confirmPassword,
	setConfirmPassword,
}) {
	const theme = useTheme();
	return (
		<SettingsContainer>
			<TextContainer>
				<H4 color="grey700" fontSize="xs" fontWeight={700}>
					Reset password
				</H4>
			</TextContainer>

			{error && (
				<Alert
					color={theme.colors.error.default}
					bgColor={theme.colors.error.red50}
				>
					{error?.response?.data?.error || "An error occurred."}
				</Alert>
			)}

			{success && (
				<Alert
					color={theme.colors.success.default}
					bgColor={theme.colors.success.green50}
				>
					{success}
				</Alert>
			)}

			<InputContainer>
				<Input
					label="Old password"
					value={oldPassword}
					onChange={(e) => setOldPassword(e.target.value)}
					labelFor="old password"
					id="old password"
					type="password"
					placeholder="Enter Old Password"
				/>

				<Input style={{ display: "none" }} />
			</InputContainer>

			<InputContainer>
				<Input
					label="New password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					labelFor="password"
					id="password"
					type="password"
					placeholder="Enter new password"
				/>

				<Input
					label="Confirm new password"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
					labelFor="confirm password"
					id="confirm password"
					type="password"
					placeholder="Re-enter new password"
				/>
			</InputContainer>

			<ButtonBox>
				<Button color="grey00" onClick={updatePassword}>
					{loading ? "Processing..." : "Save changes"}
				</Button>
			</ButtonBox>
		</SettingsContainer>
	);
}
