import axios from "axios";

export const axiosInstanceWithCred = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
	headers: { "Content-Type": "application/json" },
	withCredentials: true,
});

export const axiosInstanceNoCred = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
	headers: { "Content-Type": "application/json" },
	// withCredentials: true,
});

