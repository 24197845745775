import styled, { css } from "styled-components";

const baseStyles = css`
	margin: 0;
	padding: 0;
	font-size: ${(props) =>
		props.fontSize ? props.theme.headingText[props.fontSize] : "inherit"};
	font-family: ${(props) =>
		props.fontFamily ? props.theme.fonts[props.fontFamily] : "inherit"};
	font-weight: ${(props) => props.fontWeight || 300};
	color: ${(props) =>
		props.color ? props.theme.colors.neutral[props.color] : "inherit"};
`;

export const H1 = styled.h1`
	${baseStyles}
`;

export const H2 = styled.h2`
	${baseStyles}
`;

export const H3 = styled.h3`
	${baseStyles}
`;

export const H4 = styled.h4`
	${baseStyles}
`;
