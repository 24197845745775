import React, {useState} from "react";
import styled from "styled-components";
import dropdownIcon from "../../assets/chevron-down.svg";


const DropDownWrapper = styled.div`
	position: relative;
  cursor: pointer;
`;

const DropDownButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
	border-radius: 8px;
	padding: 0.75rem 1rem;
	background: ${(props) => props.theme.colors.neutral.grey00};
	color: ${(props) => props.theme.colors.neutral.grey500};
	border: 1px solid ${(props) => props.show ? props.theme.colors.secondary.default : props.theme.colors.neutral.grey200};
	box-shadow: ${(props) => props.theme.shadow.xs};
	transition: 0.3s ease-in-out;
`;

const Icon = styled.img`
  width: 12px;
  height: 12px;
`;

const DropDown = styled.div`
	width: 100%;
	position: absolute;
	max-height: 250px;
	overflow-y: scroll;
	left: 0px;
	bottom: 55px;
	z-index: 5;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	font-weight: 400;
	background: ${(props) => props.theme.colors.neutral.grey00};
	box-shadow: ${(props) => props.theme.shadow.dropdownShadow};
`;

const SelectButton = styled.button`
  font-size: 0.75rem;
	text-align: left;
	padding-inline: 1rem;
	background: ${(props) => props.theme.colors.neutral.grey00};
	border: 1px solid ${(props) => props.theme.colors.neutral.grey00};
	color: ${(props) => props.theme.colors.neutral.grey900};
`;

const InputDropDown = ({ children, dropdownData, getDropDownValue, show, onToggle }) => {
  const [selection, setSelection] = useState("")
	return (
		<DropDownWrapper onClick={onToggle}>
			<DropDownButton show={show ? 1 : 0}>
				{selection.length !== 0 ? selection : children}
				<Icon
					src={dropdownIcon}
					style={
						show
							? { transform: "rotate(180deg)" }
							: { transform: "rotate(0deg)" }
					}
					alt=""
				/>
			</DropDownButton>

			{show && (
				<DropDown>
					{dropdownData.map((item, index) => {
						return (
							<SelectButton
								onClick={() => {
									setSelection(item.full_name);
									getDropDownValue(item.full_name);
								}}
								key={index}
							>
								{item.full_name}
							</SelectButton>
						);
					})}
				</DropDown>
			)}
		</DropDownWrapper>
	);
};

export default InputDropDown;
