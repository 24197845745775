import React, { useState } from "react";
import Modal from "../../../components/Modal";
import styled from "styled-components";
import { H1 } from "../../../components/Headers";
import { Paragraph } from "../../../components/Paragraph";
import defaultIcon from "../../../assets/default-persona-icon.png";
import InputWithLabel from "../../../components/InputWithLabel";
import TextAreaWithLabel from "../../../components/TextAreaWithLabel";
import { Button } from "../../../components/Button";
import InputDropDown from "../../../components/InputDropDown";
import PersonaUpdateModal from "./PersonaUpdateModal";
import { useDispatch } from "react-redux";
import { fetchPersonas, handleCreatePersona } from "../../../redux/personaSliceCreation";

const InnerContainer = styled.div`
	position: relative;
	padding: 28px;
	gap: 10px;
	border-radius: 24px;
	text-align: center;
	background: linear-gradient(
		180.33deg,
		rgba(96, 208, 129, 0.15) -4.65%,
		rgba(255, 255, 255, 0) 51.27%
	);
`;

const IntroHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;
`;

const Header1 = styled(H1)``;

const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
	margin-bottom: 40px;
`;

const ImageIcon = styled.img`
	width: 70px;
	height: 70px;
	border-radius: 100%;
	object-fit: cover;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
`;

const UploadInput = styled.input.attrs({ type: "file" })`
	display: none; /* Hides the file input */
`;

const ChangeButton = styled.label`
	cursor: pointer;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	background: ${(props) => props.theme.colors.neutral.grey00};
	padding: 16px 32px;
	border-radius: 32px;

	&:hover {
		border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
		background: ${(props) => props.theme.colors.neutral.grey100};
	}
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 16px;
`;

const ButtonConatiner = styled.div`
	display: flex;
	gap: 24px;
	margin-top: 32px;
`;

const CancelButton = styled(Button)`
	width: 100%;
	padding: 16px 32px;
	border-radius: 32px;
	font-size: 16px;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	background: ${(props) => props.theme.colors.neutral.grey00};

	&:hover {
		color: ${(props) => props.theme.colors.neutral.grey600};
		border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
		background: ${(props) => props.theme.colors.neutral.grey50};
	}
`;

const DoneButton = styled(Button)`
	width: 100%;
	padding: 16px 32px;
	border-radius: 32px;
	font-size: 16px;
	color: ${(props) => props.theme.colors.neutral.grey00};
	border: 1px solid ${(props) => props.theme.colors.secondary.default};
	background: ${(props) => props.theme.colors.secondary.default};

	&:hover {
		color: ${(props) => props.theme.colors.neutral.grey00};
		border: 1px solid ${(props) => props.theme.colors.secondary.green200};
		background: ${(props) => props.theme.colors.secondary.green200};
	}
`;

export default function CreatePersona({ companyData, setShowModal, showConfigModal }) {
	const dispatch = useDispatch();
	const [vapiAssistantID, setVapiAssistantID] = useState("");
	const [personaName, setPersonaName] = useState("");
	const [personaCompany, setPersonaCompany] = useState("");
	const [showCompany, setShowCompany] = useState(false);
	const [personaDesignation, setPersonaDesignation] = useState("");
	const [personaDescription, setPersonaDescription] = useState("");
	const [feedbackPrompt, setFeedbackPrompt] = useState("");
	const [isSuccess, setIsSuccess] = useState(false);
	const [step, setStep] = useState(1);
	const [image, setImage] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);
	const [loading, setLoading] = useState(false)

	const handleModalClick = (e) => {
		e.stopPropagation(); // Prevent clicks inside the modal from closing it
	};

	const handleFileChange = (event) => {
		event.preventDefault();
		const fileInput = event.target.files;
		if (fileInput && fileInput.length > 0) {
			const file = fileInput[0];

			console.log("file", file);
			setImage(file); // Store the file in state

			// Create a preview URL and set it in state
			const previewUrl = URL.createObjectURL(file);
			setImagePreview(previewUrl);
		}
	};

	const handlePrevious = (e) => {
		setStep(step - 1);
	};

	const handleNext = (e) => {
		setStep(step + 1);
	};

	function logFormData(formData) {
		for (const [key, value] of formData.entries()) {
			console.log(`${key}: ${value}`);
		}
	}

	const handlePersonaCreation = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const formData = new FormData();
			// Append your data
			formData.append("assistant_id", vapiAssistantID);
			formData.append("designation", personaDesignation);
			formData.append("name", personaName);
			formData.append("description", personaDescription);
			formData.append("persona_prompt", feedbackPrompt);
			formData.append("company_name", personaCompany);
			formData.append("status", false);
			formData.append("image_path", image);

			logFormData(formData); // Debugging

			const response = await dispatch(handleCreatePersona(formData));
			if (handleCreatePersona.fulfilled.match(response)) {
				console.log("Create persona response:", response);
				const persona = response.payload.persona;
				console.log("persona", persona);
				dispatch(fetchPersonas());
				setIsSuccess(true);
			} else {
				console.error("Create persona failed:", response);
			}
		} catch (err) {
			console.error("Creating Persona Err:", err);
		} finally {
			setLoading(false);
		}
	};


	return (
		<>
			{!isSuccess ? (
				<Modal setShowModal={setShowModal} style={{ zIndex: 50 }}>
					<InnerContainer onClick={handleModalClick}>
						<IntroHeader>
							<Header1 fontSize="sm" fontWeight={700} color="grey800">
								{step === 2
									? "Persona Description"
									: step === 3
									? "Feedback Prompt"
									: "Create Persona"}
							</Header1>
							<Paragraph>{step}/3 Steps</Paragraph>
						</IntroHeader>

						<InputContainer>
							{step === 1 && (
								<>
									<HeaderContainer>
										<ImageIcon
											src={image ? imagePreview : defaultIcon}
											alt=""
										/>
										<ChangeButton htmlFor="profile-image-upload">
											Change picture
											<UploadInput
												type="file"
												id="profile-image-upload"
												aria-label="Upload profile picture"
												onChange={handleFileChange}
												accept="image/jpg, image/png, image/gif, image/jpeg"
											/>
										</ChangeButton>
									</HeaderContainer>

									<InputWithLabel
										label="Vapi assistant ID"
										value={vapiAssistantID}
										onChange={(e) => setVapiAssistantID(e.target.value)}
										labelFor="assistant id"
										id="assistant id"
										type="text"
										placeholder="Vapi assistant ID"
										active={vapiAssistantID.length > 0 ? "default" : ""}
									/>

									<InputWithLabel
										label="Persona name"
										value={personaName}
										onChange={(e) => setPersonaName(e.target.value)}
										labelFor="persona name"
										id="persona name"
										type="text"
										placeholder="Persona name"
										active={personaName.length > 0 ? "default" : ""}
									/>

									<InputWithLabel
										label="Persona job title/role"
										value={personaDesignation}
										onChange={(e) => setPersonaDesignation(e.target.value)}
										labelFor="persona job"
										id="persona job"
										type="text"
										placeholder="Persona job title/role"
										active={personaDesignation.length > 0 ? "default" : ""}
									/>

									<InputDropDown
										show={showCompany}
										dropdownData={companyData}
										getDropDownValue={setPersonaCompany}
										onToggle={() => setShowCompany(!showCompany)}
									>
										Company
									</InputDropDown>
								</>
							)}

							{step === 2 && (
								<TextAreaWithLabel
									label="Persona description"
									value={personaDescription}
									onChange={(e) => setPersonaDescription(e.target.value)}
									labelFor="persona description"
									id="persona description"
									type="text"
									rows={4}
									placeholder="Persona description"
									active={personaDescription.length > 0 ? "default" : ""}
								/>
							)}

							{step === 3 && (
								<TextAreaWithLabel
									label="Feedback prompt"
									value={feedbackPrompt}
									onChange={(e) => setFeedbackPrompt(e.target.value)}
									labelFor="feedback prompt"
									id="feedback prompt"
									type="text"
									rows={8}
									placeholder="Feedback prompt"
									active={feedbackPrompt.length > 0 ? "default" : ""}
								/>
							)}
						</InputContainer>
						<ButtonConatiner>
							<CancelButton
								onClick={
									step !== 1 ? handlePrevious : () => setShowModal(false)
								}
							>
								{step !== 1 ? "Back" : "Cancel"}
							</CancelButton>

							<DoneButton
								onClick={step <= 2 ? handleNext : handlePersonaCreation}
							>
								{step <= 2 ? "Next" : `${loading ? "Processing" : "Submit"}`}
							</DoneButton>
						</ButtonConatiner>
					</InnerContainer>
				</Modal>
			) : (
				<PersonaUpdateModal
					setShowModal={setShowModal}
					showConfigModal={showConfigModal}
				/>
			)}
		</>
	);
}
