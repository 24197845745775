import styled, { css } from "styled-components";

const baseStyle = css`
	border: 1px solid
		${(props) =>
			props.bordercolor
				? props.theme.colors.secondary[props.bordercolor]
				: props.theme.colors.neutral.default};
	font-size: ${(props) =>
		props.fontSize ? props.theme.paragraphText[props.fontSize] : "inherit"};
	font-family: ${(props) =>
		props.fontFamily ? props.theme.fonts[props.fontFamily] : "inherit"};
	font-weight: ${(props) => (props.bold ? "bold" : "")};
	background: ${(props) =>
		props.bg
			? props.theme.colors.secondary[props.bg]
			: props.theme.colors.neutral.default};

	color: ${(props) =>
		props.color ? props.theme.colors.neutral[props.color] : "inherit"};

	&:hover {
		background: ${(props) =>
			props.hoverbg
				? props.theme.colors.secondary[props.hoverbg]
				: props.theme.colors.neutral.default};
		color: ${(props) =>
			props.hovercolor
				? props.theme.colors.neutral[props.hovercolor]
				: props.theme.colors.neutral.grey00};
		border: 1px solid
			${(props) =>
				props.hoverbordercolor
					? props.theme.colors.secondary[props.hoverbordercolor]
					: "inherit"};
	}
`;

export const Button = styled.button`
	${baseStyle}
`;
