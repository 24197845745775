import React from 'react';
import styled, { keyframes } from "styled-components";



// Define the shimmer keyframes
const shimmer = keyframes`
  0% {
    background-position: -300% 0;
  }
  100% {
    background-position: 300% 0;
  }
`;


const SkeletonContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;


const SkeletonHeader = styled.div`
	width: 100%;
	height: 15%;
	border-radius: 16px;
	background: linear-gradient(
		90deg,
		#f3f4f6 25%,
		/* grey100 */ #e0e0e0 50%,
		/* darker grey for contrast */ #f3f4f6 75% /* grey100 */
	);
	background-size: 400% 100%; /* Wider gradient transition */
	animation: ${shimmer} 5s infinite;
`;

const SkeletonResultContainer = styled.div`
	height: 30%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 2%;
	animation: ${shimmer} 5s infinite;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		width: 100%;
		flex-direction: column;
		align-items: start;
		gap: 20px;
	}
`;

const SkeletonResult = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 16px;
	background: linear-gradient(90deg, 
    #F3F4F6 25%,  /* grey100 */
    #E0E0E0 50%,  /* darker grey for contrast */
    #F3F4F6 75%   /* grey100 */
  );
  background-size: 400% 100%; /* Wider gradient transition */
  animation: ${shimmer} 5s infinite;
`;


const SkeletonFilter = styled.div`
	width: 100%;
	height: 5%;
	border-radius: 16px;
  margin-top: 4rem;
	background: linear-gradient(90deg, 
    #F3F4F6 25%,  /* grey100 */
    #E0E0E0 50%,  /* darker grey for contrast */
    #F3F4F6 75%   /* grey100 */
  );
  background-size: 400% 100%; /* Wider gradient transition */
  animation: ${shimmer} 5s infinite;
`;


const SkeletonTable = styled.div`
	height: 55%;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const SkeletonTableHeader = styled.div`
	width: 100%;
	height: 10%;
	border-radius: 16px;
	margin-top: 2rem;
	background: linear-gradient(90deg, 
    #F3F4F6 25%,  /* grey100 */
    #E0E0E0 50%,  /* darker grey for contrast */
    #F3F4F6 75%   /* grey100 */
  );
  background-size: 400% 100%; /* Wider gradient transition */
  animation: ${shimmer} 5s infinite;
`;

const SkeletonTableData = styled.div`
	width: 100%;
	height: 5%;
	border-radius: 16px;
	margin-top: 0.5rem;
	background: linear-gradient(90deg, 
    #F3F4F6 25%,  /* grey100 */
    #E0E0E0 50%,  /* darker grey for contrast */
    #F3F4F6 75%   /* grey100 */
  );
  background-size: 400% 100%; /* Wider gradient transition */
  animation: ${shimmer} 5s infinite;
`;



const TrackPageSkeleton = () => {
  return (
		<SkeletonContainer>
			<SkeletonHeader></SkeletonHeader>

			<SkeletonResultContainer>
				<SkeletonResult></SkeletonResult>
				<SkeletonResult></SkeletonResult>
			</SkeletonResultContainer>

			<SkeletonFilter></SkeletonFilter>

			<SkeletonTable>
				<SkeletonTableHeader></SkeletonTableHeader>
				<SkeletonTableData></SkeletonTableData>
				<SkeletonTableData></SkeletonTableData>
				<SkeletonTableData></SkeletonTableData>
				<SkeletonTableData></SkeletonTableData>
				<SkeletonTableData></SkeletonTableData>
			</SkeletonTable>
		</SkeletonContainer>
	);
}

export default TrackPageSkeleton;