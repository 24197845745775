import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { H1 } from "../components/Headers";
import { Paragraph } from "../components/Paragraph";
import { Button } from "../components/Button";
import AuthAccessLayout from "../components/Layout/AuthAccessLayout";

const StyledH1 = styled(H1)`
	margin-bottom: 16px;
	color: ${(props) => props.theme.colors.primary.main};
`;

const StyledParagraph = styled(Paragraph)`
	padding-bottom: 24px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const NotFoundPage = () => {
	return (
		<AuthAccessLayout>
			<StyledH1 fontSize="xl" fontWeight={700}>
				404
			</StyledH1>
			<StyledParagraph fontSize="lg">Page Not Found</StyledParagraph>
			<Link to="/dashboard/tracking">
				<StyledButton
					bg="default"
					bordercolor="default"
					hoverbg="green100"
					hoverbordercolor="green100"
					color="grey50"
				>
					Go Dashboard
				</StyledButton>
			</Link>
		</AuthAccessLayout>
	);
};

export default NotFoundPage;
