import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstanceWithCred } from "../utils/axiosinstance";

const initialState = {
	user: null,
	loading: false,
	isAuthenticated: false,
	error: null,
};

export const handleLoginRedux = createAsyncThunk(
	"authLogin/handleLoginRedux",
	async (payload, { rejectWithValue }) => {
		try {
			const response = await axiosInstanceWithCred.post("/login", payload);
			return response.data;
		} catch (error) {
			console.error("Login error:", error);
			const errorInfo = {
				message: error.message,
				code: error.code,
				response: error.response,
			};
			return rejectWithValue(errorInfo);
		}
	}
);

export const handleLogoutRedux = createAsyncThunk(
	"authLogin/handleLogoutRedux",
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstanceWithCred.post("/logout");
			return response.data;
		} catch (error) {
			console.error("Logout error:", error);
			const errorInfo = {
				message: error.message,
				code: error.code,
				response: error.response,
			};
			return rejectWithValue(errorInfo);
		}
	}
);

const authSlice = createSlice({
	name: "authLogin",
	initialState,
	reducers: {
		clearError(state) {
			state.error = null;
		},
		setAuthUser(state, action) {
			state.user = action.payload; // Assuming action.payload contains user data
			state.isAuthenticated = true; // Set authentication status
		},
		removeAuthUser(state) {
			state.user = null;
			state.isAuthenticated = false;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoginRedux.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(handleLoginRedux.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload.user;
				state.isAuthenticated = true; // Set isAuthenticated to true on successful login
				// console.log("User logged in:", action.payload.user); // Debugging statement
			})
			.addCase(handleLoginRedux.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
				state.isAuthenticated = false; // Set isAuthenticated to false on error
			})
			.addCase(handleLogoutRedux.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(handleLogoutRedux.fulfilled, (state) => {
				state.loading = false;
				state.error = null
				state.user = null;
				state.isAuthenticated = false; // Set isAuthenticated to false on logout
			})
			.addCase(handleLogoutRedux.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const { clearError, setAuthUser, removeAuthUser } = authSlice.actions;

export const selectAuthUser = (state) => state.authLogin.user;
export const selectAuthLoading = (state) => state.authLogin.loading;
export const selectAuthError = (state) => state.authLogin.error;
export const selectIsAuthenticated = (state) => state.authLogin.isAuthenticated; // Add this selector

export default authSlice.reducer;
