import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import AuthAccessLayout from "../../../components/Layout/AuthAccessLayout";
import lockIcon from "../../../assets/passcode-lock.svg";
import infoIcon from "../../../assets/info-circle.svg";
import { Paragraph } from "../../../components/Paragraph";
import { Button } from "../../../components/Button";
import InputWithLabel from "../../../components/InputWithLabel";
import styled from "styled-components";
import { AuthErrorAlertPassword } from "../../../components/Layout/AuthErrorAlert";
import { axiosInstanceWithCred } from "../../../utils/axiosinstance";
import EndpointErrorAlert from "../../../components/EndpointErrorAlert";
import { CheckCircleBroken } from "../../../components/Icons/SvgIcons";


const EmailBox = styled.div`
	position: relative;
	margin-bottom: 1rem;
`;

const MatchError = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 16px;
	margin-bottom: 1rem;
	border-radius: 8px;
	background: ${(props) => props.theme.colors.error.red50};
	border: 1px solid ${(props) => props.theme.colors.error.default};
`;

const ErrorAlertText = styled(Paragraph)`
	color: ${(props) => props.theme.colors.error.default};
`;

const ErrorIcon = styled.img`
	position: absolute;
	right: 10px;
	top: 24px;
`;


export default function PasswordReset() {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get("token");
	console.log(location, token);
	const [password, setPassword] = useState("");
	const [passwordReset, setPasswordReset] = useState("");
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [errors, setErrors] = useState({
		uppercase: false,
		lowercase: false,
		specialChar: false,
		length: false,
		match: false,
	});
	const isFormComplete = password && passwordReset;


	const validatePassword = (password) => {
		setErrors((prevErrors) => ({
			...prevErrors,
			uppercase: !/[A-Z]/.test(password),
			lowercase: !/[a-z]/.test(password),
			specialChar: !/[!@#$%^&*(),.?":{}|<>]/.test(password),
			length: password.length < 8,
			match: password !== "" && password !== passwordReset,
		}));
	};

	const validateConfirmPassword = (passwordReset) => {
		setErrors((prevErrors) => ({
			...prevErrors,
			match: password !== "" && password !== passwordReset,
		}));
	};


	const handlePasswordChange = (e) => {
		const newPassword = e.target.value;
		setPassword(newPassword);
		validatePassword(newPassword);
	};

	const handleConfirmPasswordChange = (e) => {
		const newConfirmPassword = e.target.value;
		setPasswordReset(newConfirmPassword);
		validateConfirmPassword(newConfirmPassword);
	};

	const handleChangePassword = async (e) => {
		e.preventDefault();
		const newErrors = {
			match: password !== passwordReset,
			length: password.length < 8,
			uppercase: !/[A-Z]/.test(password),
			lowercase: !/[a-z]/.test(password),
			specialChar: !/[^A-Za-z0-9]/.test(password),
		};

		setErrors(newErrors);

		const hasError = Object.values(newErrors).some((error) => error);

		try {
				const response = await axiosInstanceWithCred.post(`/reset_password/${token}`,
					{
						password: password,
						confirm_password: passwordReset,
					}
				);
				console.log("response", response);
			if (response.status === 200) {
				setSuccess(true);
			} else {
				setSuccess(false);
				setError(response?.data?.error);
			}
			
		} catch (err) {
			console.error(err)
				setError(err);
			setSuccess(false);
		}

		setTimeout(() => {
			if (!hasError) {
				setErrors({
					uppercase: false,
					lowercase: false,
					specialChar: false,
					length: false,
					match: false,
				});
			}
		}, 3000);
		
	};

	const handleDashboard = (e) => {
		e.preventDefault();
		navigate("/dashboard/tracking");
		setSuccess(false);
	};

	return (
		<AuthAccessLayout>
			{!success ? (
				<img
					src={lockIcon}
					alt="password lock icon"
				/>
			) : (
				<CheckCircleBroken />
			)}

			<div
				style={{
					textAlign: "center",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
				}}
			>
				<Paragraph fontSize="xl" color="grey700" fontWeight={700}>
					{!success ? "Reset your password" : "Password Updated!"}
				</Paragraph>
				<Paragraph
					fontSize="md"
					color="default"
					style={{
						paddingBlock: "01rem",
						maxWidth: "324px",
					}}
				>
					{!success
						? "Enter a new password below to change your password"
						: "Your password has been changed successfully"}
				</Paragraph>
			</div>

			{!success && (
				<div style={{ marginBottom: "1rem" }}>
					{Object.values(errors).some((error) => error) && (
						<MatchError>
							<img src={infoIcon} alt="" />
							<ErrorAlertText fontSize="xs" fontWeight={400}>
								Password doesn’t match
							</ErrorAlertText>
						</MatchError>
					)}
					<EmailBox>
						{error && error?.response.status === 400 && (
							<EndpointErrorAlert>{error}</EndpointErrorAlert>
						)}

						{error &&
							(error?.message === "Network Error" ||
								error?.response.status === 500) && (
								<EndpointErrorAlert>
									Please refresh browser or contact support
								</EndpointErrorAlert>
							)}
						<InputWithLabel
							label="Create password"
							value={password}
							onChange={handlePasswordChange}
							labelFor="password"
							id="password"
							type="password"
							placeholder="Create password"
							labelColor={
								Object.values(errors).some((error) => error) && password
									? "default"
									: ""
							}
							borderColor={
								Object.values(errors).some((error) => error) && password
									? "default"
									: ""
							}
						/>
						{Object.values(errors).some((error) => error) && password && (
							<>
								<ErrorIcon src={infoIcon} alt="error icon" />
								<AuthErrorAlertPassword errors={errors} />
							</>
						)}
					</EmailBox>

					<EmailBox>
						<InputWithLabel
							label="Re-enter password"
							value={passwordReset}
							onChange={handleConfirmPasswordChange}
							labelFor="password-rest"
							id="password-rest"
							type="password"
							placeholder="Re-enter password"
							labelColor={
								Object.values(errors).some((error) => error) && passwordReset
									? "default"
									: ""
							}
							borderColor={
								Object.values(errors).some((error) => error) && passwordReset
									? "default"
									: ""
							}
						/>
						{Object.values(errors).some((error) => error) && passwordReset && (
							<>
								<ErrorIcon src={infoIcon} alt="error icon" />
							</>
						)}
					</EmailBox>
				</div>
			)}

			<Button
				bg="default"
				bordercolor="default"
				hoverbg="green100"
				hoverbordercolor="green100"
				color="grey50"
				disabled={isFormComplete ? false : true}
				style={{ width: "100%", marginTop: "1rem" }}
				onClick={!success ? handleChangePassword : handleDashboard}
			>
				{!success ? "Continue" : "Done"}
			</Button>
		</AuthAccessLayout>
	);
}
