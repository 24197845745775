import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { SimulateIcon, TrackIcon, SettingIcon, EkhoLogo } from "./SideNavIcon";

const SideBarContainer = styled.div`
	width: auto;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 16px;
	gap: 150px;
	z-index: 20;
	background: ${(props) => props.theme.colors.primary.default};

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		display: none;
	}
`;

const SideNavContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 44px;
`;

const SideNavLinks = styled(Link)`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	font-weight: 400;
	padding: 12px;
	border-radius: 12px;
	text-decoration: none !important;
	font-size: ${(props) => props.theme.paragraphText.xs};
	color: ${(props) =>
		props.active
			? `${props.theme.colors.secondary.default}`
			: `${props.theme.colors.neutral.grey400}`};
	box-shadow: ${(props) =>
		props.active
			? `${props.theme.colors.secondary.default}`
			: `${props.theme.colors.neutral.grey400}`};
	background: ${(props) =>
		props.active
			? `${props.theme.colors.secondary.green50}33`
			: `${props.theme.colors.secondary.grey400}`};

	&:active,
	&:hover {
		${
			"" /* background: ${(props) => `${props.theme.colors.secondary.green50}33`}; */
		}
		color: ${(props) =>
			props.active
				? `${props.theme.colors.secondary.default}`
				: `${props.theme.colors.neutral.grey400}`};
	}
`;

export default function SideBar() {
	const theme = useTheme();
	const location = useLocation();
	const { pathname } = location;
	const [activeLink, setActiveLink] = useState(pathname);

	const handleNavClick = (link) => {
		setActiveLink(link);
	};

	const sideNavigation = [
		{
			url: "/dashboard/tracking",
			text: "Track",
			icon: (
				<TrackIcon
					color={
						activeLink === "/dashboard/tracking"
							? theme.colors.secondary.default
							: theme.colors.neutral.grey400
					}
				/>
			),
		},
		{
			url: "/simulate/persona",
			text: "Simulate",
			icon: (
				<SimulateIcon
					color={
						activeLink === "/simulate/persona"
							? theme.colors.secondary.default
							: theme.colors.neutral.grey400
					}
				/>
			),
		},
		{
			url: "/dashboard/settings",
			text: "Settings",
			icon: (
				<SettingIcon
					color={
						activeLink === "/dashboard/settings"
							? theme.colors.secondary.default
							: theme.colors.neutral.grey400
					}
				/>
			),
		},
	];

	return (
		<SideBarContainer>
			<Link
				to="/dashboard/tracking"
				onClick={() => setActiveLink("/dashboard/tracking")}
			>
				<EkhoLogo />
			</Link>

			<SideNavContainer>
				{sideNavigation.map((item, index) => {
					return (
						<SideNavLinks
							key={index}
							to={item.url}
							active={activeLink === item.url}
							onClick={() => handleNavClick(item.url)}
						>
							{item.icon}
							{item.text}
						</SideNavLinks>
					);
				})}
			</SideNavContainer>
		</SideBarContainer>
	);
}
