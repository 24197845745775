import React from "react";
import styled from "styled-components";

const DropDownWrapper = styled.div`
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10;
	background: ${(props) => props.theme.colors.neutral.grey900}e6;
`;

const ModalContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const GradientBackground = styled.div`
	width: 100%;
	max-width: 719px;
	padding: 12px;
	border-radius: 24px;
	background: ${(props) => props.theme.colors.neutral.grey00};
`;


export default function Modal({ setShowModal, children, style }) {
	return (
		<DropDownWrapper onClick={() => setShowModal(false)} style={style}>
			<ModalContainer>
				<GradientBackground>{children}</GradientBackground>
			</ModalContainer>
		</DropDownWrapper>
	);
}