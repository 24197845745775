import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "./theme/theme";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useNavigate,
	useLocation,
} from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";
import LoginPage from "./pages/AuthPages/Login";
import SignupPage from "./pages/AuthPages/Signup";
import ForgetPassword from "./pages/AuthPages/ForgetPassword";
import PasswordReset from "./pages/AuthPages/PasswordReset";
import EmailSent from "./pages/AuthPages/EmailSent";
import VerifiedAccount from "./pages/AuthPages/VerifiedAccount";
import NotFoundPage from "./pages/404page";
import Setting from "./pages/Settings";
import Tracking from "./pages/Tracking";
import PersonaSelection from "./pages/Simulation/PersonaSelection.js";
import InCall from "./pages/Simulation/InCall/index.js";
import { axiosInstanceWithCred } from "./utils/axiosinstance.js";
import { useDispatch, useSelector } from "react-redux";
import {
	clearError,
	removeAuthUser,
	selectIsAuthenticated,
} from "./redux/authSliceLogin.js";
import { persistor } from "./redux/store.js";

function App() {
	const dispatch = useDispatch();
	const location = useLocation()
	const navigate = useNavigate();

 useEffect(() => {
		const interceptor = axiosInstanceWithCred.interceptors.response.use(
			(response) => response,
			(error) => {
				if (error.response && error.response.status === 403) {
					dispatch(removeAuthUser());
					dispatch(clearError());
					persistor.purge();
					window.location.href = "/login";
				}
				return Promise.reject(error);
			}
		);

		return () => {
			axiosInstanceWithCred.interceptors.response.eject(interceptor);
		};
 }, [dispatch]);


	const isAuthenticated = useSelector(selectIsAuthenticated);
	useEffect(() => {
		if (isAuthenticated && location.pathname === "/login") {
			navigate("/dashboard/tracking");
		}
	}, [isAuthenticated, location.pathname, navigate]);

	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<Routes>
				<Route path="/" element={<Navigate to="/login" />} />
				<Route path="*" element={<NotFoundPage />} /> {/* Catch-all route */}
				<Route path="/login" element={<LoginPage />} />
				<Route path="/signup/:token" element={<SignupPage />} />
				<Route path="/forget-password" element={<ForgetPassword />} />
				<Route path="/password-reset" element={<PasswordReset />} />
				<Route path="/email-sent" element={<EmailSent />} />
				<Route path="/signup/verification" element={<VerifiedAccount />} />
				<Route
					path="/dashboard/settings"
					element={
						<ProtectedRoute>
							<Setting />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/dashboard/tracking"
					element={
						<ProtectedRoute>
							<Tracking />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/simulate/persona"
					element={
						<ProtectedRoute>
							<PersonaSelection />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/simulate/in-call"
					element={
						<ProtectedRoute>
							<InCall />
						</ProtectedRoute>
					}
				/>
			</Routes>
		</ThemeProvider>
	);
}

const AppWrapper = () => (
	<Router>
		<App />
	</Router>
);

export default AppWrapper;
