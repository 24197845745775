import React from 'react'
import AuthAccessLayout from '../../../components/Layout/AuthAccessLayout';
import atIcon from "../../../assets/at-sign.svg";
import { Paragraph } from '../../../components/Paragraph';
import {useLocation} from "react-router-dom"

export default function EmailSent() {
	const location = useLocation();
	const {email} = location.state;
	localStorage.setItem("email", email);
  return (
		<AuthAccessLayout>
			<img src={atIcon} alt="at icon" />

			<Paragraph fontSize="xl" color="grey700" fontWeight={700}>
				Check your email
			</Paragraph>
			<div
				style={{
					textAlign: "center",
					display: "flex",
					alignItems: "center",
					justifyContent: "center"
				}}
			>
				<Paragraph
					fontSize="md"
					color="default"
					style={{
						marginTop: "1rem",
						maxWidth: "324px",
						marginInline: "auto",
					}}
				>
					We’ve sent a verification link to <b>{email}</b>.
				</Paragraph>
			</div>
		</AuthAccessLayout>
	);
}
