import React from "react";
import styled, { keyframes, useTheme } from "styled-components";
import Ekhologo from "../../../assets/ekho-white-logo.svg";
import { EarIcon } from "../../../components/Icons/SvgIcons";

const ripple = keyframes`
  from {
    opacity: 1;
    transform: scale3d(0.95, 0.95, 1.2);
  }
  to {
    opacity: 0;
    transform: scale3d(1.8, 1.8, 1.3);
  }
`;

const IndicationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 47.65px;
	margin-top: 100px;
`;

const DailingContainer = styled.div`
	width: 205.35px;
	height: 205.35px;
	position: relative;

	&::after {
		content: "";
		opacity: 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: -1px;
		left: -1px;
		right: 0;
		bottom: 0;
		border: 1px solid ${(props) => props.theme.colors.primary.green200};
		border-radius: 100%;
		animation: ${ripple} 3s infinite cubic-bezier(0.65, 0, 0.34, 1);
		animation-delay: 0.5s;
		z-index: 1;
	}
`;

const InnerContainer = styled.div`
	width: 205.35px;
	height: 205.35px;
	position: relative;

	&::after {
		content: "";
		opacity: 0;
		position: absolute;
		top: 1px;
		left: 1px;
		right: 0;
		bottom: 0;
		border: 1px solid ${(props) => props.theme.colors.primary.green200};
		border-radius: 100%;
		animation: ${ripple} 3s infinite cubic-bezier(0.65, 0, 0.34, 1);
		animation-delay: 1s;
		z-index: 1;
	}

	&::before {
		content: "";
		opacity: 0;
		position: absolute;
		top: 1px;
		left: 1px;
		right: 0;
		bottom: 0;
		border: 1px solid ${(props) => props.theme.colors.primary.green200};
		border-radius: 100%;
		animation: ${ripple} 3s infinite cubic-bezier(0.65, 0, 0.34, 1);
		animation-delay: 1.5s;
		z-index: 1;
	}
`;

const PersonaIcon = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 100%;
	position: absolute;
	object-fit: cover;
	left: 0;
	top: 0;
	z-index: 5;
	border: 1px solid ${(props) => props.theme.colors.primary.green200};
`;

const AIIconConatiner = styled.div`
	padding: 24.27px;
	border-radius: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 5;
	background: ${(props) => props.theme.colors.secondary.default};
`;

const AIIcon = styled.img`
	width: 60.67px;
	height: 53.54px;
	border-radius: 100%;
	padding: 51.34px 47.71px;
	object-fit: cover;
	background: ${(props) => props.theme.colors.secondary.default};
	box-shadow: 0px 5.19px 6.85px 0px #c4fac4 inset,
		0px -7.26px 7.78px 0px #d7ffd7 inset;
`;

const SideIndication = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	border-radius: 32px;
	background: #143729;
	border: 1px solid ${(props) => props.theme.colors.primary.default};
	padding-top: 20px;
	z-index: 2;
`;

const SmallPersonaIcon = styled.img`
	width: 66.67px;
	height: 63.89px;
	border-radius: 100px;
	border: 1.85px solid #347a4f;
	box-shadow: 0px 0px 11.11px 0px #c4fac440;
	object-fit: cover;
	background: ${(props) => props.theme.colors.secondary.default};
`;

const EkhoLogo = styled.img`
	width: 28.57px;
	height: 25.22px;
	padding: 18.78px 17px;
	border-radius: 100px;
	border: 1.85px solid #347a4f;
	box-shadow: 0px 0px 11.11px 0px #c4fac440;
	object-fit: cover;
	background: ${(props) => props.theme.colors.secondary.default};
`;

export default function UserIndication({ detectSpeaker, image_path }) {
	const theme = useTheme()
	return (
		<IndicationContainer>
			<DailingContainer>
				<InnerContainer>
					{detectSpeaker ? (
						<PersonaIcon
							src={`${process.env.REACT_APP_API_URL}/${image_path}`}
							alt="persona icon"
						/>
					) : (
						<AIIconConatiner>
							<AIIcon src={Ekhologo} alt="ekho icon" />
						</AIIconConatiner>
					)}
				</InnerContainer>
			</DailingContainer>
			<SideIndication>
				<EarIcon color={theme.colors.neutral.grey600} />
				{detectSpeaker ? (
					<EkhoLogo src={Ekhologo} alt="ekho icon" />
				) : (
					<SmallPersonaIcon
						src={`${process.env.REACT_APP_API_URL}/${image_path}`}
						alt="persona icon"
					/>
				)}
			</SideIndication>
		</IndicationContainer>
	);
}
