import React, { useEffect, memo } from "react";
import Modal from "../../../components/Modal";
import { Paragraph } from "../../../components/Paragraph";
import { H4 } from "../../../components/Headers";
import { Button } from "../../../components/Button";
import styled, { useTheme } from "styled-components";
import {
	ActivateSwitchIcon,
	DeactivateSwitchIcon,
} from "../../../components/Icons/SvgIcons";

// Styled components
const InnerContainer = styled.div`
	position: relative;
	padding: 28px;
	gap: 10px;
	border-radius: 24px;
	text-align: center;
	background: ${(props) => props.background};
`;

const TextContainer = styled.div`
	max-width: 425px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-inline: auto;
	margin-block: 40px;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 24px;
	margin-top: 32px;
`;

const RetakeButton = styled(Button)`
	width: 100%;
	padding: 16px 32px;
	border-radius: 32px;
	font-size: 16px;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	background: ${(props) => props.theme.colors.neutral.grey00};

	&:hover {
		color: ${(props) => props.theme.colors.neutral.grey600};
		border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
		background: ${(props) => props.theme.colors.neutral.grey50};
	}
`;

const DoneButton = styled(Button)`
	width: 100%;
	padding: 16px 32px;
	border-radius: 32px;
	font-size: 16px;
	color: ${(props) => props.theme.colors.neutral.grey00};
	border: 1px solid
		${(props) =>
			props.active
				? props.theme.colors.secondary.default
				: props.theme.colors.error.default};
	background: ${(props) =>
		props.active
			? props.theme.colors.secondary.default
			: props.theme.colors.error.default};

	&:hover {
		color: ${(props) => props.theme.colors.neutral.grey00};
		border: 1px solid
			${(props) =>
				props.active
					? props.theme.colors.secondary.green200
					: props.theme.colors.error.red200};
		background: ${(props) =>
			props.active
				? props.theme.colors.secondary.green200
				: props.theme.colors.error.red200};
	}
`;

function ActivatePersonaModal({
	setShowModal,
	isActivated,
	handleCancelConfig,
	id,
	handleDeactivate,
	handleActivate,
}) {
	const theme = useTheme();

	useEffect(() => {
		console.log("Current isActivated state:", isActivated);
	}, [isActivated]);

	const handleModalClick = (e) => {
		e.stopPropagation();
	};

		const getBackgroundStyle = () => {
		if (isActivated === true) {
			return `linear-gradient(
				180.33deg,
				rgba(236, 97, 91, 0.08) 23.82%,
				rgba(236, 97, 91, 0) 51.27%
			)`;
		} else if (isActivated === false) {
			return `linear-gradient(
				180.33deg,
				rgba(96, 208, 129, 0.07) -21.49%,
				rgba(255, 255, 255, 0) 51.27%
			)`;
		}
		return 'transparent'; // Default background
	};

	return (
		<Modal setShowModal={setShowModal} style={{ zIndex: 50 }}>
			{isActivated === true && (
				<InnerContainer
				background={getBackgroundStyle()}
					active={isActivated === true}
					onClick={handleModalClick}
				>
					<DeactivateSwitchIcon color={theme.colors.error.default} />
					<TextContainer>
						<H4 fontSize="sm" color="grey700" fontWeight={700}>
							Deactivate Persona
						</H4>
						<Paragraph fontSize="md" color="grey500" fontWeight={400}>
							Are you sure you want to proceed with this action?
						</Paragraph>
					</TextContainer>
					<ButtonContainer>
						<RetakeButton onClick={handleCancelConfig}>No, Cancel</RetakeButton>
						<DoneButton
							active={!isActivated === true}
							onClick={() => handleDeactivate(id)}
						>
							Yes, proceed
						</DoneButton>
					</ButtonContainer>
				</InnerContainer>
			)}
			{isActivated === false && (
				<InnerContainer
				background={getBackgroundStyle()}
					active={isActivated === false}
					onClick={handleModalClick}
				>
					<ActivateSwitchIcon color={theme.colors.secondary.default} />
					<TextContainer>
						<H4 fontSize="sm" color="grey700" fontWeight={700}>
							Activate Persona
						</H4>
						<Paragraph fontSize="md" color="grey500" fontWeight={400}>
							Are you sure you want to proceed with this action?
						</Paragraph>
					</TextContainer>
					<ButtonContainer>
						<RetakeButton onClick={handleCancelConfig}>No, Cancel</RetakeButton>
						<DoneButton
							active={isActivated === false}
							onClick={() => handleActivate(id)}
						>
							Yes, proceed
						</DoneButton>
					</ButtonContainer>
				</InnerContainer>
			)}
		</Modal>
	);
}

export default memo(ActivatePersonaModal);
