import React from "react";
import styled from "styled-components";

const StyledInput = styled.textarea`
	width: 100%;
	font-size: 1rem;
	line-height: 24px;
	padding: 0.75rem 1rem;
	cursor: pointer;
	border: none;
	border-radius: 8px;
	outline: none;
	background: ${(props) => props.theme.colors.neutral.grey00};
	color: ${(props) => props.theme.colors.neutral.grey700};
	font-weight: 400;
	transition: background 0.3s, border-color 0.3s;
	font-family: ${(props) => props.theme.fonts.default};

	&::placeholder {
		color: ${(props) => props.theme.colors.neutral.grey500};
		font-weight: 300;
	}

	&:focus {
		border-color: none;
		background: ${(props) => props.theme.colors.neutral.grey00};
	}

	&:hover {
		border-color: ${(props) => props.theme.colors.neutral.grey500};
	}

	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px
			${(props) => props.theme.colors.neutral.grey00} inset;
		box-shadow: 0 0 0px 1000px ${(props) => props.theme.colors.neutral.grey00}
			inset;
		-webkit-text-fill-color: ${(props) => props.theme.colors.neutral.grey700};
	}

	&:-webkit-autofill::first-line {
		font-family: ${(props) => props.theme.fonts.primary};
		font-size: 1rem;
		color: ${(props) => props.theme.colors.neutral.grey700};
	}
`;

const Label = styled.label`
	position: absolute;
	font-size: 0.75rem;
	margin-left: 1rem;
	margin-top: 0.5rem;
	color: ${(props) =>
		props.color
			? props.theme.colors.error[props.color]
			: props.theme.colors.neutral.grey400};
		background: ${(props) => props.theme.colors.neutral.grey00}
`;

const InputWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	font-family: ${(props) => props.theme.fonts.default};
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	cursor: pointer;
	background: ${(props) => props.theme.colors.neutral.grey00};
	box-shadow: ${(props) => props.theme.shadow.xs};
	border-radius: 8px;
	border: 1px solid
		${(props) =>
			props.bordercolor
				? props.theme.colors.error[props.bordercolor]
				: "inherit"};
	color: ${(props) =>
		props.color ? props.theme.colors.neutral[props.color] : "inherit"};
`;

const TextAreaWithLabel = ({
	label,
	labelColor,
	borderColor,
	labelFor,
	onChange,
	value,
	rows,
	...props
}) => {
	return (
		<InputWrapper bordercolor={borderColor}>
			{value && (
				<Label color={labelColor} htmlFor={labelFor}>
					{label}
				</Label>
			)}

			<StyledInput
				value={value}
				onChange={onChange}
				{...props}
				rows={rows}
				style={!!value ? { paddingTop: "1.75rem" } : { paddingTop: "0.75rem" }}
			/>
		</InputWrapper>
	);
};

export default TextAreaWithLabel;
