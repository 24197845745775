import React from "react";
import styled from "styled-components";
import logo from "../../../assets/ekho-logo.svg";
import backgroundImage from "../../../assets/ekho-bg-eclipse.svg"
import laptopBG from "../../../assets/Macbook-Icon.png";
import { Paragraph } from "../../Paragraph";
import { useLocation, useNavigate } from "react-router-dom";
import { H1 } from "../../Headers";

const AuthContainer = styled.div`
	padding: 15px;
	height: auto;
	width: 100%;
	max-width: 1440px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
	position: relative;
`;

const AuthNav = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		flex-direction: column;
	}
`;

const LogoContainer = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	cursor: pointer;
`;

const FormWrapper = styled.div`
	width: 48%;
	max-width: 703px;
	align-self: start;
	margin-top: 100px;
	margin-bottom: 50px;

	@media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
		margin-inline: auto;
		margin-top: 30px;
		width: 100%;
	}
`;

const Container = styled.div`
	max-width: 527px;
  margin-inline: auto;
`;

const AuthWrapper = styled.div`
  background: ${(props) => props.theme.colors.neutral.grey00};
  border-radius: 36px;
  padding: 12px;
  margin-top: 48px;
`;

const Form = styled.form`
	background: ${(props) => props.theme.gradient.lightGreen};
	padding: 40px;
	border-radius: 24px;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		padding: 20px;
	}
`;

const AdSection = styled.div`
	width: 48%;
	max-width: 703px;
  min-height: 746px;
	position: fixed;
	left: 50%;
	border-radius: 16px;
	align-self: start;
	background: ${(props) => props.theme.colors.primary.default};

	&::before {
		content: "";
		display: block;
		width: 600px;
		height: 350px;
		object-fit: cover;
		background: url(${backgroundImage}) no-repeat center center;
		background-size: cover;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1; // Ensure the pseudo-element is behind the content
		border-radius: inherit; 
	}

	@media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
		display: none;
	}
`;

const Image = styled.img`
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
	overflow: hidden;
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 10;
`;

const Span = styled.span`
	cursor: pointer;
	color: ${(props) => props.theme.colors.secondary.default};
`;

export const AuthLayout = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();

	const handleNavigation = () => {
		if (location.pathname === "/login") {
			navigate("/signup");
		} else {
			navigate("/login");
		}
	};

	

	return (
		<div style={{height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
			<AuthContainer>
				<FormWrapper>
					<Container>
						<AuthNav>
							<LogoContainer onClick={() => navigate("/")}>
								<img src={logo} alt="ekho logo" />{" "}
								<H1 fontSize="sm" fontWeight={700} color="grey900">
									Ekho
								</H1>
							</LogoContainer>
							<Paragraph fontWeight={600}>
								{location.pathname === "/login"
									? ""
									: "Already have an account?"}{" "}
								<Span onClick={handleNavigation}>
									{location.pathname === "/login" ? "" : "Log in."}
								</Span>
							</Paragraph>
						</AuthNav>
						<AuthWrapper>
							<Form>{children}</Form>
						</AuthWrapper>
					</Container>
				</FormWrapper>

				<AdSection>
					<Image src={laptopBG} alt="Laptop background" />
				</AdSection>
			</AuthContainer>
		</div>
	);
};
