import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstanceWithCred } from "../utils/axiosinstance";

const initialState = {
	personas: [], // Changed from null to an array to handle multiple personas
	loading: false,
	error: null,
};

// Async thunk for creating a persona
export const handleCreatePersona = createAsyncThunk(
	"persona/create",
	async (payload, { rejectWithValue }) => {
		try {
			const response = await axiosInstanceWithCred.post(
				"/personas/add_persona",
				payload,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			return response.data;
		} catch (error) {
			console.error("Persona Creation Error:", error);
			return rejectWithValue({
				message: error.message,
				code: error.code,
				response: error.response,
			});
		}
	}
);

// Async thunk for editing a persona
export const handleEditPersona = createAsyncThunk(
	"persona/edit",
	async (payload, { rejectWithValue }) => {
		try {
			const response = await axiosInstanceWithCred.put(
				"/update-persona",
				payload,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			return response.data;
		} catch (error) {
			console.error("Persona Edit Error:", error);
			return rejectWithValue({
				message: error.message,
				code: error.code,
				response: error.response,
			});
		}
	}
);

// Async thunk for fetching all personas
export const fetchPersonas = createAsyncThunk(
	"persona/fetchAll",
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstanceWithCred.get("/personas/");
			return response.data;
		} catch (error) {
			console.error("Fetch Personas Error:", error);
			return rejectWithValue({
				message: error.message,
				code: error.code,
				response: error.response,
			});
		}
	}
);

const personaSlice = createSlice({
	name: "persona",
	initialState,
	reducers: {
		clearError(state) {
			state.error = null;
		},
		addPersona(state, action) {
      console.log("Adding Persona:", action.payload);
			state.personas.push(action.payload); // Add a new persona to the array
		},
		updatePersona(state, action) {
			const { assistant_id, ...updates } = action.payload;
			console.log("action.payload", action.payload);

			// Find the index of the persona to update
			const index = state.personas.findIndex(
				(persona) => persona["assistant id"] === assistant_id
			);

			console.log("index", index);
			if (index !== -1) {
				// Create a new array with the updated persona
				state.personas[index] = { ...state.personas[index], ...updates };
			} else {
				console.error(`Persona with assistant_id ${assistant_id} not found.`);
			}
		},
		setPersonas(state, action) {
			state.personas = action.payload; // Set the entire list of personas
			state.loading = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleCreatePersona.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(handleCreatePersona.fulfilled, (state, action) => {
				state.loading = false;
				console.log("API Response on Create Persona:", action.payload);
				if (action.payload && action.payload.persona) {
					state.personas.push(action.payload.persona); // Add the new persona
				} else {
					console.error("Unexpected response format:", action.payload);
				}
				state.error = null;
			})
			.addCase(handleCreatePersona.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(handleEditPersona.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(handleEditPersona.fulfilled, (state, action) => {
				state.loading = false;
				const index = state.personas.findIndex(
					(persona) => persona["assistant id"] === action.payload.id
				);
				if (index !== -1) {
					state.personas[index] = action.payload; // Update the persona
				}
				state.error = null;
			})
			.addCase(handleEditPersona.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(fetchPersonas.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchPersonas.fulfilled, (state, action) => {
				state.loading = false;
				state.personas = action.payload; // Set the list of personas
				state.error = null;
			})
			.addCase(fetchPersonas.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const { clearError, addPersona, updatePersona, setPersonas } =
	personaSlice.actions;

export const selectPersonas = (state) => state.persona.personas;
export const selectPersonasLoading = (state) => state.persona.loading;
export const selectPersonasError = (state) => state.persona.error;

export default personaSlice.reducer;
