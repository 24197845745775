import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthUser, selectIsAuthenticated } from "../redux/authSliceLogin";

const PrivateRoute = ({ children }) => {
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const user = useSelector(selectAuthUser);
	// const error = useSelector(selectAuthError);

	// Log the current user and authentication state
	// console.log("User from Redux:", user);
	// console.log("Is Authenticated:", isAuthenticated);
	// console.log("Auth Error:", error);

	// If user is not authenticated, redirect to login
	return isAuthenticated && user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
