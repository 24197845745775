import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
	width: 100%;
	font-size: 1rem;
	line-height: 24px;
	padding: 0.75rem 1rem;
	outline: none;
	background: ${(props) =>
		props.disabled
			? props.theme.colors.neutral.grey200
			: props.theme.colors.neutral.grey00};
	box-shadow: ${(props) => props.theme.shadow.xs};
	border-radius: 8px;
	border: 1px solid
		${(props) =>
			props.bordercolor
				? props.theme.colors.error[props.bordercolor]
				: props.theme.colors.neutral.grey200};
	color: ${(props) =>
		props.color
			? props.theme.colors.neutral[props.color]
			: props.theme.colors.neutral.grey400};

	&::placeholder {
		color: ${(props) => props.theme.colors.neutral.grey400};
		font-weight: 300;
	}

	&:focus {
		color: ${(props) => props.theme.colors.neutral.grey700};
		border: 1px solid ${(props) => props.theme.colors.secondary.default};
		background: ${(props) => props.theme.colors.neutral.grey00};
	}

	&:hover {
		border-color: ${(props) => props.theme.colors.neutral.grey500};
	}

	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px
			${(props) => props.theme.colors.neutral.grey00} inset;
		box-shadow: 0 0 0px 1000px ${(props) => props.theme.colors.neutral.grey00}
			inset;
		-webkit-text-fill-color: ${(props) => props.theme.colors.neutral.grey700};
	}

	&:-webkit-autofill::first-line {
		font-family: ${(props) => props.theme.fonts.primary};
		font-size: 1rem;
		color: ${(props) => props.theme.colors.neutral.grey700};
	}
`;

const Label = styled.label`
	color: ${(props) => props.theme.colors.neutral.grey500};
`;

const InputWrapper = styled.div`
	display: flex;
  flex-direction: column;
  gap: 16px;
	width: 100%;
	display: flex;
	font-family: ${(props) => props.theme.fonts.default};
`;

const Input = ({
	label,
	labelColor,
	borderColor,
	labelFor,
	onChange,
	value,
	...props
}) => {
	return (
		<InputWrapper bordercolor={borderColor}>
      <Label color={labelColor} htmlFor={labelFor}>
        {label}
      </Label>

			<StyledInput
				value={value}
				onChange={onChange}
				{...props}
			/>
		</InputWrapper>
	);
};

export default Input;
