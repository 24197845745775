import React from 'react'
import styled, {useTheme} from 'styled-components';
import { H4 } from '../../../components/Headers';
import { Paragraph } from '../../../components/Paragraph';
import { Button } from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { ClockIcon } from '../../../components/Icons/SvgIcons';



const MainContainer = styled.div`
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 30;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(props) => props.theme.colors.neutral.grey900}e6;
`;


const ErrorContainer = styled.div`
	width: 100%;
	max-width: 719px;
	max-height: 581px;
	height: auto;
	margin-block: 2rem;
	padding: 12px;
	border-radius: 36px;
	z-index: 15;
	overflow: hidden;
	background: ${(props) => props.theme.colors.neutral.grey00};
	box-shadow: ${(props) => props.theme.shadow.md};
`;


const InnerContainer = styled.div`
	position: relative;
	padding: 40px;
	border-radius: 24px;
	background: linear-gradient(
		180.33deg,
		rgba(96, 208, 129, 0.25) -21.49%,
		rgba(255, 255, 255, 0) 51.27%
	);
	text-align: center;
`;


const TextContainer = styled.div`
	max-width: 425px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-inline: auto;
	margin-block: 40px;
`;

const ButtonConatiner = styled.div`
	display: flex;
	gap: 24px;
	margin-top: 32px;
`;


const RetakeButton = styled(Button)`
	width: 100%;
	padding: 16px 32px;
	border-radius: 32px;
	font-size: 16px;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	background: ${(props) => props.theme.colors.neutral.grey00};

	&:hover {
		color: ${(props) => props.theme.colors.neutral.grey600};
		border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
		background: ${(props) => props.theme.colors.neutral.grey50};
	}
`;

const DoneButton = styled(Button)`
	width: 100%;
	padding: 16px 32px;
	border-radius: 32px;
	font-size: 16px;
	color: ${(props) => props.theme.colors.neutral.grey00};
	border: 1px solid ${(props) => props.theme.colors.secondary.default};
	background: ${(props) => props.theme.colors.secondary.default};

	&:hover {
		color: ${(props) => props.theme.colors.neutral.grey00};
		border: 1px solid ${(props) => props.theme.colors.secondary.green200};
		background: ${(props) => props.theme.colors.secondary.green200};
	}
`;

export default function TranscriptError({ handleRestartCall, isCallShort }) {
	const navigate = useNavigate();
	const theme = useTheme();
	return (
		<MainContainer>
			<ErrorContainer>
				<InnerContainer>
					<ClockIcon color={theme.colors.secondary.default} />
					<TextContainer>
						<H4 fontSize="sm" color="grey700" fontWeight={700}>
							{isCallShort ? "Call is too short" : "Feedback is on its way!"}
						</H4>
						<Paragraph fontSize="md" color="grey500" fontWeight={400}>
							{isCallShort
								? "Please make sure your call is at least 30s so we can provide meaningful feedback"
								: "Please check your Track page later to view the feedback for your simulation."}
						</Paragraph>
					</TextContainer>

					<ButtonConatiner>
						<RetakeButton onClick={handleRestartCall}>
							Retake Simulation
						</RetakeButton>

						<DoneButton onClick={() => navigate("/dashboard/tracking")}>
							Done
						</DoneButton>
					</ButtonConatiner>
				</InnerContainer>
			</ErrorContainer>
		</MainContainer>
	);
}
