export const normalizeDropdownData = (data) => {
	if (!Array.isArray(data)) {
		return [];
	}

	return data.map((item) => {
		if (typeof item === "string") {
			return { full_name: item };
		} else if (item && typeof item === "object" && item.full_name) {
			return item;
		} else {
			return { full_name: "" }; // Handle unexpected formats
		}
	});
};
