import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { Paragraph } from "../../../components/Paragraph";
import { Button } from "../../../components/Button";
import EmptyTableState from "../emptyTable";
import {
	CancelIcon,
	ChevronDownIcon,
	ChevronLeft,
	ChevronRight,
	FilterLinesIcon,
	FilterLinesUpward,
} from "../../../components/Icons/SvgIcons";
import { DateRangePicker } from "rsuite";
import feedbackIcon from "../../../assets/feedback-icon.svg";
import "rsuite/dist/rsuite.min.css";
import "./date.css";
import "./table.css";
import FiltersDropdown from "../../../components/DropdownFIlter";
import { useSelector } from "react-redux";
import { selectPersonas } from "../../../redux/personaSliceCreation";
// import { repData } from "../trackData";

const TableMainContainer = styled.div`
	margin-top: 76px;
`;

const FilterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;
`;

const FilterBox = styled.div`
	display: flex;
	gap: 16px;
`;

const DateButtonContainer = styled.div`
	width: 100%;
	min-width: 200px;
	border-radius: 100px;
	${"" /* padding: 8px 14px; */}
	border: 1px solid ${(props) =>
		props.active
			? props.theme.colors.secondary.default
			: props.theme.colors.neutral.grey100};
	background: ${(props) =>
		props.disabled
			? props.theme.colors.neutral.grey100
			: props.theme.colors.neutral.grey00};

	&:hover {
		background: ${(props) => props.theme.colors.neutral.grey100};
		border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	}
`;

const Box = styled.div`
	position: relative;
`;

const FilterButton = styled(Button)`
	min-width: 200px;
	border-radius: 100px;
	padding: 8px 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid
		${(props) =>
			props.active
				? props.theme.colors.secondary.default
				: props.theme.colors.neutral.grey100};
	background: ${(props) =>
		props.disabled
			? props.theme.colors.neutral.grey100
			: props.theme.colors.neutral.grey00};

	&:hover {
		background: ${(props) => props.theme.colors.neutral.grey100};
		border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	}
`;

const FixedButton = styled(Button)`
	min-width: 200px;
	border-radius: 100px;
	padding: 8px 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${(props) => props.theme.colors.neutral.default};
`;

const ChevronIcon = styled(ChevronDownIcon)`
	width: 16px;
	height: 16px;
	color: ${(props) =>
		props.disabled
			? props.theme.colors.neutral.grey400
			: props.theme.colors.neutral.grey700};
`;

const TableContainer = styled.div`
	overflow-x: auto;
	margin: 16px 0 0;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	border-radius: 16px;
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const TableHead = styled.thead`
	border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
	&:nth-child(even) {
		${"" /* background: ${(props) => props.theme.colors.neutral.grey50}; */}
	}
	&:hover {
		${"" /* background: ${(props) => props.theme.colors.neutral.grey100}; */}
	}
`;

const THead = styled.th`
	padding: 17.5px 29px;
	text-align: left;
	font-weight: 400;
	font-size: ${(props) => props.theme.paragraphText.md};
	color: ${(props) => props.theme.colors.neutral.grey500};

	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(4) {
		cursor: pointer;
	}
`;

const TData = styled.td`
	padding: 24px 29px;
	text-align: left;
	font-size: ${(props) => props.theme.paragraphText.md};
	background: ${(props) => props.theme.colors.neutral.grey00};
	color: ${(props) => props.theme.colors.neutral.grey600};
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	white-space: nowrap;
	cursor: pointer;

	&:nth-child(1) {
		font-weight: 400;
		color: ${(props) => props.theme.colors.neutral.grey400};
	}
	&:nth-child(2) {
		font-weight: 600;
		color: ${(props) => props.theme.colors.neutral.grey700};
	}
	&:nth-child(3) {
		font-weight: 600;
		color: ${(props) => props.theme.colors.neutral.grey700};
	}
	&:nth-child(4) {
		font-weight: 600;
		color: ${(props) => props.theme.colors.neutral.grey700};
	}
	&:nth-child(5) {
	}
`;

const RepBox = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const PersonaBox = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const ScoreBox = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const Score = styled.div`
	padding: 4px 10px;
	border-radius: 8px;
	border: 1px solid
		${(props) =>
			props.score < 41
				? props.theme.colors.error.red100
				: props.score < 61
				? props.theme.colors.warning.yellow100
				: props.score < 81
				? props.theme.colors.secondary.green100
				: props.theme.colors.primary.green100};
	font-size: ${(props) => props.theme.paragraphText.xs};
	background: ${(props) =>
		props.score < 41
			? props.theme.colors.error.red50
			: props.score < 61
			? "#FFFAEA"
			: props.score < 81
			? "#F5FFF7"
			: props.theme.colors.primary.green50};
	color: ${(props) =>
		props.score < 41
			? props.theme.colors.error.default
			: props.score < 61
			? props.theme.colors.warning.default
			: props.score < 81
			? props.theme.colors.secondary.default
			: props.theme.colors.primary.default};
`;

const Avatar = styled.img`
	width: 32px;
	height: 32px;
	border-radius: 100%;
	object-fit: cover;
`;

const DateRangePickerComponent = styled(DateRangePicker)``;

const NameAvatar = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	border-radius: 100%;
	font-weight: 900;
	background: ${(props) => props.theme.colors.neutral.grey200};
	color: ${(props) => props.theme.colors.neutral.grey400};
`;

const FeedBackIcon = styled.img`
	width: 16px;
	height: 16px;
	padding: 8px 16px;
	border-radius: 100px;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	box-shadow: ${(props) => props.theme.shadow.glassy100};
`;

const PaginationContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 16px;
`;

const PageButton = styled.button`
	border: 1px solid
		${(props) =>
			props.active
				? props.theme.colors.secondary.default
				: props.theme.colors.neutral.grey200};
	background-color: ${(props) =>
		props.active
			? props.theme.colors.secondary.green50
			: props.theme.colors.neutral.grey00};
	color: ${(props) =>
		props.active
			? props.theme.colors.secondary.default
			: props.theme.colors.neutral.default};
	padding: 8px 16px;
	border-radius: 8px;
	cursor: pointer;
	margin: 0 4px;

	&:hover {
		background-color: ${(props) => props.theme.colors.secondary.green50};
		color: ${(props) => props.theme.colors.secondary.default};
	}
`;

const Ellipsis = styled.div`
	padding: 8px 16px;
	color: ${(props) => props.theme.colors.neutral.grey500};
`;

export default function TrackingTableState({
	data,
	salesRepData,
	managerData,
	authUser,
	selectRepFilter,
	selectDateFilter,
	selectManagerFilter,
	setSelectManagerFilter,
	selectPersonaFilter,
	dataWithKeys,
	setDataWithKeys,
	setSelectRepFilter,
	setSelectDateFilter,
	setSelectPersonaFilter,
	handleDisplayFeedback,
}) {
	const theme = useTheme();
	const personaData = useSelector(selectPersonas);
	// console.log("personaData", personaData);

	const [showManagerDropdown, setShowManagerDropdown] = useState(false);
	const [showRepDropdown, setShowRepDropdown] = useState(false);
	const [showPersonaDropdown, setShowPersonaDropdown] = useState(false);
	const [sortOrder, setSortOrder] = useState("asc");
	const [currentPage, setCurrentPage] = useState(1);
	const [sortedColumn, setSortedColumn] = useState("");
	const itemsPerPage = 25;

	useEffect(() => {
		const fetchData = async () => {
			setDataWithKeys(data);
		};

		fetchData();
	}, [data, setDataWithKeys]);

	// console.log(dataWithKeys);

	const handleDisplayManagerDropdown = () => {
		setShowManagerDropdown((showManagerDropdown) => !showManagerDropdown);
		setShowPersonaDropdown(false);
		setShowRepDropdown(false);
	};

	const handleDisplayRepDropdown = () => {
		setShowRepDropdown((showRepDropdown) => !showRepDropdown);
		setShowPersonaDropdown(false);
		setShowManagerDropdown(false);
	};

	const handleDisplayPersonaDropdown = () => {
		setShowPersonaDropdown((showPersonaDropdown) => !showPersonaDropdown);
		setShowRepDropdown(false);
		setShowManagerDropdown(false);
	};

	const handleRepCancel = (e) => {
		setSelectRepFilter("");
		setShowRepDropdown(false);
	};

	const handleManagerCancel = (e) => {
		setSelectManagerFilter("");
		setShowRepDropdown(false);
	};

	const handlePersonaCancel = (e) => {
		setSelectPersonaFilter([]);
		setShowPersonaDropdown(false);
	};

	const handleDateRangeOk = (value) => {
		// Assuming value is an array of two Date objects representing the selected date range
		// console.log("Selected date range:", value);

		// Format the dates as MM/DD/YYYY before updating state
		const formattedDates = value.map((date) => {
			const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
			const day = date.getDate().toString().padStart(2, "0");
			const year = date.getFullYear().toString();
			return `${month}/${day}/${year}`;
		});

		// console.log(formattedDates);

		// Update state to reflect the formatted selected date range
		setSelectDateFilter(formattedDates);
		return formattedDates;
	};

	const formatDateTime = (dateString) => {
		const date = new Date(dateString);

		// Check if the date is valid
		if (isNaN(date.getTime())) {
			console.error("Invalid date string:", dateString);
			return "Invalid date";
		}

		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const day = date.getDate().toString().padStart(2, "0");
		const year = date.getFullYear().toString();
		// const hours = date.getHours().toString().padStart(2, "0");
		// const minutes = date.getMinutes().toString().padStart(2, "0");
		// const seconds = date.getSeconds().toString().padStart(2, "0");

		return `${month}/${day}/${year}`;
	};

	const totalPages = Math.ceil(dataWithKeys.length / itemsPerPage);

	const handleClick = (page) => {
		setCurrentPage(page);
	};

	const handlePrevClick = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const handleNextClick = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	const currentItems = dataWithKeys.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	// console.log("currentItems", currentItems);

	const handleScoreSorting = () => {
		const order =
			sortedColumn === "score" && sortOrder === "asc" ? "desc" : "asc";
		const sortedData = [...dataWithKeys].sort((a, b) => {
			const scoreA = parseInt(a.score, 10);
			const scoreB = parseInt(b.score, 10);
			return order === "asc" ? scoreA - scoreB : scoreB - scoreA;
		});
		setDataWithKeys(sortedData);
		setSortedColumn("score");
		setSortOrder(order);
	};

	const handlePersonaSorting = () => {
		const order =
			sortedColumn === "persona" && sortOrder === "asc" ? "desc" : "asc";
		const sortedData = [...dataWithKeys].sort((a, b) => {
			const personaA = a.persona.full_name.toLowerCase();
			const personaB = b.persona.full_name.toLowerCase();
			if (personaA < personaB) return order === "asc" ? -1 : 1;
			if (personaA > personaB) return order === "asc" ? 1 : -1;
			return 0;
		});
		setDataWithKeys(sortedData);
		setSortedColumn("persona");
		setSortOrder(order);
	};

	const handleManagerSorting = () => {
		const order =
			sortedColumn === "manager" && sortOrder === "asc" ? "desc" : "asc";
		const sortedData = [...dataWithKeys].sort((a, b) => {
			const personaA = a?.user?.manager?.full_name.toLowerCase();
			const personaB = b?.user?.manager?.full_name.toLowerCase();
			if (personaA < personaB) return order === "asc" ? -1 : 1;
			if (personaA > personaB) return order === "asc" ? 1 : -1;
			return 0;
		});
		setDataWithKeys(sortedData);
		setSortedColumn("manager");
		setSortOrder(order);
	};

	const handleRepSorting = () => {
		const order =
			sortedColumn === "rep" && sortOrder === "asc" ? "desc" : "asc";
		const sortedData = [...dataWithKeys].sort((a, b) => {
			const repA = a.user.full_name.toLowerCase();
			const repB = b.user.full_name.toLowerCase();
			if (repA < repB) return order === "asc" ? -1 : 1;
			if (repA > repB) return order === "asc" ? 1 : -1;
			return 0;
		});
		setDataWithKeys(sortedData);
		setSortedColumn("rep");
		setSortOrder(order);
	};


	const convertToNonDecimal = (score) => {
		// Convert score to a number if it's not already
		const numericScore = Number(score.replace("%", ""));

		// Check if the conversion was successful
		if (isNaN(numericScore)) {
			console.error("Invalid score value:", score);
			return "N/A"; // Return a default value or handle the error as needed
		}

		return `${numericScore.toFixed(0)}%`;
	};

	// console.log(salesRepData);
	// console.log(managerData);

	return (
		<TableMainContainer>
			<FilterContainer>
				<Paragraph fontSize="xl" color="grey500" fontWeight={700}>
					Recent Simulations
				</Paragraph>
				<FilterBox>
					<DateButtonContainer>
						<DateRangePickerComponent
							placeholder="Date"
							// disabled={!selectDateFilter}
							character="-"
							format="dd MMM, yyyy"
							value={selectDateFilter}
							onChange={setSelectDateFilter}
							onOk={handleDateRangeOk}
							onClean={() => setSelectDateFilter([])}
						/>
					</DateButtonContainer>

					{authUser.role === "super_admin" && (
						<Box>
							{selectManagerFilter.length === 0 ? (
								<FilterButton
									fontSize="sm"
									disabled={dataWithKeys.length === 0}
									color={dataWithKeys.length === 0 ? "grey400" : "grey700"}
									active={showManagerDropdown}
									hovercolor={dataWithKeys.length === 0 ? "grey400" : "grey700"}
									onClick={handleDisplayManagerDropdown}
								>
									Manager
									<ChevronIcon
										disabled={dataWithKeys.length === 0}
										color={
											showManagerDropdown
												? theme.colors.neutral.grey700
												: theme.colors.neutral.grey400
										}
									/>
								</FilterButton>
							) : (
								<FixedButton
									fontSize="sm"
									color="grey00"
									hovercolor="grey00"
									onClick={handleManagerCancel}
								>
									{selectManagerFilter.length <= 1
										? selectManagerFilter
										: `${selectManagerFilter.length} managers selected`}
									<CancelIcon size="10" color={theme.colors.neutral.grey00} />
								</FixedButton>
							)}

							{showManagerDropdown && (
								<FiltersDropdown
									data={managerData}
									showCheckBox={true}
									showAvatar={true}
									setShowDropdown={setShowManagerDropdown}
									setSelectFilter={setSelectManagerFilter}
								/>
							)}
						</Box>
					)}

					{(authUser.role === "super_admin" || authUser.role === "manager") && (
						<Box>
							{selectRepFilter.length === 0 ? (
								<FilterButton
									fontSize="sm"
									disabled={dataWithKeys.length === 0}
									color={dataWithKeys.length === 0 ? "grey400" : "grey700"}
									active={showRepDropdown}
									hovercolor={dataWithKeys.length === 0 ? "grey400" : "grey700"}
									onClick={handleDisplayRepDropdown}
								>
									Rep
									<ChevronIcon
										disabled={dataWithKeys.length === 0}
										color={
											showRepDropdown
												? theme.colors.neutral.grey700
												: theme.colors.neutral.grey400
										}
									/>
								</FilterButton>
							) : (
								<FixedButton
									fontSize="sm"
									color="grey00"
									hovercolor="grey00"
									onClick={handleRepCancel}
								>
									{selectRepFilter.length <= 1
										? selectRepFilter
										: `${selectRepFilter.length} reps selected`}
									<CancelIcon size="10" color={theme.colors.neutral.grey00} />
								</FixedButton>
							)}

							{showRepDropdown && (
								<FiltersDropdown
									data={salesRepData}
									showCheckBox={true}
									showAvatar={true}
									setShowDropdown={setShowRepDropdown}
									setSelectFilter={setSelectRepFilter}
								/>
							)}
						</Box>
					)}

					<Box>
						{selectPersonaFilter.length === 0 ? (
							<FilterButton
								fontSize="sm"
								disabled={dataWithKeys.length === 0}
								color={dataWithKeys.length === 0 ? "grey400" : "grey700"}
								active={showPersonaDropdown}
								hovercolor={dataWithKeys.length === 0 ? "grey400" : "grey700"}
								onClick={handleDisplayPersonaDropdown}
							>
								Persona
								<ChevronIcon
									disabled={dataWithKeys.length === 0}
									color={
										showPersonaDropdown
											? theme.colors.neutral.grey700
											: theme.colors.neutral.grey400
									}
								/>
							</FilterButton>
						) : (
							<FixedButton
								fontSize="sm"
								color="grey00"
								hovercolor="grey00"
								onClick={handlePersonaCancel}
							>
								{selectPersonaFilter.length <= 1
									? selectPersonaFilter
									: `${selectPersonaFilter.length} personas selected`}
								<CancelIcon size="10" color={theme.colors.neutral.grey00} />
							</FixedButton>
						)}

						{showPersonaDropdown && (
							<FiltersDropdown
								data={personaData}
								showCheckBox={true}
								showAvatar={true}
								setShowDropdown={setShowPersonaDropdown}
								setSelectFilter={setSelectPersonaFilter}
							/>
						)}
					</Box>
				</FilterBox>
			</FilterContainer>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<THead>Date</THead>
							{authUser.role === "super_admin" && (
								<THead onClick={handleManagerSorting}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "8px",
										}}
									>
										Manager{" "}
										{sortOrder === "asc" && sortedColumn === "manager" ? (
											<FilterLinesUpward color={theme.colors.neutral.grey400} />
										) : (
											<FilterLinesIcon color={theme.colors.neutral.grey400} />
										)}
									</div>
								</THead>
							)}

							{(authUser.role === "super_admin" ||
								authUser.role === "manager") && (
								<THead onClick={handleRepSorting}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "8px",
										}}
									>
										Rep{" "}
										{sortOrder === "asc" && sortedColumn === "rep" ? (
											<FilterLinesUpward color={theme.colors.neutral.grey400} />
										) : (
											<FilterLinesIcon color={theme.colors.neutral.grey400} />
										)}
									</div>
								</THead>
							)}

							<THead onClick={handlePersonaSorting}>
								<div
									style={{ display: "flex", alignItems: "center", gap: "8px" }}
								>
									Persona{" "}
									{sortOrder === "asc" && sortedColumn === "persona" ? (
										<FilterLinesUpward color={theme.colors.neutral.grey400} />
									) : (
										<FilterLinesIcon color={theme.colors.neutral.grey400} />
									)}
								</div>
							</THead>
							<THead onClick={handleScoreSorting}>
								<div
									style={{ display: "flex", alignItems: "center", gap: "8px" }}
								>
									Score
									{sortOrder === "asc" && sortedColumn === "score" ? (
										<FilterLinesUpward color={theme.colors.neutral.grey400} />
									) : (
										<FilterLinesIcon color={theme.colors.neutral.grey400} />
									)}
								</div>
							</THead>
							<THead>Feedback</THead>
						</TableRow>
					</TableHead>

					<TableBody>
						{currentItems.map((item, index) => {
							return (
								<TableRow key={index}>
									<TData>{formatDateTime(item.date)}</TData>
									{authUser.role === "super_admin" && (
										<TData style={{ minWidth: "190px" }}>
											<PersonaBox>
												{item?.user?.manager?.image_path ? (
													<Avatar
														src={`${process.env.REACT_APP_API_URL}/${item?.user?.manager?.image_path}`}
														alt=""
													/>
												) : (
													<div>
														<NameAvatar>
															<Paragraph fontWeight={900}>
																{item?.user?.manager?.full_name.slice(0, 1)}
															</Paragraph>
														</NameAvatar>
													</div>
												)}

												<div
													style={{
														display: "flex",
														flexDirection: "column",
														gap: "4px",
													}}
												>
													<Paragraph
														color="grey700"
														fontSize="md"
														fontWeight={600}
													>
														{item?.user?.manager?.full_name}
													</Paragraph>
													<Paragraph
														color="grey400"
														fontSize="xs"
														fontWeight={400}
													>
														{item?.user?.manager?.company_name}
													</Paragraph>
												</div>
											</PersonaBox>
										</TData>
									)}

									{(authUser.role === "super_admin" ||
										authUser.role === "manager") && (
										<TData style={{ minWidth: "190px" }}>
											<RepBox>
												{item.user.image_path ? (
													<Avatar
														src={`${process.env.REACT_APP_API_URL}/${item.user.image_path}`}
														alt=""
													/>
												) : (
													<div>
														<NameAvatar>
															<Paragraph fontWeight={900}>
																{item.user.full_name.slice(0, 1)}
															</Paragraph>
														</NameAvatar>
													</div>
												)}

												{item.user.full_name}
											</RepBox>
										</TData>
									)}

									<TData style={{ minWidth: "190px" }}>
										<PersonaBox>
											{item.persona.image_path ? (
												<Avatar
													src={`${process.env.REACT_APP_API_URL}/${item.persona.image_path}`}
													alt=""
												/>
											) : (
												<div>
													<NameAvatar>
														<Paragraph fontWeight={900}>
															{item.persona.full_name.slice(0, 1)}
														</Paragraph>
													</NameAvatar>
												</div>
											)}

											<div
												style={{
													display: "flex",
													flexDirection: "column",
													gap: "4px",
												}}
											>
												<Paragraph
													color="grey700"
													fontSize="md"
													fontWeight={600}
												>
													{item.persona.full_name}
												</Paragraph>
												<Paragraph
													color="grey400"
													fontSize="xs"
													fontWeight={400}
												>
													{item.persona.designation}
												</Paragraph>
											</div>
										</PersonaBox>
									</TData>
									<TData>
										<ScoreBox>
											<span style={{ width: "50px" }}>{convertToNonDecimal(item.score)}</span>
											<Score score={Number(item.score.replace("%", ""))}>
												{Number(item.score.replace("%", "")) < 41
													? "Low"
													: Number(item.score.replace("%", "")) < 61
													? "Good"
													: Number(item.score.replace("%", "")) < 81
													? "Very Good"
													: "Excellent"}
											</Score>
										</ScoreBox>
									</TData>
									<TData>
										<FeedBackIcon
											src={feedbackIcon}
											alt=""
											onClick={() => handleDisplayFeedback(item.report)}
										/>
									</TData>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{dataWithKeys.length !== 0 && (
				<PaginationContainer>
					<PageButton onClick={handlePrevClick} disabled={currentPage === 1}>
						<ChevronLeft
							color={
								currentPage === 1
									? theme.colors.neutral.grey200
									: theme.colors.neutral.default
							}
						/>
					</PageButton>

					{currentPage > 3 && (
						<>
							<PageButton onClick={() => handleClick(1)}>1</PageButton>
							<Ellipsis>...</Ellipsis>
						</>
					)}

					{Array.from({ length: totalPages }, (_, index) => {
						const page = index + 1;
						if (page < currentPage - 1 || page > currentPage + 1) return null;
						return (
							<PageButton
								key={index}
								active={currentPage === page}
								onClick={() => handleClick(page)}
							>
								{page}
							</PageButton>
						);
					})}

					{currentPage < totalPages - 2 && (
						<>
							<Ellipsis>...</Ellipsis>
							<PageButton onClick={() => handleClick(totalPages)}>
								{totalPages}
							</PageButton>
						</>
					)}

					<PageButton
						onClick={handleNextClick}
						disabled={currentPage === totalPages}
					>
						<ChevronRight
							color={
								currentPage === totalPages
									? theme.colors.neutral.grey200
									: theme.colors.neutral.default
							}
						/>
					</PageButton>
				</PaginationContainer>
			)}

			{dataWithKeys.length === 0 && <EmptyTableState />}
		</TableMainContainer>
	);
}
