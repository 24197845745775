import React from "react";
import styled, { useTheme } from "styled-components";
import { H4 } from "../../components/Headers";
import { Button } from "../../components/Button";
import Input from "../../components/Input";
import Alert from "../../components/Alert";



const SettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

const UserImageContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 40px;
`;

const ImageIcon = styled.img`
	width: 152px;
	height: 152px;
	border-radius: 100%;
	object-fit: cover;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
`;

const NoImageIcon = styled.div`
	width: 152px;
	height: 152px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	font-weight: 700;
	background: ${(props) => props.theme.colors.neutral.grey200};
`;

const DeleteButton = styled(Button)`
	padding: 16px 32px;
	border-radius: 32px;
	border: 1px solid ${(props) => props.theme.colors.error.red100};
	background: ${(props) => props.theme.colors.error.red50};

	&:hover {
		color: ${(props) => props.theme.colors.neutral.grey600};
		border: 1px solid ${(props) => props.theme.colors.error.red100};
		background: ${(props) => props.theme.colors.error.red100};
	}
`;

const InputContainer = styled.div`
	display: flex;
	gap: 64px;
`;

const UploadInput = styled.input.attrs({ type: "file" })`
	display: none; /* Hides the file input */
`;

const ButtonGroup = styled.div`
	display: flex;
	gap: 16px;
`;

const ButtonBox = styled.div`
	max-width: 173px;
	margin-left: auto;
`;

const ChangeButton = styled.label`
	cursor: pointer;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	background: ${(props) => props.theme.colors.neutral.grey00};
	padding: 16px 32px;
	border-radius: 32px;

	&:hover {
		border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
		background: ${(props) => props.theme.colors.neutral.grey100};
	}
`;

const SaveButton = styled(Button)``;

export default function ProfileData({
	authUser,
	fullname,
	error,
	success,
	imageLoading,
  profileLoading,
	setFullname,
	handleUpdateProfile,
	handleDeleteImage,
}) {
	const theme = useTheme();


	return (
		<SettingsContainer>
			<TextContainer>
				<H4 color="grey700" fontSize="xs" fontWeight={700}>
					Profile
				</H4>
			</TextContainer>

			{error && (
				<Alert
					color={theme.colors.error.default}
					bgColor={theme.colors.error.red50}
				>
					{error?.response?.data?.error || error || "An error occurred."}
				</Alert>
			)}

			{success && (
				<Alert
					color={theme.colors.success.default}
					bgColor={theme.colors.success.green50}
				>
					{success}
				</Alert>
			)}

			<UserImageContainer>
				{authUser.image_path ? (
					<ImageIcon
						src={`${process.env.REACT_APP_API_URL}/${authUser.image_path}`}
						alt=""
					/>
				) : (
					<NoImageIcon>{authUser.full_name.slice(0, 1)}</NoImageIcon>
				)}
				<ButtonGroup>
					<ChangeButton htmlFor="profile-image-upload">
						{imageLoading ? "Processing..." : "Change picture"}
						<UploadInput
							type="file"
							id="profile-image-upload"
							aria-label="Upload profile picture"
							onChange={handleUpdateProfile}
							accept="image/jpg, image/png, image/gif, image/jpeg"
						/>
					</ChangeButton>
					<DeleteButton onClick={handleDeleteImage}>
						Delete picture
					</DeleteButton>
				</ButtonGroup>
			</UserImageContainer>

			<InputContainer>
				<Input
					label="Full name"
					value={fullname}
					onChange={(e) => setFullname(e.target.value)}
					labelFor="Full name"
					id="full name"
					type="text"
					placeholder="Enter full name"
				/>

				<Input
					label="Company email"
					value={authUser.email}
					// onChange={(e) => setFullname(e.target.value)}
					labelFor="company email"
					id="company email"
					type="text"
					placeholder="Enter company email"
					disabled={true}
				/>
			</InputContainer>
			<InputContainer>
				<Input
					label="Company name"
					value={authUser.company_name}
					// onChange={(e) => setFullname(e.target.value)}
					labelFor="company name"
					id="company name"
					type="text"
					placeholder="Enter company name"
					disabled={true}
				/>
				<Input label="" style={{ display: "none" }} />
			</InputContainer>

			<ButtonBox>
				<SaveButton color="grey00" onClick={handleUpdateProfile}>
					{profileLoading ? "Processing..." : "Save changes"}
				</SaveButton>
			</ButtonBox>
		</SettingsContainer>
	);
}
