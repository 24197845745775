export const ChevronDownIcon = ({ color }) => {
	return (
		<svg
			width="10"
			height="6"
			viewBox="0 0 10 6"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 0.668945L5 4.66895L9 0.668945"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const FilterLinesIcon = ({ color }) => {
	return (
		<svg
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 8.5H12M2 4.5H14M6 12.5H10"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const FilterLinesUpward = ({ color }) => {
	return (
		<svg
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			transform="rotate(180)"
		>
			<path
				d="M4 8.5H12M2 4.5H14M6 12.5H10"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const DateIcon = ({ color }) => {
	return (
		<svg
			width="15"
			height="16"
			viewBox="0 0 15 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.6665 6.66659H1.6665M10.3332 1.33325V3.99992M4.99984 1.33325V3.99992M4.8665 14.6666H10.4665C11.5866 14.6666 12.1467 14.6666 12.5745 14.4486C12.9508 14.2569 13.2568 13.9509 13.4485 13.5746C13.6665 13.1467 13.6665 12.5867 13.6665 11.4666V5.86659C13.6665 4.74648 13.6665 4.18643 13.4485 3.7586C13.2568 3.38228 12.9508 3.07632 12.5745 2.88457C12.1467 2.66659 11.5866 2.66659 10.4665 2.66659H4.8665C3.7464 2.66659 3.18635 2.66659 2.75852 2.88457C2.3822 3.07632 2.07624 3.38228 1.88449 3.7586C1.6665 4.18643 1.6665 4.74648 1.6665 5.86659V11.4666C1.6665 12.5867 1.6665 13.1467 1.88449 13.5746C2.07624 13.9509 2.3822 14.2569 2.75852 14.4486C3.18635 14.6666 3.7464 14.6666 4.8665 14.6666Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const ChevronRight = ({ color }) => {
	return (
		<svg
			width="7"
			height="12"
			viewBox="0 0 7 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 11L6 6L1 1"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const ChevronLeft = ({ color }) => {
	return (
		<svg
			width="7"
			height="12"
			viewBox="0 0 7 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6 11L1 6L6 1"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const CancelIcon = ({ color, size }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 9 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.66618 1.66663L0.999512 8.33329M0.999512 1.66663L7.66618 8.33329"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const CancelCallIcon = ({ color }) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 17 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.06285 9.71933C2.20374 8.38529 1.56369 6.95111 1.1427 5.46786C1.00919 4.99749 0.942438 4.76231 0.941413 4.41818C0.940277 4.03633 1.07475 3.51925 1.26176 3.18633C1.4303 2.88631 1.65098 2.66563 2.09233 2.22428L2.22369 2.09292C2.66656 1.65005 2.888 1.42861 3.12581 1.30833C3.59878 1.0691 4.15734 1.0691 4.6303 1.30833C4.86812 1.42861 5.08955 1.65005 5.53242 2.09292L5.69481 2.25531C5.98615 2.54665 6.13182 2.69231 6.22706 2.8388C6.58622 3.39121 6.58622 4.10336 6.22706 4.65576C6.13182 4.80225 5.98615 4.94792 5.69482 5.23925C5.59956 5.33451 5.55192 5.38214 5.51206 5.43782C5.37038 5.63568 5.32146 5.92256 5.38957 6.15619C5.40873 6.22193 5.43368 6.27389 5.48357 6.37781C5.58426 6.58753 5.6913 6.79496 5.80469 6.99982M7.81808 9.68171L7.85553 9.71933C8.85685 10.7206 9.98905 11.5113 11.1971 12.0913C11.301 12.1412 11.3529 12.1661 11.4187 12.1853C11.6523 12.2534 11.9392 12.2045 12.137 12.0628C12.1927 12.0229 12.2403 11.9753 12.3356 11.88C12.6269 11.5887 12.7726 11.443 12.9191 11.3478C13.4715 10.9886 14.1837 10.9886 14.7361 11.3478C14.8825 11.443 15.0282 11.5887 15.3196 11.88L15.4819 12.0424C15.9248 12.4853 16.1462 12.7067 16.2665 12.9446C16.5058 13.4175 16.5058 13.9761 16.2665 14.449C16.1462 14.6869 15.9248 14.9083 15.4819 15.3512L15.3506 15.4825C14.9092 15.9239 14.6886 16.1446 14.3885 16.3131C14.0556 16.5001 13.5385 16.6346 13.1567 16.6334C12.8126 16.6324 12.5774 16.5657 12.107 16.4322C9.57917 15.7147 7.19387 14.361 5.20388 12.371L5.16646 12.3334M15.9997 1.5001L0.999676 16.5001"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const ReloadIcon = ({ color }) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 14 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.3334 3.41692C11.7476 4.44727 12.6667 6.11629 12.6667 7.99998C12.6667 11.1296 10.1297 13.6666 7.00004 13.6666H6.66671M3.66671 12.5831C2.25248 11.5527 1.33337 9.88367 1.33337 7.99998C1.33337 4.87037 3.87043 2.33332 7.00004 2.33332H7.33337M7.66671 14.9333L6.33337 13.6L7.66671 12.2666M6.33337 3.73332L7.66671 2.39998L6.33337 1.06665"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const FeedbackIcon = ({ color }) => {
	return (
		<svg
			width="60.48"
			height="60.48"
			viewBox="0 0 57 57"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.8252 26.763C13.6685 25.7536 13.5873 24.7195 13.5873 23.6665C13.5873 12.5323 22.6727 3.50635 33.88 3.50635C45.0874 3.50635 54.1728 12.5323 54.1728 23.6665C54.1728 26.1816 53.7091 28.5892 52.8622 30.8094C52.6863 31.2706 52.5983 31.5011 52.5584 31.6811C52.5188 31.8595 52.5035 31.985 52.4992 32.1676C52.4949 32.3519 52.5199 32.555 52.5699 32.9611L53.5844 41.202C53.6942 42.0941 53.7491 42.5402 53.6007 42.8645C53.4707 43.1486 53.2398 43.3743 52.9527 43.4977C52.6251 43.6386 52.1804 43.5734 51.2911 43.4431L43.2642 42.2665C42.8451 42.205 42.6355 42.1743 42.4447 42.1754C42.2558 42.1764 42.1252 42.1904 41.9404 42.2292C41.7537 42.2685 41.5151 42.3579 41.0378 42.5366C38.8119 43.3704 36.3996 43.8266 33.88 43.8266C32.8262 43.8266 31.7911 43.7468 30.7806 43.5929M17.699 53.9066C25.1706 53.9066 31.2275 47.7013 31.2275 40.0465C31.2275 32.3918 25.1706 26.1865 17.699 26.1865C10.2274 26.1865 4.17049 32.3918 4.17049 40.0465C4.17049 41.5852 4.41522 43.0654 4.86699 44.4483C5.05797 45.0329 5.15345 45.3252 5.18479 45.5249C5.2175 45.7334 5.22324 45.8504 5.21105 46.0611C5.19938 46.2629 5.1489 46.491 5.04792 46.9471L3.50732 53.9066L11.0543 52.8759C11.4662 52.8197 11.6722 52.7915 11.852 52.7928C12.0414 52.794 12.1419 52.8043 12.3276 52.8413C12.504 52.8765 12.7663 52.969 13.2907 53.1541C14.6725 53.6418 16.1555 53.9066 17.699 53.9066Z"
				stroke={color}
				strokeWidth="5.67003"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const FeedbackLightIcon = ({ color }) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9 16.6586V19C9 20.1046 9.89543 21 11 21C12.1046 21 13 20.1046 13 19V16.6586M11 1V2M2 11H1M4.5 4.5L3.8999 3.8999M17.5 4.5L18.1002 3.8999M21 11H20M17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const InfoIcon = ({color}) => {
	return (
		<svg
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.00016 11.3358V8.66911M8.00016 6.00244H8.00683M14.6668 8.66911C14.6668 12.351 11.6821 15.3358 8.00016 15.3358C4.31826 15.3358 1.3335 12.351 1.3335 8.66911C1.3335 4.98721 4.31826 2.00244 8.00016 2.00244C11.6821 2.00244 14.6668 4.98721 14.6668 8.66911Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export const CopyIcon = ({color}) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.8346 6.6665V4.33317C12.8346 3.39975 12.8346 2.93304 12.653 2.57652C12.4932 2.26292 12.2382 2.00795 11.9246 1.84816C11.5681 1.6665 11.1014 1.6665 10.168 1.6665H3.83464C2.90121 1.6665 2.4345 1.6665 2.07798 1.84816C1.76438 2.00795 1.50941 2.26292 1.34962 2.57652C1.16797 2.93304 1.16797 3.39975 1.16797 4.33317V10.6665C1.16797 11.5999 1.16797 12.0666 1.34962 12.4232C1.50941 12.7368 1.76438 12.9917 2.07798 13.1515C2.4345 13.3332 2.90121 13.3332 3.83464 13.3332H6.16797M8.83464 18.3332H15.168C16.1014 18.3332 16.5681 18.3332 16.9246 18.1515C17.2382 17.9917 17.4932 17.7368 17.653 17.4232C17.8346 17.0666 17.8346 16.5999 17.8346 15.6665V9.33317C17.8346 8.39975 17.8346 7.93304 17.653 7.57652C17.4932 7.26292 17.2382 7.00795 16.9246 6.84816C16.5681 6.6665 16.1014 6.6665 15.168 6.6665H8.83464C7.90121 6.6665 7.4345 6.6665 7.07798 6.84816C6.76438 7.00795 6.50941 7.26292 6.34962 7.57652C6.16797 7.93304 6.16797 8.39975 6.16797 9.33317V15.6665C6.16797 16.5999 6.16797 17.0666 6.34962 17.4232C6.50941 17.7368 6.76438 17.9917 7.07798 18.1515C7.4345 18.3332 7.90121 18.3332 8.83464 18.3332Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export const CheckIcon = ({color}) => {
	return (
		<svg
			width="18"
			height="13"
			viewBox="0 0 18 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17 1L6 12L1 7"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export const ClockIcon = ({color}) => {
	return (
		<svg
			width="57"
			height="56"
			viewBox="0 0 57 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M28.4997 12.0002V28.0002L39.1663 33.3335M55.1663 28.0002C55.1663 42.7278 43.2273 54.6668 28.4997 54.6668C13.7721 54.6668 1.83301 42.7278 1.83301 28.0002C1.83301 13.2726 13.7721 1.3335 28.4997 1.3335C43.2273 1.3335 55.1663 13.2726 55.1663 28.0002Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}


export const CheckCircleBroken = ({color}) => {
	return (
		<svg
			width="65"
			height="65"
			viewBox="0 0 65 65"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M59.1668 30.2442V32.6976C59.1636 38.448 57.3015 44.0434 53.8584 48.6491C50.4153 53.2548 45.5756 56.6242 40.0611 58.2546C34.5466 59.8851 28.6529 59.6893 23.2587 57.6964C17.8646 55.7036 13.2592 52.0205 10.1294 47.1964C6.99954 42.3723 5.51294 36.6657 5.8913 30.9277C6.26966 25.1897 8.49271 19.7278 12.2289 15.3564C15.9651 10.9851 21.0143 7.93857 26.6233 6.67128C32.2324 5.40398 38.1009 5.98379 43.3535 8.32422M59.1668 11.349L32.5002 38.0423L24.5002 30.0423"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}


export const MoreIcon = ({color}) => {
	return (
		<svg
			width="10"
			height="2"
			viewBox="0 0 10 2"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5 1.5C5.27614 1.5 5.5 1.27614 5.5 1C5.5 0.723858 5.27614 0.5 5 0.5C4.72386 0.5 4.5 0.723858 4.5 1C4.5 1.27614 4.72386 1.5 5 1.5Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.5 1.5C8.77614 1.5 9 1.27614 9 1C9 0.723858 8.77614 0.5 8.5 0.5C8.22386 0.5 8 0.723858 8 1C8 1.27614 8.22386 1.5 8.5 1.5Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.5 1.5C1.77614 1.5 2 1.27614 2 1C2 0.723858 1.77614 0.5 1.5 0.5C1.22386 0.5 1 0.723858 1 1C1 1.27614 1.22386 1.5 1.5 1.5Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}


export const ActivateSwitchIcon = ({color}) => {
	return (
		<svg
			width="57"
			height="34"
			viewBox="0 0 57 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.83594 17C1.83594 8.16345 8.99938 1 17.8359 1H39.1693C48.0058 1 55.1693 8.16345 55.1693 17C55.1693 25.8366 48.0058 33 39.1693 33H17.8359C8.99938 33 1.83594 25.8366 1.83594 17Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M39.1693 23.6667C42.8512 23.6667 45.8359 20.6819 45.8359 17C45.8359 13.3181 42.8512 10.3333 39.1693 10.3333C35.4874 10.3333 32.5026 13.3181 32.5026 17C32.5026 20.6819 35.4874 23.6667 39.1693 23.6667Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export const DeactivateSwitchIcon = ({ color }) => {
	return (
		<svg
			width="57"
			height="34"
			viewBox="0 0 57 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.83594 17C1.83594 8.16345 8.99938 1 17.8359 1H39.1693C48.0058 1 55.1693 8.16345 55.1693 17C55.1693 25.8366 48.0058 33 39.1693 33H17.8359C8.99938 33 1.83594 25.8366 1.83594 17Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.8359 23.6667C21.5178 23.6667 24.5026 20.6819 24.5026 17C24.5026 13.3181 21.5178 10.3333 17.8359 10.3333C14.154 10.3333 11.1693 13.3181 11.1693 17C11.1693 20.6819 14.154 23.6667 17.8359 23.6667Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const LowScoreIcon = () => {
	return (
		<svg
			width="67"
			height="74"
			viewBox="0 0 67 74"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="34"
				cy="44"
				r="29.25"
				fill="#FFF0E9"
				stroke="#FFE6DA"
				strokeWidth="1.5"
			/>
			<g filter="url(#filter0_ii_2225_6506)">
				<circle cx="34" cy="44" r="24" fill="#FF5833" />
			</g>
			<g filter="url(#filter1_di_2225_6506)">
				<path
					d="M32.1839 32.9373C32.8987 31.3876 35.1013 31.3876 35.8161 32.9373L37.7631 37.1585C38.0545 37.7901 38.6531 38.225 39.3438 38.3069L43.96 38.8542C45.6548 39.0552 46.3354 41.1499 45.0824 42.3087L41.6695 45.4648C41.1589 45.9371 40.9302 46.6407 41.0658 47.323L41.9717 51.8824C42.3044 53.5563 40.5224 54.851 39.0332 54.0174L34.9769 51.7468C34.3699 51.4071 33.6301 51.4071 33.0231 51.7468L28.9668 54.0174C27.4776 54.851 25.6956 53.5563 26.0283 51.8824L26.9342 47.323C27.0698 46.6407 26.8411 45.9371 26.3305 45.4648L22.9176 42.3087C21.6646 41.1499 22.3452 39.0552 24.04 38.8542L28.6562 38.3069C29.3469 38.225 29.9455 37.7901 30.2369 37.1585L32.1839 32.9373Z"
					fill="#FAD4C4"
				/>
			</g>
			<g filter="url(#filter2_f_2225_6506)">
				<circle cx="33.5" cy="33.5" r="9.5" fill="white" />
			</g>
			<defs>
				<filter
					id="filter0_ii_2225_6506"
					x="10"
					y="18.5"
					width="48"
					height="51"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="-1.5" />
					<feGaussianBlur stdDeviation="1.5" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 1 0 0 0 0 0.737255 0 0 0 0 0.623529 0 0 0 1 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_2225_6506"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="1.5" />
					<feGaussianBlur stdDeviation="1.5" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 1 0 0 0 0 0.82459 0 0 0 0 0.749414 0 0 0 1 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_innerShadow_2225_6506"
						result="effect2_innerShadow_2225_6506"
					/>
				</filter>
				<filter
					id="filter1_di_2225_6506"
					x="18.2729"
					y="31.7749"
					width="31.4541"
					height="30.5024"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 1 0 0 0 0 0.3 0 0 0 0 0 0 0 0 0.48 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_2225_6506"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_2225_6506"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="1" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.96 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect2_innerShadow_2225_6506"
					/>
				</filter>
				<filter
					id="filter2_f_2225_6506"
					x="0"
					y="0"
					width="67"
					height="67"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="12"
						result="effect1_foregroundBlur_2225_6506"
					/>
				</filter>
			</defs>
		</svg>
	);
};

export const GoodScoreIcon = () => {
	return (
		<svg
			width="67"
			height="74"
			viewBox="0 0 67 74"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="34"
				cy="44"
				r="29.25"
				fill="#FFFDE9"
				stroke="#FFF6DB"
				strokeWidth="1.5"
			/>
			<g filter="url(#filter0_ii_2225_6565)">
				<circle cx="34" cy="44" r="24" fill="#FFAD33" />
			</g>
			<g filter="url(#filter1_di_2225_6565)">
				<path
					d="M32.1839 32.9373C32.8987 31.3876 35.1013 31.3876 35.8161 32.9373L37.7631 37.1585C38.0545 37.7901 38.6531 38.225 39.3438 38.3069L43.96 38.8542C45.6548 39.0552 46.3354 41.1499 45.0824 42.3087L41.6695 45.4648C41.1589 45.9371 40.9302 46.6407 41.0658 47.323L41.9717 51.8824C42.3044 53.5563 40.5224 54.851 39.0332 54.0174L34.9769 51.7468C34.3699 51.4071 33.6301 51.4071 33.0231 51.7468L28.9668 54.0174C27.4776 54.851 25.6956 53.5563 26.0283 51.8824L26.9342 47.323C27.0698 46.6407 26.8411 45.9371 26.3305 45.4648L22.9176 42.3087C21.6646 41.1499 22.3452 39.0552 24.04 38.8542L28.6562 38.3069C29.3469 38.225 29.9455 37.7901 30.2369 37.1585L32.1839 32.9373Z"
					fill="#FAF5C4"
				/>
			</g>
			<g filter="url(#filter2_f_2225_6565)">
				<circle cx="33.5" cy="33.5" r="9.5" fill="white" />
			</g>
			<defs>
				<filter
					id="filter0_ii_2225_6565"
					x="10"
					y="18.5"
					width="48"
					height="51"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="-1.5" />
					<feGaussianBlur stdDeviation="1.5" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 1 0 0 0 0 0.894118 0 0 0 0 0.623529 0 0 0 1 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_2225_6565"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="1.5" />
					<feGaussianBlur stdDeviation="1.5" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 1 0 0 0 0 0.894986 0 0 0 0 0.624951 0 0 0 1 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_innerShadow_2225_6565"
						result="effect2_innerShadow_2225_6565"
					/>
				</filter>
				<filter
					id="filter1_di_2225_6565"
					x="18.2734"
					y="31.7749"
					width="31.4531"
					height="30.5024"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 1 0 0 0 0 0.84 0 0 0 0 0 0 0 0 0.48 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_2225_6565"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_2225_6565"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="1" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect2_innerShadow_2225_6565"
					/>
				</filter>
				<filter
					id="filter2_f_2225_6565"
					x="0"
					y="0"
					width="67"
					height="67"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="12"
						result="effect1_foregroundBlur_2225_6565"
					/>
				</filter>
			</defs>
		</svg>
	);
};

export const VeryGoodScoreIcon = () => {
	return (
		<svg
			width="67"
			height="74"
			viewBox="0 0 67 74"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="33.9626"
				cy="44.0374"
				r="29.2136"
				fill="#E9FFF0"
				stroke="#C4FAC4"
				strokeWidth="1.49813"
			/>
			<g filter="url(#filter0_ii_2229_7661)">
				<circle cx="33.9613" cy="44.0375" r="23.9701" fill="#60D081" />
			</g>
			<g filter="url(#filter1_di_2229_7661)">
				<path
					d="M32.1479 33.9874C32.8619 32.4395 35.0617 32.4395 35.7756 33.9874L37.7203 38.2033C38.0112 38.8341 38.6091 39.2684 39.2989 39.3502L43.9094 39.8969C45.6021 40.0976 46.2819 42.1897 45.0304 43.347L41.6218 46.4993C41.1117 46.9709 40.8834 47.6737 41.0188 48.3551L41.9236 52.9088C42.2558 54.5807 40.4761 55.8737 38.9887 55.0412L34.9375 52.7734C34.3313 52.4341 33.5923 52.4341 32.9861 52.7734L28.9349 55.0412C27.4475 55.8737 25.6678 54.5807 26 52.9088L26.9048 48.3551C27.0402 47.6737 26.8118 46.9709 26.3018 46.4993L22.8932 43.347C21.6417 42.1897 22.3215 40.0976 24.0142 39.8969L28.6247 39.3502C29.3145 39.2684 29.9124 38.8341 30.2033 38.2033L32.1479 33.9874Z"
					fill="#A3F0AA"
				/>
			</g>
			<g filter="url(#filter2_f_2229_7661)">
				<circle cx="33.4628" cy="33.5507" r="9.48817" fill="white" />
			</g>
			<defs>
				<filter
					id="filter0_ii_2229_7661"
					x="9.99121"
					y="18.5693"
					width="47.9404"
					height="50.9365"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="-1.49813" />
					<feGaussianBlur stdDeviation="1.49813" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.6 0 0 0 0 0.847059 0 0 0 0 0.596078 0 0 0 1 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_2229_7661"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="1.49813" />
					<feGaussianBlur stdDeviation="1.49813" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.601098 0 0 0 0 0.847059 0 0 0 0 0.596078 0 0 0 1 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_innerShadow_2229_7661"
						result="effect2_innerShadow_2229_7661"
					/>
				</filter>
				<filter
					id="filter1_di_2229_7661"
					x="18.254"
					y="32.8264"
					width="31.4148"
					height="30.4644"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="3.99502" />
					<feGaussianBlur stdDeviation="1.99751" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_2229_7661"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_2229_7661"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="1.99751" />
					<feGaussianBlur stdDeviation="0.998755" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect2_innerShadow_2229_7661"
					/>
				</filter>
				<filter
					id="filter2_f_2229_7661"
					x="0.0044899"
					y="0.0923805"
					width="66.9168"
					height="66.9166"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="11.9851"
						result="effect1_foregroundBlur_2229_7661"
					/>
				</filter>
			</defs>
		</svg>
	);
};

export const ExcellentScoreIcon = () => {
	return (
		<svg
			width="67"
			height="74"
			viewBox="0 0 67 74"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="33.9626"
				cy="44.0374"
				r="29.2136"
				fill="#E9FFF0"
				stroke="#D4F4DD"
				strokeWidth="1.49813"
			/>
			<g filter="url(#filter0_ii_2229_7667)">
				<circle cx="33.9633" cy="44.0373" r="23.9701" fill="#102D23" />
			</g>
			<g filter="url(#filter1_di_2229_7667)">
				<path
					d="M32.1499 33.9869C32.8638 32.4391 35.0637 32.4391 35.7776 33.9869L37.7222 38.2028C38.0132 38.8336 38.611 39.268 39.3009 39.3497L43.9114 39.8964C45.604 40.0971 46.2838 42.1893 45.0324 43.3465L41.6237 46.4988C41.1137 46.9704 40.8853 47.6732 41.0207 48.3546L41.9256 52.9084C42.2578 54.5802 40.4781 55.8732 38.9907 55.0407L34.9394 52.7729C34.3332 52.4336 33.5943 52.4336 32.9881 52.7729L28.9368 55.0407C27.4494 55.8732 25.6697 54.5802 26.0019 52.9084L26.9068 48.3546C27.0421 47.6732 26.8138 46.9704 26.3038 46.4988L22.8951 43.3465C21.6437 42.1893 22.3235 40.0971 24.0161 39.8964L28.6266 39.3497C29.3165 39.268 29.9143 38.8336 30.2053 38.2028L32.1499 33.9869Z"
					fill="#D4F4DD"
				/>
			</g>
			<g filter="url(#filter2_f_2229_7667)">
				<circle cx="33.4647" cy="33.5504" r="9.48817" fill="white" />
			</g>
			<defs>
				<filter
					id="filter0_ii_2229_7667"
					x="9.99316"
					y="18.569"
					width="47.9404"
					height="50.9365"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="-1.49813" />
					<feGaussianBlur stdDeviation="1.49813" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.203922 0 0 0 0 0.478431 0 0 0 0 0.309804 0 0 0 1 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_2229_7667"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="1.49813" />
					<feGaussianBlur stdDeviation="1.49813" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.203922 0 0 0 0 0.478431 0 0 0 0 0.309804 0 0 0 1 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_innerShadow_2229_7667"
						result="effect2_innerShadow_2229_7667"
					/>
				</filter>
				<filter
					id="filter1_di_2229_7667"
					x="18.256"
					y="32.8259"
					width="31.4148"
					height="30.4644"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="3.99502" />
					<feGaussianBlur stdDeviation="1.99751" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_2229_7667"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_2229_7667"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="1.99751" />
					<feGaussianBlur stdDeviation="0.998755" />
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect2_innerShadow_2229_7667"
					/>
				</filter>
				<filter
					id="filter2_f_2229_7667"
					x="0.00644302"
					y="0.0921364"
					width="66.9168"
					height="66.9166"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="11.9851"
						result="effect1_foregroundBlur_2229_7667"
					/>
				</filter>
			</defs>
		</svg>
	);
};


export const CallPanelIcon = () => {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{cursor: "pointer"}}
		>
			<path
				d="M11 15V11M11 7H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
				stroke="#428165"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}


export const EarIcon = ({color}) => {
	return (
		<svg
			width="23"
			height="23"
			viewBox="0 0 23 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.85131 16.6676C5.85131 18.7141 6.91529 20.3716 8.8423 20.3716C10.7693 20.3716 12.3333 18.9826 12.8315 16.6676C13.0714 15.5574 13.5612 14.8869 14.328 14.3526C16.3226 12.9636 17.8181 10.8264 17.8181 8.33359C17.8181 7.48236 17.6504 6.63946 17.3246 5.85303C16.9989 5.06659 16.5214 4.35202 15.9195 3.7501C15.3176 3.14819 14.603 2.67073 13.8166 2.34498C13.0302 2.01923 12.1873 1.85156 11.336 1.85156C10.4848 1.85156 9.6419 2.01923 8.85547 2.34498C8.06903 2.67073 7.35446 3.14819 6.75255 3.7501C6.15063 4.35202 5.67317 5.06659 5.34742 5.85303C5.02167 6.63946 4.854 7.48236 4.854 8.33359"
				stroke={color}
				strokeWidth="1.38901"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.55811 13.8897C9.84062 13.8897 10.8805 12.9572 10.8805 11.8062C10.8805 10.6552 9.84062 9.72268 8.55811 9.72268C8.55811 7.85493 8.98777 5.55566 11.3454 5.55566C13.0029 5.55566 13.9752 6.82799 14.1141 8.47258"
				stroke={color}
				strokeWidth="1.38901"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
