import React from 'react'
import styled from 'styled-components';
import { Paragraph } from '../Paragraph';
import infoIcon from "../../assets/info-circle.svg";


const ErrorAlert = styled.div`
	display: flex;
	align-items: center;
	padding: 16px;
	border-radius: 8px;
	margin-bottom: 1rem;
	background: ${(props) => props.theme.colors.error.red50};
	border: 1px solid ${(props) => props.theme.colors.error.default};
	transition: 1s ease-in-out;
`;

const ErrorIconAlert = styled.img`
	margin-right: 10px;
`;

export default function EndpointErrorAlert({ children }) {
	return (
		<ErrorAlert>
			<ErrorIconAlert src={infoIcon} alt="error icon" />
			<Paragraph fontSize="xs" fontWeight={400}>
				{children}
			</Paragraph>
		</ErrorAlert>
	);
}
