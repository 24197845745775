import styled from "styled-components"
import { Paragraph } from '../Paragraph';
import checkIcon from "../../assets/check-circle.svg"
import xIcon from "../../assets/x-circle.svg";

const ErrorContainer = styled.div`
	position: absolute;
	top: 0px;
	right: -190px;
	padding: 0.5rem;
	z-index: 10;
	background: ${(props) => props.theme.colors.neutral.grey00};
	box-shadow: ${(props) => props.theme.shadow.md};
	border-radius: 8px;
	padding: 16px;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		right: 0px;
		top: 50px;
	}
`;

const ErrorPasswordContainer = styled.div`
	position: absolute;
	top: 0px;
	right: -230px;
	padding: 0.5rem;
	z-index: 15;
	background: ${(props) => props.theme.colors.neutral.grey00};
	box-shadow: ${(props) => props.theme.shadow.md};
	border-radius: 8px;
	padding: 16px;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		right: 0px;
		top: 50px;
	}
`;
const PasswordErrorList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  margin-top: 0.7rem;
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;



export const AuthErrorAlert = ({message}) => {
  return (
		<ErrorContainer>
			<Paragraph fontSize="xs">{message}</Paragraph>
		</ErrorContainer>
	);
}

export const AuthErrorAlertPassword = ({
	errors = {
		uppercase: false,
		lowercase: false,
		specialChar: false,
		length: false,
		match: false
	},
}) => {
	return (
		<ErrorPasswordContainer>
			<Paragraph fontSize="xs" fontWeight={700}>
				Your Password must contain:
			</Paragraph>
			<PasswordErrorList>
				<Container>
					<img src={errors.uppercase ? xIcon : checkIcon} alt="icon" />
					<Paragraph fontSize="xs">an uppercase character</Paragraph>
				</Container>
				<Container>
					<img src={errors.lowercase ? xIcon : checkIcon} alt="icon" />
					<Paragraph fontSize="xs">a lowercase character</Paragraph>
				</Container>
				<Container>
					<img src={errors.specialChar ? xIcon : checkIcon} alt="icon" />
					<Paragraph fontSize="xs">a special character</Paragraph>
				</Container>
				<Container>
					<img src={errors.length ? xIcon : checkIcon} alt="icon" />
					<Paragraph fontSize="xs">should be least 8 characters</Paragraph>
				</Container>
				<Container>
					<img src={errors.match ? xIcon : checkIcon} alt="icon" />
					<Paragraph fontSize="xs">password does not match</Paragraph>
				</Container>
			</PasswordErrorList>
		</ErrorPasswordContainer>
	);
};
