import React, { useState } from "react";
import styled from "styled-components";
import NavBar from "../../NavBar";
import SideBar from "../../SideBar";
import { Paragraph } from "../../Paragraph";
import { Button } from "../../Button";
import {
	handleLogoutRedux,
	selectAuthUser,
} from "../../../redux/authSliceLogin";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "../../Modal";
import InviteMember from "../../InviteMember";

const DashboardContainer = styled.div``;

const AsideContainer = styled.div`
	margin-left: 4.65rem;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		margin-left: 0;
	}
`;

const ContentContainer = styled.div`
	position: relative;
	padding: 37px 50px 110px 70px;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		padding: 37px 30px;
	}
`;

const DropDownWrapper = styled.div`
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10;
	background: ${(props) => props.theme.colors.neutral.grey900}e6;
`;

const NavDropdown = styled.div`
	display: flex;
	flex-direction: column;
	padding: 8px;
	border-radius: 16px;
	position: absolute;
	right: 50px;
	top: 80px;
	background: ${(props) => props.theme.colors.neutral.grey00};
	border: 1px solid ${(props) => props.theme.colors.neutral.grey30};

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		right: 30px;
	}
`;

const UserInfo = styled.div`
	padding: 8px 16px;
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
`;

const DropdownButtons = styled(Button)`
	border-radius: 8px;
	padding: 8px 16px;
	border: none;
	text-align: left;
	font-weight: 600;
	background: ${(props) => props.theme.colors.neutral.grey00};
	color: ${(props) => props.theme.colors.neutral.grey500};

	&:hover {
		background: ${(props) => props.theme.colors.neutral.grey100};
	}

	&:focus {
		background: ${(props) => props.theme.colors.neutral.grey100};
		color: ${(props) => props.theme.colors.neutral.grey700};
	}
`;

const UserButton = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.6rem;
	margin-block: 0.6rem;
	padding-block: 0.6rem;
	border-top: 1px solid ${(props) => props.theme.colors.neutral.grey30};
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral.grey30};
`;

const InviteButton = styled(Button)`
	display: none;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		display: block;
		border-radius: 8px;
		padding: 8px 16px;
		border: none;
		text-align: left;
		font-weight: 600;
		background: ${(props) => props.theme.colors.neutral.grey00};
		color: ${(props) => props.theme.colors.neutral.grey500};

		&:hover {
			background: ${(props) => props.theme.colors.neutral.grey100};
		}

		&:focus {
			background: ${(props) => props.theme.colors.neutral.grey100};
			color: ${(props) => props.theme.colors.neutral.grey700};
		}
	}
`;

export default function DashboardLayout({ children }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const authUser = useSelector(selectAuthUser);

	const [showDropDown, setShowDropdown] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const handleShowModal = () => {
		setShowModal(!showModal);
		setShowDropdown(false);
	};

	const handleLogout = async () => {
		try {
			await dispatch(handleLogoutRedux());
			navigate("/login");
		} catch (error) {
			console.error("Logout error:", error);
		}
	};

	const handleDropDownWrapperClick = (e) => {
		e.stopPropagation(); // Prevent clicks inside DropDownWrapper from closing it
	};

	return (
		<DashboardContainer>
			<SideBar />
			<AsideContainer>
				<NavBar
					setShowDropdown={setShowDropdown}
					setShowModal={setShowModal}
					authUser={authUser}
				/>
				<ContentContainer>
					{showDropDown && (
						<DropDownWrapper onClick={() => setShowDropdown(false)}>
							<NavDropdown onClick={handleDropDownWrapperClick}>
								<UserInfo>
									<Paragraph fontSize="sm" color="grey700" fontWeight={700}>
										{authUser.full_name}
									</Paragraph>
									<Paragraph fontSize="sm" color="grey500" fontWeight={400}>
										{authUser.email}
									</Paragraph>
								</UserInfo>
								<UserButton>
									<DropdownButtons
										hovercolor="grey500"
										onClick={() => navigate("/dashboard/settings")}
									>
										Settings
									</DropdownButtons>
									{(authUser.role === "super_admin" ||
										authUser.role === "manager") && (
										<InviteButton
											hovercolor="grey500"
											onClick={handleShowModal}
										>
											Invite member
										</InviteButton>
									)}
								</UserButton>
								<DropdownButtons hovercolor="grey500" onClick={handleLogout}>
									Log out
								</DropdownButtons>
							</NavDropdown>
						</DropDownWrapper>
					)}
					{showModal && (
							<Modal setShowModal={setShowModal}>
								<InviteMember
									setShowModal={setShowModal}
								/>
							</Modal>
					)}
					{children}
				</ContentContainer>
			</AsideContainer>
		</DashboardContainer>
	);
}