import React, { useState, useEffect } from "react";
import AuthPasswordLayout from "../../../components/Layout/AuthAccessLayout";
import lockIcon from "../../../assets/passcode-lock.svg";
import { Paragraph } from "../../../components/Paragraph";
import { Button } from "../../../components/Button";
import InputWithLabel from "../../../components/InputWithLabel";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EndpointErrorAlert from "../../../components/EndpointErrorAlert";


const EmailBox = styled.div`
	position: relative;
`;
export default function ForgetPassword() {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [error, setError] = useState(null);
	const isFormComplete = email;

	const handlePasswrdReset = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/forgot_password`,
				{
					email: email,
				}
			);
			console.log("response", response);
			if (response.status === 200) {
				navigate("/email-sent", {
					state: {
						email: email,
					},
				});
			} else {
				setError(response?.data?.error);
			}
		} catch (err) {
			console.error(err);
			setError(err);
		}
	};

	useEffect(() => {
		console.log(error);
		if (error) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error]);

	return (
		<AuthPasswordLayout>
			<img src={lockIcon} alt="lock icon" />

			<div
				style={{
					textAlign: "center",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
				}}
			>
				<Paragraph fontSize="xl" color="grey700" fontWeight={700}>
					Reset your password
				</Paragraph>
				<Paragraph
					fontSize="md"
					color="default"
					style={{ paddingBlock: "01rem", maxWidth: "324px" }}
				>
					Enter your email address and we will send you instructions to reset
					your password.
				</Paragraph>
			</div>

			<EmailBox>
				{error && error?.response.status === 400 && (
					<EndpointErrorAlert>{error?.response?.data.error}</EndpointErrorAlert>
				)}

				{error &&
					(error?.message === "Network Error" ||
						error?.response.status === 500) && (
						<EndpointErrorAlert>
							Please refresh browser or contact support
						</EndpointErrorAlert>
					)}

				<InputWithLabel
					label="Company email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					labelFor="email"
					id="email"
					type="email"
					placeholder="Company email"
				/>
			</EmailBox>

			<Button
				bg="default"
				bordercolor="default"
				hoverbg="green100"
				hoverbordercolor="green100"
				color="grey50"
				disabled={isFormComplete ? false : true}
				style={{ width: "100%", marginTop: "2rem" }}
				onClick={handlePasswrdReset}
			>
				Continue
			</Button>
		</AuthPasswordLayout>
	);
}
