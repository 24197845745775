import React, { useState } from "react";
import {useNavigate} from "react-router-dom"
import styled from "styled-components";
import { Paragraph } from "../../Paragraph";
import { Button } from "../../Button";
import { useDispatch, useSelector } from "react-redux";
import { handleLogoutRedux, selectAuthUser } from "../../../redux/authSliceLogin";
import BreadCrumb from "../../NavBar/BreadCrumb";
import { EkhoLogo } from "../../SideBar/SideNavIcon";

const DashboardContainer = styled.div`
	width: 100%;
	height: 100%;
	min-height: 100vh;
	position: relative;
	background: linear-gradient(180deg, #102d23 0%, #001817 100%);
`;

const Navigation = styled.nav`
	width: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 50px;
	position: sticky;
	top: 0;
	z-index: 20;
	${'' /* background: ${(props) => props.theme.colors.neutral.grey00}; */}

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		padding: 16px 30px;
	}
`;

const NavSection = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const NoImageIcon = styled.div`
	width: 38px;
	height: 38px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	font-weight: 900;
	cursor: pointer;
	background: ${(props) => props.theme.colors.neutral.grey200};
`;

const UserIconContainer = styled.img`
	cursor: pointer;
	width: 38px;
	height: 38px;
	object-fit: cover;
	border-radius: 100%;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	box-shadow: ${(props) => props.theme.shadow.md};
`;

const ContentContainer = styled.div`
	position: relative;

	padding: 37px 50px 110px 70px;

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		padding: 37px 30px;
	}
`;

const CrumnBox = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const DropDownWrapper = styled.div`
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10;
	background: ${(props) => props.theme.colors.neutral.grey900}e6;
`;

const NavDropdown = styled.div`
	display: flex;
	flex-direction: column;
	padding: 8px;
	border-radius: 16px;
	position: absolute;
	right: 50px;
	top: 80px;
	background: ${(props) => props.theme.colors.neutral.grey00};
	border: 1px solid ${(props) => props.theme.colors.neutral.grey30};

	@media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
		right: 30px;
	}
`;

const UserInfo = styled.div`
	padding: 8px 16px;
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
`;

const DropdownButtons = styled(Button)`
	border-radius: 8px;
	padding: 8px 16px;
	border: none;
	text-align: left;
	font-weight: 600;
	background: ${(props) => props.theme.colors.neutral.grey00};
	color: ${(props) => props.theme.colors.neutral.grey500};

	&:hover {
		background: ${(props) => props.theme.colors.neutral.grey100};
	}

	&:focus {
		background: ${(props) => props.theme.colors.neutral.grey100};
		color: ${(props) => props.theme.colors.neutral.grey700};
	}
`;

const UserButton = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.6rem;
	margin-block: 0.6rem;
	padding-block: 0.6rem;
	border-top: 1px solid ${(props) => props.theme.colors.neutral.grey30};
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral.grey30};
`;

export default function DashboardCallLayout({ children }) {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const authUser = useSelector(selectAuthUser);
	const [showDropDown, setShowDropdown] = useState(false);

	const handleDropdown = () => {
		setShowDropdown((showDropDown) => !showDropDown);
	};


	const handleLogout = async () => {
	try {
			// Dispatch the logout async thunk
			await dispatch(handleLogoutRedux());

			// Navigate to the login page
			navigate("/login");
		} catch (error) {
			console.error("Logout error:", error);
			// Handle error appropriately (e.g., show error message)
		}
	};
	return (
		<DashboardContainer>
			<Navigation>
				<CrumnBox>
					<EkhoLogo />
					<BreadCrumb />
				</CrumnBox>

				<NavSection>
					{authUser.image_path ? (
						<UserIconContainer
							src={`${process.env.REACT_APP_API_URL}/${authUser.image_path}`}
							alt={authUser.full_name}
							onClick={handleDropdown}
						/>
					) : (
						<NoImageIcon onClick={handleDropdown}>
							{authUser.full_name.slice(0, 1)}
						</NoImageIcon>
					)}
				</NavSection>
			</Navigation>

			<ContentContainer>
				{showDropDown && (
					<DropDownWrapper>
						<NavDropdown>
							<UserInfo>
								<Paragraph fontSize="sm" color="grey700" fontWeight={700}>
									{authUser.full_name}
								</Paragraph>
								<Paragraph fontSize="sm" color="grey500" fontWeight={400}>
									{authUser.email}
								</Paragraph>
							</UserInfo>
							<UserButton>
								<DropdownButtons
									hovercolor="grey500"
									onClick={() => navigate("/dashboard/settings")}
								>
									Settings
								</DropdownButtons>
							</UserButton>
							<DropdownButtons hovercolor="grey500" onClick={handleLogout}>
								Log out
							</DropdownButtons>
						</NavDropdown>
					</DropDownWrapper>
				)}
				{children}
			</ContentContainer>
		</DashboardContainer>
	);
}
