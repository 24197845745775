import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Paragraph } from "../../components/Paragraph";
import { Button } from "../../components/Button";
import { Checkbox } from "rsuite";

const DropdownContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	${'' /* left: 0; */}
	top: 45px;
	border-radius: 8px;
	z-index: 9;
	background: ${(props) => props.theme.colors.neutral.grey00};
	border: 1px solid ${(props) => props.theme.colors.neutral.grey100};
	box-shadow: ${(props) => props.theme.shadow.dropdownShadow};
`;

const SelectButton = styled.div`
	min-width: 293px;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 8px;
	gap: 16px;
	border-radius: 8px;
	transition: background-color 0.3s ease;

	${(props) =>
		props.active &&
		css`
			background: ${(props) => props.theme.colors.neutral.grey100};
		`}

	&:hover {
		background: ${(props) => props.theme.colors.neutral.grey100};
	}
`;

const Avatar = styled.img`
	width: 32px;
	height: 32px;
	border-radius: 100%;
	object-fit: cover;
`;

const NameAvatar = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	border-radius: 100%;
	background: ${(props) => props.theme.colors.neutral.grey200};
	color: ${(props) => props.theme.colors.neutral.grey400};
`;

const ContentBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
`;

const ButtonBox = styled.div`
	display: flex;
	gap: 16px;
	padding: 16px;
	border-radius: 0 0 8px 8px;
	border-width: 1px 0 0 0;
	border-style: solid;
	border-color: ${(props) => props.theme.colors.neutral.grey100};
	background: ${(props) => props.theme.colors.neutral.grey50};
`;

const CancelButton = styled(Button)`
	width: 100%;
	padding: 8px 32px;
	border-radius: 32px;
	border: 1px solid ${(props) => props.theme.colors.neutral.grey200};
	background: ${(props) => props.theme.colors.neutral.grey00};
	color: ${(props) => props.theme.colors.neutral.default};

	&:hover {
		border: 1px solid ${(props) => props.theme.colors.secondary.grey100};
		background: ${(props) => props.theme.colors.secondary.grey100};
		color: ${(props) => props.theme.colors.neutral.grey00};
	}
`;

const ApplyButton = styled(Button)`
	width: 100%;
	padding: 8px 32px;
	border-radius: 32px;
	border: 1px solid ${(props) => props.theme.colors.secondary.default};
	background: ${(props) => props.theme.colors.secondary.default};
	color: ${(props) => props.theme.colors.neutral.grey00};

	&:hover {
		border: 1px solid ${(props) => props.theme.colors.secondary.green200};
		background: ${(props) => props.theme.colors.secondary.green200};
		color: ${(props) => props.theme.colors.neutral.grey00};
	}
`;

export default function FiltersDropdown({
	data,
	setShowDropdown,
	setSelectFilter,
  showCheckBox,
  showAvatar
}) {
	const [selectedValues, setSelectedValues] = useState([]);
const dropdownRef = useRef(null);

const [position, setPosition] = useState("right");

useEffect(() => {
	const updatePosition = () => {
		const dropdown = dropdownRef.current;
		if (dropdownRef.current) {
			const { left, right } = dropdownRef.current.getBoundingClientRect();
			const viewportWidth = window.innerWidth;
			console.log(viewportWidth);

			if (left < 0) {
				setPosition("left");
			} else if (right > viewportWidth) {
				setPosition("right");
				dropdown.style.right = "0";
			} else {
				setPosition("left");
				dropdown.style.left = "0";
			}
		}
	};

	updatePosition();
	window.addEventListener("resize", updatePosition);

	return () => {
		window.removeEventListener("resize", updatePosition);
	};
}, []);


const handleSelectValues = (item) => {
    // Determine the key to use: item.value if present, otherwise item.full_name
    const key = item.value !== undefined ? item.value : item.full_name;

    setSelectedValues((prevSelectedValues) => {
        const newSelectedValues = [...prevSelectedValues];
        if (newSelectedValues.includes(key)) {
            // If the key is already in selectedValues, remove it
            return newSelectedValues.filter((value) => value !== key);
        } else {
            // Otherwise, add the key to selectedValues
            return [...newSelectedValues, key];
        }
    });
};

	const handleApply = () => {
		setSelectFilter(selectedValues);
				console.log(selectedValues);
		setShowDropdown(false);
	};

	const handleCancelFilter = () => {
		setSelectFilter([]);
		setSelectedValues([]);
		setShowDropdown(false);
	};

	return (
		<DropdownContainer ref={dropdownRef} position={position}>
			<div
				style={{
					maxHeight: "320px",
					overflowY: "scroll",
					display: "flex",
					flexDirection: "column",
					gap: "16px",
					padding: "16px",
				}}
			>
				{data.map((item, index) => (
					<SelectButton
						key={index}
						active={
							selectedValues.includes(item.value) ||
							selectedValues.includes(item.full_name)
						}
						onClick={() => handleSelectValues(item)}
					>
						{showCheckBox && (
							<Checkbox
								color="green"
								checked={selectedValues.includes(item.full_name)}
							/>
						)}
						{showAvatar && (
							<>
								{item.image_path ? (
									<Avatar
										src={`${process.env.REACT_APP_API_URL}/${item.image_path}`}
										alt={item.full_name}
									/>
								) : (
									<NameAvatar>
										<Paragraph fontWeight={900}>
											{item.full_name.slice(0, 1)}
										</Paragraph>
									</NameAvatar>
								)}
							</>
						)}

						<ContentBox>
							<Paragraph fontSize="sm" color="grey700" fontWeight={600}>
								{item.full_name}
							</Paragraph>
							{item.designation && (
								<Paragraph fontSize="xs" color="grey400" fontWeight={400}>
									{item.designation}
								</Paragraph>
							)}
						</ContentBox>
					</SelectButton>
				))}
			</div>
			<ButtonBox>
				<CancelButton onClick={handleCancelFilter}>Cancel</CancelButton>
				<ApplyButton onClick={handleApply}>Apply</ApplyButton>
			</ButtonBox>
		</DropdownContainer>
	);
}
