import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducerSignup from "./authSliceSignup";
import authReducer from "./authSliceLogin"; 
import personaReducer from "./personaSliceCreation"

const persistConfig = {
	key: "root",
	storage,
};

const rootReducer = combineReducers({
	authLogin: authReducer,
	authSignup: authReducerSignup,
	persona: personaReducer,
	// Add other reducers here if any
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					"persist/PERSIST",
					"persist/REHYDRATE",
					"persist/FLUSH",
					"persist/PAUSE",
					"persist/UPDATE",
					"persist/REMOVE",
				],
			},
		}),
});

export const persistor = persistStore(store);
